import { Box, Card, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import Api from '../../../../http/adminHttp/serviceApiAdmin';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import AdminForm from '../adminForm/adminForm';
import Statistic from './statistic/statistic';

// tabs
import AdminLogTab from './tabs/adminLogTab/adminLogTab';
import AdminAccountTab from './tabs/adminAccountTab/adminAccountTab';
import AdminUserTab from './tabs/adminUserTab/adminUserTab';
import AdminTomanTransactionTab from './tabs/adminTomanTransactionTab/adminTomanTransactionTab';
import LoadingScreen from '../../../../components/LoadingScreen';
import { navigationUrl } from '../../../../navigationUrl';
import BankCardTable from '../../bankCard/bankCardTable';
// import AdminCryptoTransactionTab from './tabs/adminCryptoTransactionTab/adminCryptoTransactionTab';

export default function EditAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((store) => store.adminUserInfo);
  const { id: paramId } = useParams();

  const userId = +paramId === 0 ? userInfo.userId : paramId;

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Edit Admin' },
    });
  }, []);

  const [tab, setTab] = useState(0);
  const [data, setData] = useState(null);

  const handleTabChange = (e, newTab) => {
    setTab(newTab);
  };

  // ----------------------------------------------
  const getData = async () => {
    try {
      const response = await Api.getAdminById(userId);
      if (response) {
        setData(response);
      } else {
        navigate(navigationUrl.admin_list);
      }
    } catch (e) {
      navigate(navigationUrl.admin_list);
    }
  };

  // ----------------------------------------------
  useEffect(() => {
    if (userId) getData();
  }, [userId]);

  // ----------------------------------------------
  if (!data) {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ mx: { xs: 2, lg: 0 } }}>
      <Statistic data={data} />

      <AdminForm data={data} />

      <Card sx={{ mt: 2.5 }}>
        <Box sx={{ borderBottom: 2, borderColor: 'divider', maxWidth: 1035 }}>
          <Tabs value={tab} onChange={handleTabChange} variant='scrollable' scrollButtons='auto'>
            <Tab label={t('List of accounts')} sx={{ width: 170 }} />
            <Tab label={t('Fiat transactions')} sx={{ width: 180 }} />
            {/* <Tab label={t('Crypto transactions')} sx={{ width: 180 }} /> */}
            <Tab label={t('Bank cards')} sx={{ width: 170 }} />
            <Tab label={t('Users')} sx={{ width: 170 }} />
            <Tab label={t('Log')} sx={{ width: 165 }} />
          </Tabs>
        </Box>

        <TabPanel value={tab} index={0}>
          <AdminAccountTab dollarLimit={data?.dollarLimit || 0} rialLimit={data?.rialLimit || 0} userId={userId} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <AdminTomanTransactionTab />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <BankCardTable userId={userId} isChangeList />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <AdminUserTab userId={userId} />
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <AdminLogTab userId={userId} />
        </TabPanel>
        {/* <TabPanel value={tab} index={2}>
          <AdminCryptoTransactionTab />
        </TabPanel> */}
      </Card>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} {...other}>
      {value === index && <Box sx={{ mt: 2.5 }}>{children}</Box>}
    </div>
  );
}
