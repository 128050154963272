import React from 'react';
import { Button, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFNumberField, RHFTextField } from '../../../components/hookForm';
import { navigationUrl } from '../../../navigationUrl';
import Api from '../../../http/serviceApi';

export default function DepositPaymentForm({ selectedCurrency, bankCardList }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const validators = Yup.object().shape({
    amount: Yup.string().required(t('Please input the value!')),
    cardId: Yup.string().required(t('Please select a bank card!')),
  });

  const methods = useForm({
    resolver: yupResolver(validators),
    defaultValues: {
      amount: '',
      cardId: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (payload) => {
    // try {
    //   let response = {};
    //   if (selectedCurrency === 'USD') {
    //     // requestDepositUsd
    //   } else {
    //     response = await Api.requestDepositRial(+payload.amount.replace(',', ''), payload.cardId);
    //   }
    //   if (response?.status === 'SUCCESS') {
    //     reset();
    //   }
    // } catch (e) {}
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='body3' color='secondary'>
        {t('Payment is only possible through approved accounts, otherwise your payment will be unsuccessful.')}
      </Typography>

      <RHFNumberField
        name='amount'
        label='Value'
        fullWidth
        variant='standard'
        sx={{ mt: 5 }}
        thousandSeparator
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Typography color='grey.100' variant='body2'>
                {selectedCurrency}
              </Typography>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name='cardId'
        label='Bank Card'
        select
        fullWidth
        variant='standard'
        sx={{ mt: 3 }}
        helperText={t('You will see a list of your approved bank accounts')}
        SelectProps={{
          MenuProps: {
            sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
          },
        }}
      >
        {bankCardList
          .filter((card) => {
            if (selectedCurrency === 'USD') return card.cardType !== 'SHETAB_CARD';
            return card.cardType === 'SHETAB_CARD';
          })
          .map((card, index) => (
            <MenuItem key={index} value={card.cardId}>
              <Typography variant='body2'>
                {card.cardNumber
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(.{4})/g, '$1 ')
                  .trim()}
              </Typography>
            </MenuItem>
          ))}
      </RHFTextField>

      <Stack direction='row' sx={{ mt: 6.25 }} spacing={2}>
        <LoadingButton
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ width: '60%', typography: 'body3' }}
        >
          {t('Go to payment')}
        </LoadingButton>

        <Button
          onClick={() => navigate(navigationUrl.wallet)}
          variant='outlined'
          color='inherit'
          sx={{ width: '40%', typography: 'body3' }}
        >
          {t('Back')}
        </Button>
      </Stack>
    </FormProvider>
  );
}
