import { Box, Button, FormControl, FormLabel, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FormProvider, RHFNumberField, RHFRadioGroup, RHFTextField } from '../../../components/hookForm';
import Image from '../../../components/Image';
import PaymentMethod from '../../../components/PaymentMethod';
import PMIcon from '../../../assets/logo/PM.svg';
import PSIcon from '../../../assets/logo/PS.svg';
import USD from '../../../assets/logo/dolar.svg';
import IRR from '../../../assets/logo/IRR.svg';

import usePerfectMoneyForm from './hooks/usePerfectMoneyForm';
import usePerfectMoneyCalculate from './hooks/usePerfectMoneyCalculate';
import usePerfectMoneySubmit from './hooks/usePerfectMoneySubmit';
import { fFloat } from '../../../utils/formatNumber';

export default function PerfectMoneyForm({ keepType, setKeepType, side, onOpenStatusModal }) {
  const { t } = useTranslation();
  const isIranianPaymentActive = useSelector((store) => store.isIranianPaymentActive);

  // ---------------------------
  const { methods } = usePerfectMoneyForm();

  // ---------------------------
  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isSubmitting },
  } = methods;

  // ---------------------------
  const { unitData } = usePerfectMoneyCalculate({ side, setValue, watch });
  const { type, fiatType, fiatAmount, depositType, withDescription } = watch();

  // ---------------------------
  const { onSubmit } = usePerfectMoneySubmit({ onOpenStatusModal, reset, side ,fiatAmount , fiatType});

  console.log(fiatType)
  // ---------------------------

  // ---------------------------
  useEffect(() => {
    setValue('type', keepType);
  }, []);

  // ---------------------------
  useEffect(() => {
    setKeepType(type);
  }, [type]);

  // ---------------------------
  return (
    <>
      <Typography alignItems={'center'} variant='body3' color={'grey.300'}>
        {t('Complete the following information to buy digital currency')}
      </Typography>

      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={2}
        sx={{ backgroundColor: 'grey.700', borderRadius: '12px', mt: 3, p: 2.5 }}
      >
        <Stack direction='row' spacing={1} alignItems={'center'}>
          <Typography variant='body2'>{side === 'BUY' ? t('Pay') : t('Receive')}</Typography>

          <Typography variant='h5' sx={{ fontWeight: 400 }}>
            {fFloat(fiatAmount)}
          </Typography>
          {fiatAmount > 0 && (
            <Typography variant='body1' sx={{ fontWeight: 400 }} color='grey.300'>
              USD
            </Typography>
          )}
        </Stack>
      </Stack>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ mt: 3 }}>
          <Box>
            <RHFTextField
              name='type'
              select
              label={'Select currency'}
              variant='standard'
              fullWidth
              SelectProps={{
                MenuProps: {
                  sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
                },
              }}
            >
              <MenuItem value='NORMAL'>
                <Stack direction={'row'} spacing={1} alignItems='center'>
                  <Image src={PMIcon} />
                  <Typography variant='body2' sx={{ fontWeight: 400 }}>
                    {t('Perfect Money')}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem value='E_VOUCHER'>
                <Stack direction={'row'} spacing={1} alignItems='center'>
                  <Image src={PMIcon} />
                  <Typography variant='body2' sx={{ fontWeight: 400 }}>
                    {t('Voucher Perfect Money')}
                  </Typography>
                </Stack>
              </MenuItem>
              <MenuItem value='PAYSAFE'>
                <Stack direction={'row'} spacing={1} alignItems='center'>
                  <Image src={PSIcon} />
                  <Typography variant='body2' sx={{ fontWeight: 400 }}>
                    {t('Voucher PaySafe')}
                  </Typography>
                </Stack>
              </MenuItem>
            </RHFTextField>

            <Stack direction={'row'} justifyContent={'space-between'} sx={{ mt: 0.5 }}>
              <Typography variant='body3' color={'grey.300'}>
                {(type === 'NORMAL' && t('Price per unit of Perfect Money')) ||
                  (type === 'E_VOUCHER' && t('Price per unit of Voucher Perfect Money')) ||
                  (type === 'NORMAL' && t('Price per unit of Voucher PaySafe')) ||
                  ''}
              </Typography>
              <Typography variant='body3' color={'grey.300'}>
                {unitData.price} {fiatType}
              </Typography>
            </Stack>
          </Box>

          {(side === 'BUY' || (side === 'SELL' && type === 'NORMAL')) && (
            <RHFNumberField
              name='perfectMoneyAmount'
              label={'Value currency'}
              variant='standard'
              placeholder='0'
              thousandSeparator
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Image src={type === 'PAYSAFE' ? PSIcon : PMIcon} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end' sx={{ color: 'grey.100' }}>
                    {type === 'PAYSAFE' ? 'PS' : 'PM'}
                  </InputAdornment>
                ),
              }}
            />
          )}

          {side === 'BUY' && (
            <>
              <RHFTextField
                name='fiatType'
                select
                label={'Payment unit'}
                variant='standard'
                fullWidth
                SelectProps={{
                  MenuProps: {
                    sx: { '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' } },
                  },
                }}
              >
                <MenuItem value='USD'>
                  <Typography variant='body2' sx={{ fontWeight: 400 }}>
                    {t('Dollar')}
                  </Typography>
                </MenuItem>
                {isIranianPaymentActive && (
                  <MenuItem value='IRR'>
                    <Typography variant='body2' sx={{ fontWeight: 400 }}>
                      {t('Rial')}
                    </Typography>
                  </MenuItem>
                )}
              </RHFTextField>

              <Box>
                <RHFNumberField
                  name='fiatAmount'
                  variant='standard'
                  fullWidth
                  placeholder='0'
                  thousandSeparator
                  sx={{ mt: 2.5 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Image src={fiatType === 'USD' ? USD : IRR} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end' sx={{ color: 'grey.100' }}>
                        {fiatType}
                      </InputAdornment>
                    ),
                  }}
                />

                <Typography variant='body3' color={'grey.300'} component='p' sx={{ mt: 0.5 }}>
                  {t('You can also enter the amount')}
                </Typography>
              </Box>
            </>
          )}

          {type === 'NORMAL' && side === 'BUY' && (
            <>
              <RHFTextField
                name='walletId'
                label={'Wallet ID'}
                variant='standard'
                placeholder={t('Enter the relevant account ID')}
                fullWidth
              />
              <FormControl>
                <FormLabel variant='body3' sx={{ color: 'grey.400', fontWeight: 400, mt: 1 }}>
                  {t('With security code')}
                </FormLabel>

                <RHFRadioGroup
                  name='withSecurityCode'
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                  ]}
                  sx={{ '& .MuiFormControlLabel-root': { mr: 5 } }}
                />
              </FormControl>

              <FormControl>
                <FormLabel variant='body3' sx={{ color: 'grey.400', fontWeight: 400 }}>
                  {t('With description')}
                </FormLabel>

                <RHFRadioGroup
                  name='withDescription'
                  options={[
                    { label: 'Yes', value: 'yes' },
                    { label: 'No', value: 'no' },
                  ]}
                  sx={{ '& .MuiFormControlLabel-root': { mr: 5 } }}
                />
              </FormControl>
            </>
          )}

          {type !== 'NORMAL' && side === 'BUY' && (
            <>
              <Box>
                <RHFNumberField
                  name='purchase'
                  label={'Purchase fee'}
                  variant='standard'
                  placeholder={t('One percent equals 0 Dollar')}
                  fullWidth
                  disabled
                  sx={{
                    '& .Mui-disabled': {
                      color: 'grey.400',
                      '& input': { WebkitTextFillColor: 'grey.0' },
                      '&:before': { borderBottomStyle: 'solid' },
                    },
                  }}
                />
                <Typography variant='body3' color={'grey.300'} component='p' sx={{ mt: 0.5 }}>
                  {t('The purchase fee will be added to your purchase amount')}
                </Typography>
              </Box>
            </>
          )}

          {type === 'E_VOUCHER' && side === 'SELL' && (
            <>
              <RHFTextField name='voucherCode' variant='standard' fullWidth label='Voucher Code' />

              <RHFTextField name='activationCode' variant='standard' fullWidth label='Activation code' />
            </>
          )}

          {type === 'PAYSAFE' && side === 'SELL' && (
            <>
              <RHFTextField name='serialNumber' variant='standard' fullWidth label='Serial number' />

              <RHFTextField name='activationCode' variant='standard' fullWidth label='Activation code' />

              <RHFNumberField
                name='perfectMoneyAmount'
                label={'Amount'}
                variant='standard'
                placeholder='0'
                thousandSeparator
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Image src={PSIcon} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end' sx={{ color: 'grey.100' }}>
                      PS
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {side === 'SELL' && (
            <>
              <Box>
                <RHFNumberField
                  name='sales'
                  label={'Sales fee'}
                  variant='standard'
                  placeholder={t('One percent equals 0 Dollar')}
                  fullWidth
                  disabled
                  sx={{
                    '& .Mui-disabled': {
                      color: 'grey.400',
                      '& input': { WebkitTextFillColor: 'grey.0' },
                      '&:before': { borderBottomStyle: 'solid' },
                    },
                  }}
                />
                <Typography variant='body3' color={'grey.300'} component='p' sx={{ mt: 0.5 }}>
                  {t('The sales fee is deducted from the amount you receive')}
                </Typography>
              </Box>
            </>
          )}

          {((type === 'NORMAL' && side === 'BUY' && withDescription === 'yes') ||
            type !== 'NORMAL' ||
            side !== 'BUY') &&
            type !== 'PAYSAFE' && <RHFTextField name='description' variant='standard' fullWidth label='Description' />}
        </Stack>

        {side === 'BUY' && (
          <Box sx={{ mt: 5 }}>
            <PaymentMethod onSelectGateway={(id) => setValue('depositType', id)} paymentMethod={depositType} />
          </Box>
        )}

        <Stack direction={'row'} spacing={1} sx={{ mt: 5 }}>
          <LoadingButton
            variant='contained'
            type='submit'
            size='large'
            loading={isSubmitting}
            sx={{ width: '70%', typography: 'body3_secondary', fontWeight: 500 }}
          >
            {t('Pay')}
          </LoadingButton>
          <Button
            variant='outlined'
            type='button'
            size='large'
            color='inherit'
            sx={{ typography: 'body3_secondary', width: '30%', fontWeight: 500 }}
          >
            {t('Back')}
          </Button>
        </Stack>
      </FormProvider>
    </>
  );
}
