import { useState } from 'react';
import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import mergeDeep from '../../../utils/merge';
import { BaseOptionChart } from '../../../components/chart';
import { OverallGrowthChartContainer, PeriodButton } from './style';
import { fPercent } from '../../../utils/formatNumber';
import useOverallGrowth from './hooks/useOverallGrowth';
import { Endpoint } from '../../../configs/serviceConfig';
import fakeChart from '../../../assets/fake-chart.jpg';
import fakeChartLight from '../../../assets/fake-chart-light.jpeg';

import Image from '../../../components/Image';

const periods = [
  { value: 'ONE_DAY', label: 'last day', days: 1 },
  { value: 'ONE_WEEK', label: 'last week', days: 7 },
  { value: 'TWO_WEEK', label: 'last 2 week', days: 14 },
  { value: 'ONE_MONTH', label: 'last month', days: 30 },
  // { value: 'THREE_MONTH', label: 'last month', days: 90 },
];


export default function OverallGrowth() {
  const { t } = useTranslation();

  const [periodSelected, setPeriodSelected] = useState(periods[1]);
  const { chartLabels, chartData } = useOverallGrowth(periodSelected);


  const theme = useTheme()
  return (
    <>
      <Stack direction={'row'} alignItems={'center'}>
        <Typography variant='h6_secondary' sx={{ width: 200 }}>
          {t('Overall Growth')}
        </Typography>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2 }}
          justifyContent='center'
          sx={{ width: '100%' }}
        >
          {periods.map((period) => (
            <PeriodButton
              size='small'
              color='inherit'
              selected={periodSelected.value === period.value}
              onClick={() => setPeriodSelected(period)}
              key={period.value}
            >
              <Typography variant='subtitle2_secondary' color='grey.400' sx={{ fontWeight: 900 }}>
                {t(period.label)}
              </Typography>
            </PeriodButton>
          ))}
        </Stack>
      </Stack>

      <OverallGrowthChartContainer dir='ltr'>
        {chartData === null ? (
          <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 405 }}>
            <CircularProgress color='success' size={40} />
          </Stack>
        ) : (
          <>
            {chartData.length > 0 ? (
              <DrawChart chartData={chartData} chartLabels={chartLabels} />
            ) : (
              theme.palette.mode === 'dark' ?
              <Image src={fakeChart} />
                :
              <Image src={fakeChartLight} />

              // <Stack alignItems={'center'} justifyContent={'center'} sx={{ height: 405 }}>
              //   <Typography>{t('No Data')}</Typography>
              // </Stack>
            )}
          </>
        )}
      </OverallGrowthChartContainer>
    </>
  );
}

const DrawChart = ({ chartData, chartLabels }) => {
  const theme = useTheme();

  const chartOptions = mergeDeep(BaseOptionChart(), {
    colors: ['#F7931A', '#BD47FB', '#30E0A1', '#00c3ff'],
    stroke: {
      show: chartLabels.length <= 7,
      width: 3,
      colors: ['transparent'],
    },
    xaxis: {
      categories: chartLabels,
    },
    tooltip: {
      custom: ({ series, dataPointIndex, w }) => {
        const currencies = w.globals.initialSeries;
        return `
          <div class="tooltip">
            ${generateItem(currencies[0], series[0][dataPointIndex])}
            ${generateItem(currencies[1], series[1][dataPointIndex])}
            ${generateItem(currencies[2], series[2][dataPointIndex])}
            ${generateItem(currencies[3], series[3][dataPointIndex])}
          </div>`;
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.1,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 85, 100],
        shade: 'dark',
        inverseColors: true,
      },
    },
    chart: { foreColor: theme.palette.grey[400] },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: chartLabels.length === 1 ? '20%' : '70%',
        borderRadius: 0,
      },
    },
  });

  return <ReactApexChart type='bar' series={chartData} options={chartOptions} height={388} />;
};

const generateItem = (currency, value) => `
<div class="tooltip-item">
  <div class="tooltip-label">
    ${
      currency.symbol
        ? `<div class="tooltip-icon"><img src="${Endpoint}cryptizi/api/v1/general/symbols?symbol=${currency.symbol}" alt="" /></div>`
        : ''
    }
    <div class="tooltip-content">
      <span>${currency.symbol || ''}</span>
      <small class="${!currency.symbol ? 'tooltip-other' : ''}">${currency.name}</small>
    </div>
  </div>
  <div class="tooltip-value">
    <img src="${process.env.PUBLIC_URL}/images/icons/arrow-up.svg" alt="" />
    <span>${fPercent(value)}</span>
  </div>
</div>`;
