import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MenuItem, Stack, Card, Typography, FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { SET_PAGE_STATE } from '../../../../store/actionTypes';
import { PageTitle } from '../../../../components/PageTitle';
import { FormProvider, RHFSelect, RHFSwitch } from '../../../../components/hookForm';
import GatewayInputs from './gatewayInputs';
import RejectionBanks from './rejectionBanks';
import WorkingHours from './workingHours';
import useGatewayForm from './hooks/useGatewayForm';
import useGatewaySubmit from './hooks/useGatewaySubmit';
import Api from '../../../../http/adminHttp/serviceApiAdmin';

//=====================================================

const DEPOSIT_MODELS = [
  { value: 'active', label: 'Active' },
  { value: 'PISHFARZ', label: 'PISHFARZ' },
  { value: 'TASVIE DAR LAHZE', label: 'TASVIE DAR LAHZE' },
];

//=====================================================

const AutoDepositSetting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [values, setValues] = useState([]);
  const [data, setData] = useState({});
  const [gateway, setGateway] = useState('vandar');

  const { handleSubmit, isSubmitting, methods, isActive } = useGatewayForm(data);
  const { onSubmit } = useGatewaySubmit(values, gateway);

  const fetchGatewayData = async (name) => {
    const response = await Api.getGatewaySettings(name);
    if (response.status === 'SUCCESS') {
      setData(response);
    }
  };

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Automatic Deposit settings' },
    });
  }, []);

  useEffect(() => {
    fetchGatewayData(gateway);
  }, [gateway]);

  return (
    <Stack sx={{ padding: { xs: '0 20px 20px', lg: '0 0 97px' } }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ mt: 5, py: 5, px: 2.5 }}>
          <PageTitle title={t('Automatic deposit settings')} subTitle='Caption' />

          <Stack direction='row' alignItems={'center'} spacing={1} sx={{ mt: 5 }}>
            <RHFSwitch name='active' />
            <Typography variant='body3' color='grey.100'>
              {isActive ? t('Active') : t('Disable')}
            </Typography>
          </Stack>

          <Stack
            sx={{
              mt: 3,
              '& .MuiFormControl-root': { display: 'flex', flexDirection: 'row' },
              '& .MuiFormGroup-root': { flexDirection: 'row' },
            }}
          >
            <FormControl onChange={(e) => setGateway(e.target.value)}>
              <RadioGroup aria-labelledby='demo-controlled-radio-buttons-group' name='controlled-radio-buttons-group'>
                <FormControlLabel value='vandar' control={<Radio checked={gateway === 'vandar'} />} label='Vandar' />
                <FormControlLabel value='zibal' control={<Radio checked={gateway === 'zibal'} />} label='Zibal' />
                {/* <FormControlLabel value='pir' control={<Radio checked={gateway === "pir"}/>} label='Pir' /> */}
              </RadioGroup>
            </FormControl>
          </Stack>

          <GatewayInputs selectedGateway={gateway} />

          <RejectionBanks banksList={data.bankNames} setValues={setValues} values={values} />

          <WorkingHours />
        </Card>

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          justifyContent='space-between'
          alignItems='center'
          sx={{ mt: 3.5 }}
          spacing={2}
        >
          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
            sx={{ maxWidth: 283, mt: 2 }}
          >
            {t('Save')}
          </LoadingButton>

          {/* <RHFSelect
            name='deposit_model'
            SelectProps={{
              native: false,
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 300, backgroundColor: 'black.0' } },
              },
            }}
            label='Deposit model'
            variant='standard'
            fullWidth
            sx={{ maxWidth: 320 }}
          >
            {DEPOSIT_MODELS.map((each, index) => (
              <MenuItem value={each.value} key={index}>
                <Stack direction='row' spacing={1} alignItems={'center'}>
                  <Typography typography='body3' color='grey.300'>
                    {each.label}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </RHFSelect> */}
        </Stack>
      </FormProvider>
    </Stack>
  );
};

export default AutoDepositSetting;
