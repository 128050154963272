const tr = {
    translation: {
        "Cryptizi": "Криптизи",
"Hi, Welcome Back": "Merhaba, Tekrar Hoş Geldiniz",
"Nice to meet you!": "Sizi tanıdığıma memnun oldum!",
"Best Wallet For Your Coins": "Paralarınız için en iyi cüzdan",
"Dashboard": "Kontrol Paneli",
"Wallet": "Cüzdan",
"{{number}} Currencies": "{{number}} Para Birimleri",
"Sign up": "Kaydol",
"Log in": "Giriş yap",
"Search": "Ara",
"No": "Hayır",
"Yes": "Evet",
"Balance": "Bakiye",
"Income": "Gelir",
"Expenses": "Harcamalar",
"Exchange": "Değişim",
"Top Assets": "En İyi Varlıklar",
"Transaction": "İşlem",
"All": "Tümü",
"Receive": "Al",
"Send": "Gönder",
"Overall Growth": "Genel Büyüme",
"Open trade": "Açık işlem",
"Market": "Piyasa",
"Trade": "Ticaret",
"P2P": "Kişiden Kişiye",
"E-Money": "Elektronik Para",
"Convert": "Dönüştür",
"Crypto": "Kripto",
"Futures": "Vadeli İşlemler",
"Possibilities": "Olanaklar",
"Referrals & Income": "Tavsiyeler ve Gelir",
"Ticket": "Bilet",
"History": "Geçmiş",
"Authentication": "Kimlik Doğrulama",
"Bank Card": "Banka Kartı",
"Account settings": "Hesap Ayarları",
"Academy": "Akademi",
"Security": "Güvenlik",
"Identification": "Tanımlama",
"Referral": "Tavsiye",
"Verified": "Doğrulandı",
"Regular user": "Düzenli Kullanıcı",
"Log out": "Çıkış yap",
"Add New favorite": "Yeni favori ekle",
"Total Balance": "Toplam Bakiye",
"30 Days PNL": "30 Gün PNL",
"Today PNL": "Bugün PNL",
"Withdraw": "Çekme",
"Deposit": "Yatırma",
"Your inventory": "Envanteriniz",
"Rial": "Riyal",
"Coin": "Kripto Para",
"Total": "Toplam",
"Available": "Kullanılabilir",
"In Order": "Sırada",
"BTC Value": "BTC Değeri",
"Action": "Eylem",
"Buy": "Satın Al",
"Rows per page": "Sayfa başına satır",
"of": "İçin",

"All Situations": "Tüm Durumlar",
"History of your orders or transactions": "Siparişlerinizin veya işlemlerinizin geçmişi",
"You can enter the name and symbol of Coin, transaction ID, etc. in the search field": "Arama alanına Coin adı, sembol, işlem ID'si vb. girebilirsiniz",
"Spend": "Harcama",
"Transaction requirements": "İşlem gereksinimleri",
"Convert ": "Dönüştür ",

"last 1hr": "son 1 saat",
"last 24hr": "son 24 saat",
"last week": "geçen hafta",
"last month": "geçen ay",

"Crypto convert": "Kripto dönüştür",
"Futures Classic": "Klasik Vadeli İşlemler",
"Token Listing": "Token Listesi",
"Total Sell": "Toplam Satış",
"APIs": "API'lar",
"iOS App": "iOS Uygulaması",
"Android App": "Android Uygulaması",
"Bank deposit": "Banka yatırımı",
"Credit/Debit card": "Kredi/Kredi Kartı",
"P2P trading": "Kişiden Kişiye Ticaret",
"Crytizi Convert": "Crytizi Dönüştür",
"Spot": "Anlık",
"Margin": "Marj",
"Markets": "Piyasalar",
"Strategy Trading": "Strateji Ticareti",
"About Us": "Hakkımızda",
"Service": "Hizmet",
"More": "Daha Fazla",
"Learn": "Öğren",
"Community": "Topluluk",
"About": "Hakkında",
"Contact us": "Bize Ulaşın",
"Terms": "Şartlar",
"FAQ": "Sıkça Sorulan Sorular",
"Token listing": "Token Listesi",
"Cryptizi Convert": "Cryptizi Dönüştür",
"Buy/Sell Crypto": "Kripto Al/Sat",
"USDⓈ-M Futures": "USDⓈ-M Vadeli İşlemler",
"Coin-M Futures": "Coin-M Vadeli İşlemler",
"Submit A request": "Bir Talep Gönder",
"Fees": "Ücretler",
"Trading Rules": "Ticaret Kuralları",
"Browse Crypto Prices": "Kripto Fiyatlarını Gözat",
"Cryptizi Academy": "Cryptizi Akademisi",
"Sign Up": "Kaydol",

"We are the most trusted cryptocurrency platform.": "En güvenilir kripto platformuyuz.",

"View more prices": "Daha fazla fiyat görüntüle",

"Build your crypto portfolio anywhere.": "Kripto portföyünüzü her yerde oluşturun.",

"A powerful cryptocurrency exchange in your pocket. Buy, sell and trade crypto on the go.":
"Cebinizde güçlü bir kripto borsası. Hareket halindeyken kripto alın, satın ve ticaret yapın.",

"Get the Cryptizi Wallet Mobile App Now!": "Şimdi Cryptizi Cüzdan Mobil Uygulamasını Edinin!",

"The most trusted cryptocurrency platform.": "En güvenilir kripto platformu.",

"Cryptizi has a variety of features that make it the best place to start trading.":
"Cryptizi'ye başlamak için en iyi yer yapacak birçok özelliği var.",

"Buy and sell popular digital currencies, keep track of them in one place.":
"Popüler dijital para birimlerini alın ve satın, hepsini tek bir yerde takip edin.",

"For added security, store your funds in a vault with time-delayed withdrawals.":
"Ek güvenlik için fonlarınızı zaman gecikmeli çekmelerle bir kasa içinde saklayın.",

"Stay on top of the markets with the Cryptizi app for Android or iOS.":
"Android veya iOS için Cryptizi uygulaması ile piyasaların üstünde kalın.",

"Portofolio Manager": "Portföyo Yöneticisi",
"Mobile Apps": "Mobil Uygulamalar",

"One click, instant payout with a credit or debit card.": "Bir tıklama, anında ödeme kredi veya banka kartı ile.",

"Become a crypto owner in minutes using your debit or credit card and quickly purchase top cryptocurrencies.":
"Banka kartınızı veya kredi kartınızı kullanarak birkaç dakika içinde kripto sahibi olun ve hızla en popüler kriptoları satın alın.",

"Intermediate Verification": "Orta Düzey Doğrulama",
"Intermediate Verified": "Orta Düzey Doğrulandı",
"Additional Information": "Ek Bilgi",
"OK": "Tamam",
"Upgrade to Higher Limits": "Daha Yüksek Sınırlara Yükselt",

"New users can earn up to {{amount}} in crypto rewards.":
"Yeni kullanıcılar {{amount}}'e kadar kripto ödülleri kazanabilirler.",

"Ready to start trading cryptocurrency?": "Kripto ticareti yapmaya hazır mısınız?",

"Enter your email address": "E-posta adresinizi girin",
"Get Started": "Başlayın",

"Your guide to the world of an open financial system. Get started with the easiest and most secure platform to buy and trade cryptocurrency.":
"Açık bir finansal sistemin dünyasına rehberiniz. Kripto para satın almak ve ticaret yapmak için en kolay ve güvenilir platform ile başlayın.",

"A trusted and secure cryptocurrency exchange.": "Güvenilir ve güvenli bir kripto para borsası.",

"Cryptizi ©. All rights reserved.": "Cryptizi ©. Tüm hakları saklıdır.",
"Terms Of Use": "Kullanım Koşulları",
"Privacy Policy": "Gizlilik Politikası",
"Assets": "Varlıklar",
"Last price": "Son fiyat",
"Change": "Değişim",
"Chart": "Grafik",

"Create an Account": "Hesap Oluştur",
"You are not a normal user!": "Sıradan bir kullanıcı değilsiniz!",
"Forgot Password": "Parolanızı mı unuttunuz?",
"Login": "Giriş",
"Please input your username!": "Lütfen kullanıcı adınızı girin!",
"Please input your password!": "Lütfen parolanızı girin!",
"Username": "Kullanıcı Adı",
"Password": "Parola",
"Remember me": "Beni Hatırla",
"You don't have permission to login!": "Giriş yapma izniniz yok!",
"Please input your first name!": "Lütfen adınızı girin!",
"Please input your last name!": "Lütfen soyadınızı girin!",
"Please input your email!": "Lütfen e-posta adresinizi girin!",
"Please input your password": "Lütfen parolanızı girin",
"Please input your confirm password!": "Lütfen parolanızı doğrulayın!",
"The confirm password does not match the password": "Doğrulama parolası parolayla eşleşmiyor",
"You must accept the terms and conditions!": "Şartları ve koşulları kabul etmelisiniz!",
"I have read and agree to Cryptizi's Terms of Service and Privacy Policy.":
"Cryptizi'nin Hizmet Şartları ve Gizlilik Politikası'nı okudum ve kabul ediyorum.",
"Confirm Password": "Parolayı Onayla",
"Register": "Kayıt Ol",
"First Name": "Ad",
"Last Name": "Soyad",
"Email": "E-posta",
"Enter the email verification code!": "E-posta doğrulama kodunu girin!",
"Enter the phone number verification code!": "Telefon numarası doğrulama kodunu girin!",
"Enter the authenticator code!": "Kimlik doğrulama kodunu girin!",
"Enter the {{number}}-digit code sent to {{email}}": "{{email}} adresine gönderilen {{number}} haneli kodu girin",
"Email verification code": "E-posta doğrulama kodu",
"Phone number verification code": "Telefon numarası doğrulama kodu",
"Enter the {{number}}-digit code send to {{phone}}": "{{phone}} adresine gönderilen {{number}} haneli kodu girin",
"Authenticator code": "Kimlik doğrulama kodu",
"Enter the {{number}}-digit code from Google authenticator": "Google kimlik doğrulama kodundan {{number}} haneli kodu girin",
"Passwords must have at least 8 characters and contain at least one uppercase letter, lowercase letter, number and symbol.": "Parolalar en az 8 karakter içermeli ve en az bir büyük harf, küçük harf, rakam ve sembol içermelidir.",
"You do not have a registered bank card": "Kayıtlı bir banka kartınız yok",
"Card added successfully.": "Kart başarıyla eklendi.",
"Add a bank card": "Bir banka kartı ekleyin",
"Type of Card": "Kart Türü",
"Register the card": "Kartı kaydet",
"Please input the type of your card": "Lütfen kart türünüzü girin",
"Done Successfully": "Başarıyla tamamlandı",
"Account Number": "Hesap Numarası",
"CVV2": "CVV2",
"Month of date": "Tarih Ayı",
"Year of date": "Tarih Yılı",
"Card Number": "Kart Numarası",
"Please input the type of your card!": "Lütfen kart türünüzü girin!",
"Please input your card number!": "Lütfen kart numaranızı girin!",
"Please input month of date!": "Lütfen tarih ayını girin!",
"Please input 2-digit number": "Lütfen 2 haneli bir sayı girin",
"Please input year of date!": "Lütfen tarih yılını girin!",
"Please input CVV2!": "Lütfen CVV2'yi girin!",
"Please input your account number!": "Lütfen hesap numaranızı girin!",
"Please input your Sheba number!": "Lütfen Sheba numaranızı girin!",
"Please input a valid Sheba number!": "Lütfen geçerli bir Sheba numarası girin!",
"Card Holder name": "Kart Sahibi Adı",
"Expiry date": "Son kullanma tarihi",
"To insert a bank card, fill out the form below and note that the bank card must be in your name.": "Bir banka kartı eklemek için aşağıdaki formu doldurun ve banka kartının adınıza olması gerektiğini unutmayın.",
"A trusted and secure cryptocurrency exchange.": "Güvenilir ve güvenli bir kripto para değişimi.",
"Vault Protection": "Kasa Koruma",
"Successful": "Başarılı",
"Learn & Earn": "Öğren ve Kazan",
"GET IT ON": "AL",
"Google Play": "Google Play",
"Download on the": "İndirin",
"App Store": "App Store",
"Additional Information": "Ek Bilgi",
"Countries": "Ülkeler",
"Traders": "Tüccarlar",
"Trading Pairs": "Ticaret Çiftleri",
"Are you sure to remove this symbol from your favorites list?": "Bu sembolü favoriler listenizden kaldırmak istediğinize emin misiniz?",
"Tips": "İpuçları",
"Deposit via receipt": "Makbuz üzerinden depozito",
"Deposit to wallet": "Cüzdana depozito",
"Deposit payment gateway": "Depozito ödeme ağ geçidi",
"Back": "Geri",
"Go to payment": "Ödemeye git",
"Save": "Kaydet",
"When determining the deposit amount, make sure that the minimum purchase and sale amount on the exchange is {{amount}}.": "Depozito miktarını belirlerken, borsadaki minimum alım ve satım miktarının {{amount}} olduğundan emin olun.",
"In order to deposit money, you must use bank cards in your own name, which are registered and approved in the user section.": "Para yatırmak için, kullanıcı bölümünde kayıtlı ve onaylanmış olan kendi adınıza banka kartları kullanmalısınız.",
"Payment is only possible through approved accounts, otherwise your payment will be unsuccessful.": "Ödeme yalnızca onaylanmış hesaplar aracılığıyla mümkündür, aksi takdirde ödemeniz başarısız olur.",
"Only for level {{level}} it is possible to deposit with a receipt.": "Yalnızca {{level}} seviyesi için makbuz ile depozito yapmak mümkündür.",
"We believe Cryptizi is here to stay - and that a future worth building is one which opens its doors and invites everyone in.": "Cryptizi'nin burada kalmak için geldiğine inanıyoruz - ve kurmaya değer bir geleceğin, kapılarını açıp herkesi davet eden bir gelecek olduğuna inanıyoruz.",
"Please input the repeat password!": "Lütfen tekrar parolayı girin!",
"The repeat password does not match the password": "Tekrar parolası parola ile eşleşmiyor",
"Repeat Password": "Parolayı Tekrarlayın",
"Successfully": "Başarılı",
"Your email has been successfully verified": "E-posta adresiniz başarıyla doğrulandı",
"Verify Email": "E-postayı Doğrula",
"Request Again": "Tekrar İste",
"Didn’t receive code?": "Kodu almadınız mı?",
"Forgot password": "Parolayı mı unuttunuz?",
"The input is not a valid email!": "Girdi geçerli bir e-posta değil!",
"Reset password": "Parolayı Sıfırla",
"Back to login": "Girişe geri dön",
"No worries, we’ll send you reset instructions": "Endişelenmeyin, size sıfırlama talimatları göndereceğiz",
"Submit": "Gönder",
"Others": "Diğerleri",
"Fiat Deposit": "Fiat Yatırma",
"USD": "USD",

"You can enter the amount, transaction ID, etc. in the search field.": "Arama alanına miktarı, işlem kimliği vb. girebilirsiniz.",
"List of Toman deposits and withdrawals": "Toman yatırma ve çekme işlemleri listesi",
"Please input the value!": "Lütfen değeri girin!",
"Please select a bank card!": "Lütfen bir banka kartı seçin!",
"You will see a list of your approved bank accounts": "Onaylanmış banka hesaplarınızın listesini göreceksiniz",
"Num": "Numara",
"Date": "Tarih",
"Creation Date": "Oluşturma Tarihi",
"Type": "Tür",
"Value": "Değer",
"Inventory": "Envanter",
"Condition": "Durum",
"Description": "Açıklama",
"details": "Detaylar",
"Please input the tracking code!": "Lütfen takip kodunu girin!",
"Optional and to speed up transaction confirmation time": "İsteğe bağlı ve işlem onay süresini hızlandırmak için",
"select to deposit": "yatırmak için seçin",
"Payer": "Ödeyen",
"Tracking Code": "Takip Kodu",
"Date and time of transaction": "İşlem tarihi ve saati",
"How to deposit": "Nasıl para yatırılır",
"Receipt image (optional)": "Fiş görüntüsü (isteğe bağlı)",
"Sell": "Sat",
"USDT": "USDT",
"Stay tuned for more coin types!": "Daha fazla coin türü için takipte kalın!",
"Enter Amount": "Miktarı Girin",
"All Payment Methods": "Tüm Ödeme Yöntemleri",
"Refresh": "Yenile",
"Advertiser": "Reklamveren",
"Price": "Fiyat",
"Available/Limits": "Mevcut/Sınırlar",
"Payment Method": "Ödeme Yöntemi",
"Order(s)": "Sipariş(ler)",
"Limits": "Sınırlar",
"Please select the currency!": "Lütfen para birimini seçin!",
"Please input your wallet ID!": "Lütfen cüzdan kimliğinizi girin!",
"Complete the following information to buy digital currency": "Dijital para satın almak için aşağıdaki bilgileri tamamlayın",
"Received money": "Alınan para",
"Please input the value currency!": "Lütfen değer para birimini girin!",
"Voucher Perfect Money": "Kupon Perfect Money",
"Price per unit of Perfect Money": "Birim başına Perfect Money fiyatı",
"You can also enter the amount in tomans": "Miktarı ayrıca tümen olarak da girebilirsiniz",
"Enter the relevant account ID": "İlgili hesap kimliğini girin",
"With description": "Açıklama ile",
"With security code": "Güvenlik kodu ile",
"Payment method": "Ödeme yöntemi",
"Visa/Mastercards": "Visa/Mastercard",
"Shetab Card": "Shetab Kartı",
"Fast Pay": "Hızlı Ödeme",
"By charging the wallet, make your payment without going to the bank portal": "Cüzdanı şarj ederek, banka portalına gitmeden ödemenizi yapın",
"Pay": "Ödeme yap",
"Select currency": "Para birimi seçin",
"Value currency": "Değer para birimi",
"Wallet ID": "Cüzdan Kimliği",
"One percent equals 0 Dollar": "Bir yüzde 0 Dolar'a eşittir",
"The sales fee is deducted from the amount you receive": "Satış ücreti aldığınız tutardan düşülür",
"Sales fee": "Satış ücreti",
"Voucher Code": "Kupon Kodu",
"Activation code": "Aktivasyon kodu",
"The purchase fee will be added to your purchase amount": "Satın alma ücreti satın alma tutarınıza eklenir",
"Purchase fee": "Satın alma ücreti",
"cryptizi convert": "cryptizi dönüştür",
"Guaranteed price": "Garantili fiyat",
"Any pairs": "Herhangi bir çift",
"Zero fee": "Sıfır ücret",
"Orders": "Siparişler",
"From": "Kimden",
"Spot wallet available": "Spot cüzdanı mevcut",
"Please enter {{range}}": "Lütfen {{range}} girin",
"To": "Kime",
"Please enter 20-42000000": "Lütfen 20-42000000 arasında bir değer girin",
"Enter an amount": "Miktarı girin",
"Select Crypto": "Kripto seçin",
"Deposit Crypto": "Kripto yatırma",
"Withdraw Crypto": "Kripto çekme",
"OTC": "OTC",
"Please input coin!": "Lütfen coin adı girin!",
"Please input TXID!": "Lütfen TXID girin!",
"Deposit crypto": "Kripto yatırma",
"Recent Deposit": "Son Yatırma",
"Bitcoin": "Bitcoin",
"Network": "Ağ",
"Select Deposit network": "Yatırım ağı seçin",
"Copy": "Kopyala",
"Expected Arrival": "Beklenen Varış",
"{{number}} Network Confirmations": "{{number}} Ağ Onayları",
"Expected Unlock": "Beklenen Kilitleme Açma",
"Minimum deposit": "Minimum yatırma",
"Selected wallet": "Seçilen cüzdan",
"Main wallet": "Ana cüzdan",
"change": "değiştir",
"Send Only {{symbol}} to this deposit address": "Bu yatırım adresine sadece {{symbol}} gönderin",
"Ensure the network is": "Ağın olduğundan emin olun",
"Do not send NFTs to this address.": "Bu adrese NFT göndermeyin.",
"learn how to deposit NFTs": "NFT yatırmayı öğrenin",
"submit": "gönder",
"Video Tutorial": "Video Öğretici",
"How to Withdraw crypto step-by-step Guide": "Kripto çekme adım adım rehberi",
"Why has my withdrawal Not Arrived?": "Neden çekimim gelmedi?",
"How to find My transaction ID (TxID)?": "Benim işlem kimliğimi (TxID) nasıl bulurum?",
"how to recover My BEP-20 token?": "BEP-20 jetonumu nasıl kurtarabilirim?",
"Deposit & withdrawal status query": "Yatırım ve çekim durumu sorgusu",
"Select coin": "Coin seçin",
"TXID": "TXID",
"Scan the code on the withdrawal page of the trading platform APP or wallet App": "Ticaret platformu uygulamasının veya cüzdan uygulamasının çekim sayfasındaki kodu tarayın",
"Send only {{symbol}} to this deposit address": "Bu yatırım adresine sadece {{symbol}} gönderin",
"Learn how to deposit NFTs": "NFT yatırmayı öğrenin",
"Unmatched networks automatically removed. Select a network below": "Eşleşmeyen ağlar otomatik olarak kaldırıldı. Aşağıdan bir ağ seçin",
"SOL": "SOL",
"Solana": "Solana",
"TRX": "TRX",
"Tron (TRC20)": "Tron (TRC20)",
"Arrival time = {{min}} min": "Varış zamanı = {{min}} dk",
"fee": "ücret",
"BSC ": "BSC",
"BNB Smart chain (BEP20)": "BNB Akıllı zincir (BEP20)",
"Fee rules": "Ücret kuralları",
"Select Network": "Ağı seçin",
"Crypto Address": "Kripto Adres",
"Completed": "Tamamlandı",
"Withdraw wallet": "Çekme cüzdanı",
"Spot Wallet": "Spot Cüzdan",
"TxID": "TxID",
"View All": "Hepsini Görüntüle",
"Cryptizi user": "Cryptizi kullanıcısı",
"Recent Withdrawals": "Son Çekimler",
"Select Withdrawal network": "Çekim ağı seçin",
"SQl Network Withdrawal suspended. withdrawals can be processed through alternative network.": "SQl ağından çekme askıya alındı. Çekimler alternatif ağ üzerinden işlenebilir.",
"withdrawal": "çekim",
"Address": "Adres",
"Enter Address": "Adresi Girin",
"USDT balance": "USDT bakiyesi",
'Network Fee': 'Ağ Ücreti',
'Minimum withdrawal': 'Minimum çekim',
'24h remaining limit': '24 saat kalan limit',
'Please enter SMS verification code!': 'Lütfen SMS doğrulama kodunu girin!',
Amount: 'Miktar',
'Receive {{receive}} {{symbol}} (Network fee {{fee}} USDT)':
'{{receive}} {{symbol}} alın (Ağ ücreti {{fee}} USDT)',
Source: 'Kaynak',
'Spot wallet': 'Spot cüzdan',
'Verification code sent': 'Doğrulama kodu gönderildi',
'security verification unavailable?': 'Güvenlik doğrulama kullanılamıyor?',
'Security verification': 'Güvenlik doğrulama',
'Withdrawal Request Submitted': 'Çekim Talebi Gönderildi',

'Please note that you will receive an email once it is completed':
'Lütfen tamamlandığında bir e-posta alacağınızı unutmayın',

'The receiver will get': 'Alıcı alacak',
'Save Address': 'Adresi Kaydet',
'One-Step Withdrawal': 'Tek Adımda Çekim',

'Enable One-Step Withdrawal to withdraw to whitelist addresses easily.':
"Beyaz liste adreslerine kolayca çekmek için Tek Adımda Çekim'i etkinleştirin.",

'Please input your Recipient!': 'Lütfen Alıcıyı girin!',
'Cryptizi ID': 'Cryptizi Kimliği',
'Recipient’s email': 'Alıcının e-postası',
'Orders history': 'Sipariş geçmişi',
Recive: 'Al',
'Buy Crypto with Debit / Credit Card': 'Banka Kartı ile Kripto Satın Al',
max: 'maksimum',
'Trades History': 'İşlem Geçmişi',
'Orders History': 'Sipariş Geçmişi',
'Convert History': 'Dönüştürme Geçmişi',
'Internal Transactions': 'İçsel İşlemler',
'Futures History': 'Vadeli İşlem Geçmişi',
'Crypto Transactions': 'Kripto İşlemler',
'Fiat Transactions': 'Fiat İşlemleri',
ALL: 'TÜMÜ',
BUY: 'AL',
SELL: 'SAT',
MARKET: 'PAZAR',
LIMIT: 'LİMİT',
'STOP LIMIT': 'DURDURMA LİMİTİ',
CANCELED: 'İPTAL EDİLDİ',
EXPIRED: 'SÜRESİ DOLDU',
FILLED: 'DOLDU',
NEW: 'YENİ',
'PARTIALLY FILLED': 'KISMEN DOLDU',
'PENDING CANCEL': 'BEKLEYEN İPTAL',
REJECTED: 'REDDEDİLDİ',
Pair: 'Çift',
Side: 'Yön',
Filed: 'Kaydedildi',
"Commission": "Komisyon",
"State": "Durum",
"Credit Card": "Kredi Kartı",
"Please input a valid CVV2!": "Lütfen geçerli bir CVV2 girin!",
"Master Card": "Master Kart",
"Visa Card": "Visa Kart",
"Please input your old email address!": "Lütfen eski e-posta adresinizi girin!",
"Change Email": "E-postayı Değiştir",
"Please input your new email!": "Lütfen yeni e-postanızı girin!",
"New Email": "Yeni E-posta",
"Email changed successfully": "E-posta başarıyla değiştirildi",
"Tickets": "Biletler",
"Order": "Sipariş",
"Account Settings": "Hesap Ayarları",
"Settings Network": "Ağ Ayarları",
"Settings": "Ayarlar",
"Users": "Kullanıcılar",
"Admins": "Yöneticiler",
"Referral marketing": "Yönlendirme Pazarlama",
"Crypto Wallet Transactions": "Kripto Cüzdan İşlemleri",
"Wallet Transactions": "Cüzdan İşlemleri",
"Automatic Transactions": "Otomatik İşlemler",
"List Of All Bank Cards": "Tüm Banka Kartları Listesi",
"User's": "Kullanıcıların",
"Notification": "Bildirim",
"Reporting": "Raporlama",
"Tether Setting": "Tether Ayarı",
"Inventory Settings": "Envanter Ayarları",
"Daily Rial withdrawal ceiling": "Günlük Riyal çekim sınırı",
"Buy Digital Currency": "Dijital Para Satın Al",
"API settings": "API Ayarları",
"General settings": "Genel Ayarlar",
"IP Block": "IP Engelleme",
"User level settings": "Kullanıcı Düzeyi Ayarları",
"Deposit With Receipt": "Fişli Yatırım",
"Popup Settings": "Açılır Pencere Ayarları",
"Commission settings": "Komisyon Ayarları",
"Related To Reference Account": "Referans Hesap ile İlgili",
"Automatic Deposit settings": "Otomatik Yatırım Ayarları",
"Dedicated Gateway settings": "Ayrılmış Geçiş Ayarları",
"All users": "Tüm kullanıcılar",
"Add user": "Kullanıcı ekle",
"Add Admin": "Yönetici Ekle",
"Two-step login via Google": "Google üzerinden İki Adımlı Giriş",
"Activation": "Aktivasyon",
"6-digit google code": "6 haneli Google kodu",
"Two-step login via Sms": "Sms üzerinden İki Adımlı Giriş",
"Send SMS": "Sms Gönder",
"SMS Code": "SMS Kodu",
"Two-step login via Email": "E-posta üzerinden İki Adımlı Giriş",
"Send Email": "E-posta Gönder",
"Email Code": "E-posta Kodu",
"Tether price inquiry from api and automatic": "API ve Otomatikten Tether fiyat sorgulama",
"Settings Tether": "Tether Ayarları",
"Caption": "Başlık",
"Please select a gateway!": "Lütfen bir geçit seçin!",
"Inventory settings": "Envanter Ayarları",
"API status for inventory": "Envanter için API durumu",
"Cancel": "İptal",
"Buy digital currency": "Dijital para satın al",
"Sell digital currency": "Dijital para satışı",
"Digital currency": "Dijital para",
"Gateway Setting": "Geçit Ayarı",
"Voucher Settings": "Kupon Ayarları",
"Pay Safe Settings": "Güvenli Ödeme Ayarları",
"Perfect Money Settings": "Perfect Money Ayarları",
"Cryptocurrency Setting": "Kripto Para Ayarı",
"Settings related to vouchers": "Kuponlarla ilgili ayarlar",
"Purchase status": "Satın alma durumu",
"Sales status": "Satış durumu",
"apikey": "API Anahtarı",
"seckey": "Gizli Anahtar",
"Query the price of dollars from the api": "Api'den dolar fiyatını sorgula",
"Purchase price in dollars": "Dolar cinsinden satış fiyatı",
"Percentage on the price to buy": "Alım fiyatı üzerinde yüzde",
"Purchase price times this number": "Bu sayıyı kullanarak alım fiyatı",
"Selling price in dollars": "Dolar cinsinden satış fiyatı",
"Percentage in price for sale": "Satış için fiyatta yüzde",
"Selling price times this number": "Bu sayıyı kullanarak satış fiyatı",
"Percentage in the price for purchase (above one hundred tomans)": "Alım fiyatı üzerinde yüzde (yüz tomanın üstünde)",
"Percentage in the price for sale (above one hundred tomans)": "Satış fiyatı üzerinde yüzde (yüz tomanın üstünde)",
"Settings related to Pay Safe Voucher": "Pay Safe Kuponlarıyla İlgili Ayarlar",
"UserName": "Kullanıcı Adı",
"AccountID": "Hesap Kimliği",
"Purchase status of Perfect Money voucher": "Perfect Money Kuponunun Satın Alma Durumu",
"Settings related to Perfect Money": "Perfect Money ile İlgili Ayarlar",
"Perfect Money Voucher sales status": "Perfect Money Kuponunun Satış Durumu",
"Perfect Money Voucher": "Perfect Money Kuponu",
"Settings related to cryptocurrency reference account": "Kripto Para Referans Hesabı ile İlgili Ayarlar",
"Binance apikey": "Binance API Anahtarı",
"Binance seckey": "Binance Gizli Anahtar",
"Settings Cryptocurrency": "Kripto Para Ayarları",
"Enter the code that was sent to your previous mobile number and email in the box below": "Lütfen aşağıdaki kutuya önceki mobil numaranıza ve e-postanıza gönderilen kodu girin",
"New Email verification code": "Yeni E-posta doğrulama kodu",
'Add Market': 'Pazar Ekle',
Name: 'Ad',
Symbol: 'Sembol',
'Name Persian': 'Farsça Ad',
Selector: 'Seçici',
'Network Status': 'Ağ Durumu',
'Settings network': 'Ağ Ayarları',
'Please input networks name!': 'Lütfen ağın adını girin!',
"Please input network's selector!": 'Lütfen ağın seçicisini girin!',
"Please input network's persian name!": 'Lütfen ağın Farsça adını girin!',
"Please input network's symbol!": 'Lütfen ağın sembolünü girin!',
'Please input Minimum allowed!': 'Lütfen minimum izin verileni girin!',
'Minimum allowed purchase': 'Minimum izin verilen satış',
'Please input Daily purchase amount!': 'Lütfen günlük satın alma miktarını girin!',
'IP test mode (to disable, leave the field blank)': 'IP test modu (devre dışı bırakmak için alanı boş bırakın)',

'Purchases from the site (inserting a message in the box below means deactivating purchases from the site)':
'Siteden yapılan alımlar (aşağıdaki kutuya bir mesaj eklemek, siteden alımların devre dışı bırakılması anlamına gelir)',

'Daily purchase amount during registration': 'Kayıt sırasında günlük satın alma miktarı',
'Deposit settings with receipt': 'Fişli depozito ayarları',
'Bank name': 'Banka adı',
'Card number': 'Kart numarası',
'Account number': 'Hesap numarası',
'Shaba number': 'Shaba numarası',
'Please input number of days!': 'Lütfen gün sayısını girin!',
'Popup settings': 'Açılır pencere ayarları',

'After each login, it shows the password (that is, if he has not used remember me) and also shows it every few days if he has remembered me!':
'Her girişten sonra parolayı gösterir (yani, hatırla beni kullanmamışsa) ve ayrıca hatırla beni kullanmışsa her birkaç günde bir gösterir!',

Percentage: 'Yüzde',
'Automatic deposit settings': 'Otomatik depozito ayarları',
Business: 'İş',
'Deposit model': 'Depozito modeli',
Mobile: 'Mobil',
'Rejection of automatic deposit for the following banks': 'Aşağıdaki bankalar için otomatik depozito reddi',
Sat: 'Cmt',
Sun: 'Paz',
Mon: 'Pzt',
Tue: 'Sal',
Wed: 'Çar',
Thu: 'Per',
Fri: 'Cum',
Active: 'Aktif',
Token: 'Token',
Inactive: 'Pasif',
'Iran GATEWAY': 'İran GATEWAY',
'Contract text': 'Sözleşme metni',
'SMS confirmation for withdrawal and purchase': 'Çekme ve satın alma için SMS onayı',
'International GATEWAY': 'Uluslararası GATEWAY',
'Iranian payment gateways Appearance': 'İran ödeme kapıları Görünümü',
'Minimum wallet withdrawal amount': 'Minimum cüzdan çekme miktarı',
'Minimum amount of currency sales': 'Minimum para birimi satış miktarı',
'Dedicated Gateway settings': 'Ayrılmış Kapı Ayarları',
disable: 'Devre dışı bırak',
active: 'Aktif',

'This is the agreement between Iranianex.com and the user who uses the various services of the site. All users of Iranianex.com should read these rules completely and then become a member of the site if they accept them. Iranianex.com can update the terms of this agreement every month according to the changes in the laws. Registering on this site, using its services such as buying and selling, etc., means that you have read and accepted the terms of this contract. The user accepts that he is the legal owner. This is the agreement between Iranianex.com and the user who uses the various services of the site.':
"Bu, Iranianex.com ile sitenin çeşitli hizmetlerini kullanan kullanıcı arasındaki anlaşmadır. Iranianex.com'un tüm kullanıcıları bu kuralları tamamen okumalı ve ardından bunları kabul ederlerse siteye üye olmalıdır. Iranianex.com, yasal değişikliklere göre bu anlaşmanın şartlarını her ay güncelleyebilir. Bu siteye kaydolmak, satın alma ve satma gibi hizmetlerini kullanmak vb. şeyler, bu sözleşmenin şartlarını okuduğunuzu ve kabul ettiğinizi gösterir. Kullanıcı, yasal sahibi olduğunu kabul eder. Bu, Iranianex.com ile çeşitli hizmetleri kullanıcısı olan kullanıcı arasındaki anlaşmadır.",

'Add New Admin': 'Yeni Yönetici Ekle',
Admin: 'Yönetici',
Block: 'Engelle',
Edit: 'Düzenle',
Delete: 'Sil',
'List of accounts': 'Hesaplar listesi',
'Crypto transactions': 'Kripto işlemleri',
'Bank cards': 'Banka kartları',
Log: 'Günlük',
'Toman transactions': 'Toman işlemleri',
'Please input name!': 'Lütfen adınızı girin!',
'Please input mobile!': 'Lütfen cep telefonu numarasını girin!',
'Please input email!': 'Lütfen e-posta adresinizi girin!',
'Please input password!': 'Lütfen şifrenizi girin!',
'Add new admin': 'Yeni yönetici ekle',
Deactive: 'Pasifleştir',
'Delete user': 'Kullanıcıyı sil',
Access: 'Erişim',
'Two-step login': 'İki adımlı giriş',
'Upload photo': 'Fotoğraf yükle',
'Change photo': 'Fotoğraf değiştir',
Notifications: 'Bildirimler',
'Wallet deposit and withdrawal': 'Cüzdan yatırma ve çekme',
Filter: 'Filtre',
'View transactions': 'İşlemleri görüntüle',
'Edit Admin': 'Yöneticiyi Düzenle',
'Wallet transactions': 'Cüzdan işlemleri',

'User wallet transactions that have been changed by this admin':
'Bu yönetici tarafından değiştirilen kullanıcı cüzdan işlemleri',

Bank: 'Banka',
'Amount of': 'Miktar',
Currency: 'Para Birimi',
Status: 'Durum',
Detail: 'Detay',
'Cryptocurrency wallet transactions': 'Kripto para cüzdanı işlemleri',
Regarding: 'İlgili',
'Business transactions': 'İşlem işlemleri',
'Transactions of a transaction': 'İşlemin işlemleri',

'ID transaction': 'İşlem Kimliği',

'You can see the cryptocurrency wallet transactions that the admin has changed':
'Yöneticinin değiştirdiği kripto para cüzdanı işlemlerini görebilirsiniz',

'The list of users whose document image has been changed by this admin.':
'Belge görüntüsü bu yönetici tarafından değiştirilen kullanıcıların listesi.',

Level: 'Seviye',
'Add admin': 'Yönetici Ekle',
'New Admin': 'Yeni Yönetici',

'You can not disable a port that is in the default state.':
'Varsayılan durumda olan bir bağlantı noktasını devre dışı bırakamazsınız.',

'Order type': 'Sipariş türü',
Tether: 'Tether',
User: 'Kullanıcı',
'Transfer fee': 'Transfer ücreti',
network: 'ağ',
'The amount': 'Miktar',
Close: 'Kapat',
'Address wallet': 'Cüzdan adresi',
'Displaying saved parameters (Click)': 'Kaydedilen parametreleri görüntüle (Tıklayın)',
'Transactions Details': 'İşlem Detayları',
Success: 'Başarılı',
Failed: 'Başarısız',

'You can see the total number of records on the current page, and for the total number of all displays':
'Mevcut sayfadaki toplam kayıt sayısını görebilirsiniz ve tüm görüntülerin toplam sayısını görebilirsiniz',
"Account list": "Hesap listesi",
"Group payment": "Grup ödemesi",
"Sheba's list": "Sheba listesi",
"You can see the wallet transactions of users.": "Kullanıcıların cüzdan işlemlerini görebilirsiniz.",
"View": "Görüntüle",
"The transfer encountered an error": "Transfer bir hatayla karşılaştı",
"Transaction number": "İşlem numarası",
"Transaction amount": "İşlem tutarı",
"IP": "IP",
"Done": "Tamamlandı",
"New": "Yeni",
"Your Notifications": "Bildirimleriniz",
"View All Notifications": "Tüm Bildirimleri Görüntüle",
"Sort by": "Sırala",
"last 2 week": "son 2 hafta",
"No Data": "Veri Yok",
"Dollar": "Dolar",
"Payment unit": "Ödeme birimi",
"Done successfully": "Başarıyla tamamlandı",
"Voucher code": "Kupon kodu",
"MAX": "MAKS",
"Select Coin": "Kripto Seçin",
"Main Wallet": "Ana Cüzdan",
"Funding Wallet": "Finansman Cüzdanı",
"Select Wallet": "Cüzdan Seçin",
"Please select a coin firstly!": "Lütfen önce bir kripto seçin!",
"Enter Tag": "Etiket Girin",
"Add New Address": "Yeni Adres Ekleyin",
"Select from Address Book": "Adres Defterinden Seçin",
"Phone": "Telefon",
"balance": "bakiye",
"Receive {{receive}} {{symbol}}": "Al {{receive}} {{symbol}}",
"Top gainer (24h)": "En İyi Kazanç Sağlayan (24s)",
"Highest volume (24h)": "En Yüksek Hacim (24s)",
"Most Visited": "En Çok Ziyaret Edilen",
"New listing": "Yeni Listeleme",
"24h Change": "24s Değişim",
"24h Volume": "24s Hacmi",
"Market Cap": "Piyasa Değeri",
"Crypto Market": "Kripto Piyasa",
"If you need help, watch the guide video in this section": "Yardıma ihtiyacınız varsa, bu bölümdeki kılavuz videosunu izleyin",
"Watch the movie": "Filmi izle",
"Your Share": "Sizin Payınız",
"Share friends": "Arkadaşlarınızla Paylaşın",
"Reports": "Raporlar",
"List of trades in your subset": "Alt kümenizdeki işlemlerin listesi",
"Your income chart": "Gelir grafiğiniz",
"List of introduced users": "Tanıtılan kullanıcıların listesi",
"Link and introduction code": "Bağlantı ve tanıtım kodu",
"By providing a link or referral code to your friends, you can earn money from their transaction fees and also allocate a part of this income to them, which is both for their benefit and for your benefit.":
"Arkadaşlarınıza bir bağlantı veya referans kodu sağlayarak, onların işlem ücretlerinden para kazanabilir ve ayrıca bu gelirin bir kısmını onlara ayırabilirsiniz, bu hem onların hem de sizin yararınıza olan bir şeydir.",
"Set percentage for submissions": "Gönderiler için yüzde belirleme",
"30% of your friends transaction fee is provided for you and by setting this section you can divide your income among the people you have introduced.":
"Arkadaşlarınızın işlem ücretinin %30'u size sağlanır ve bu bölümü ayarlayarak tanıttığınız kişiler arasında gelirinizi bölebilirsiniz.",
"Number": "Numara",
"Details": "Detaylar",
"Add Ticket": "Bilet Ekle",
"Subject": "Konu",
"Please input section!": "Lütfen bölümü girin!",
"Please input description!": "Lütfen açıklamayı girin!",
"Finance": "Finans",
"Technical": "Teknik",
"KYC": "KYC",
"Name Ticket": "Bilet Adı",
"Section": "Bölüm",
"History of your trades": "İşlemlerinizin Geçmişi",
"Time": "Zaman",
"History of your transactions": "İşlemlerinizin Geçmişi",
"Past 30 days": "Son 30 gün",
"Past 90 days": "Son 90 gün",
"Credited": "Kredilendirildi",
"Pending": "Beklemede",
"Past 7 days": "Son 7 gün",
"Asset": "Varlık",
"Enter TxID": "TxID Girin",
"Select Asset": "Varlık Seçin",
"Destination": "Hedef",
"Failure": "Başarısızlık",
"Canceled": "İptal Edildi",
"Deposit ID": "Depozito Kimliği",
"Please input your old Email address!": "Lütfen eski E-posta adresinizi girin!",
"Fiat Withdraw": "Fiat Çekme",
"Withdrawal of wallet": "Cüzdan Çekme",
"Your recoverable inventory:": "Kurtarılabilir envanteriniz:",
"Equivalent daily withdrawal amount: 0 out of 500,000,000 Tomans": "Eşdeğer günlük çekim miktarı: 0 / 500,000,000 Tomans",
"When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is $ 11.": "Depozito miktarını belirlerken, borsadaki minimum alım ve satım miktarının 11 $ olduğundan emin olun.",
"Bank account to receive": "Almak için banka hesabı",
'Buy & Sell': 'Satın Al & Sat',
"All Model": "Tüm Modeller",
"Management": "Yönetim",
"Account Setting": "Hesap Ayarı",
"Profile": "Profil",
"Waiting Actions": "Bekleyen İşlemler",
"Admin Utilities": "Yönetici Araçları",
"Title": "Başlık",
"Today": "Bugün",
"Yesterday": "Dün",
"This week": "Bu hafta",
"This month": "Bu ay",
"This Year": "Bu Yıl",
"Total value": "Toplam Değer",
"Sales": "Satışlar",
"System status": "Sistem Durumu",
"Website": "Web sitesi",
"PaySafe Voucher": "PaySafe Kupon",
"PMvoucher": "PM Kupon",
"Name Currency": "Para Birimi Adı",
"Sale": "Satış",
"Stock": "Stok",
"Amount of orders": "Sipariş Miktarı",
"Sales number": "Satış Numarası",
"Selling price": "Satış Fiyatı",
"Purchases": "Alımlar",
"Purchase amount": "Alım Miktarı",
"New User": "Yeni Kullanıcı",
"Most sales": "En Fazla Satış",
"Most purchases": "En Fazla Alım",
"Users waiting for Verify": "Onay Bekleyen Kullanıcılar",
"Fiat Transaction Waiting": "Fiat İşlem Bekliyor",
"Reset": "Sıfırla",
"You can see the list of users whose information must be confirmed.": "Onaylanması gereken kullanıcıların listesini görebilirsiniz.",
"Edit / Delete": "Düzenle / Sil",
"Basic": "Temel",
"Crypto wallet": "Kripto Cüzdan",
"Fiat wallet": "Fiat Cüzdan",
"Crypto Transaction": "Kripto İşlem",
"Cards": "Kartlar",
"Trade history": "İşlem Geçmişi",
"Earn ": "Kazan ",
"please fill the blank": "lütfen boşluğu doldurun",
"Other": "Diğer",
"Intermediate": "Orta Düzey",
"Advanced": "Gelişmiş",
"Send Form": "Form Gönder",
"Middle Name": "İkinci Ad",
"Country": "Ülke",
"Street Address": "Cadde Adresi",
"birthDate": "Doğum Tarihi",
"Postal Code": "Posta Kodu",
"City": "Şehir",
"Phone Number": "Telefon Numarası",
"Approve": "Onayla",
"Reject": "Reddet",
"Send Notification": "Bildirim Gönder",
"Wallet’s User": "Cüzdan Kullanıcısı",
"Total inventory": "Toplam Envanter",
"Equivalent to Dollar": "Dolara Eşdeğer",
"Inventory available": "Mevcut Envanter",
"Create a transaction": "İşlem Oluştur",
"Add transaction": "İşlem Ekle",
"Error": "Hata",
"Edit User": "Kullanıcıyı Düzenle",
"Create": "Oluştur",
"Update photo": "Fotoğrafı Güncelle",
"Value Transaction": "Değer İşlemi",
"Account Transaction": "Hesap İşlemi",
'Add Card': 'Kart Ekle',
'Add card': 'Kart Ekle',
Shaba: 'Shaba',
Waiting: 'Beklemede',
Message: 'Mesaj',
Payment: 'Ödeme',
Port: 'Limansız',
'Order value': 'Sipariş Değeri',
success: 'Başarılı',
Earn: 'Kazan',
'List of users that this user has introduced': 'Bu kullanıcının tanıttığı kullanıcıların listesi',
'Value Order': 'Değer Siparişi',
'User commission': 'Kullanıcı Komisyonu',
'ID card': 'Kimlik Kartı',
Passport: 'Pasaport',
'Upload .PDF/.JPG/.JPEG/.PNG file and no more than 5M': ".PDF/.JPG/.JPEG/.PNG dosyasını yükleyin ve 5M'den fazla olmasın",
'Select your Country / region': 'Ülkenizi / bölgenizi seçin',
'Change level': 'Seviyeyi Değiştir',
'Change password': 'Şifre Değiştir',
'Representative mobile': 'Temsilci Mobil',
'List of all bank cards': 'Tüm banka kartlarının listesi',
'To edit, delete or approve or disapprove a bank card, click on the link to be redirected to the bank cards section in the users profile.': 'Bir banka kartını düzenlemek, silmek, onaylamak veya reddetmek için, kullanıcı profilinde banka kartları bölümüne yönlendirilmek üzere bağlantıya tıklayın.',
'Edit card': 'Kartı Düzenle',
'Edit Card': 'Kartı Düzenle',
'Fiat transactions': 'Fiat İşlemleri',
'Edit admin': 'Yöneticiyi Düzenle',
'API Setting': 'API Ayarı',
'Network Setting': 'Ağ Ayarı',
Referrals: 'Referanslar',
'Dollar balance': 'Dolar bakiyesi',
'Rial balance': 'Rial bakiyesi',
'Percentage in the price for ( marketing )': 'Pazarlama için fiyatın yüzdesi',
'Perfect Money ': 'Mükemmel Para',
'Percentage in the price for purchase': 'Satın alma için fiyatın yüzdesi',
'Percentage in the price for sale': 'Satış için fiyatın yüzdesi',
'Network Name': 'Ağ Adı',
'General Settings': 'Genel Ayarlar',
'Type ...': 'Tür ...',
"Account Holder's name": "Hesap Sahibinin Adı",
Vandar: 'Vandar',
PIR: 'PIR',
Zibal: 'Zibal',
'Add User': 'Kullanıcı Ekle',
BASIC: 'TEMEL',
Disapproval: 'Onaylanmama',
REGISTERED: 'KAYITLI',
BASIC_BY_ADMIN: 'YÖNETİCİ TARAFINDAN TEMEL',
INTERMEDIATE_REQUESTED: 'ARA DERECE TALEP EDİLİYOR',
ADVANCED_BY_ADMIN: 'YÖNETİCİ TARAFINDAN İLERİ',
INTERMEDIATE_BY_ADMIN: 'YÖNETİCİ TARAFINDAN ORTA',
ADVANCED_REQUESTED: 'İLERİ DERECE TALEP EDİLİYOR',
Google: 'Google',
LastName: 'Soyadı',
Sms: 'SMS',
'Father Name': 'Baba Adı',
'National Number': 'Ulusal Numara',
'Birth Date': 'Doğum Tarihi',
'Login Date': 'Giriş Tarihi',
Verification: 'Doğrulama',
Tell: 'Söyle',
'Add New Card': 'Yeni Kart Ekle',
Support: 'Destek',
caption: 'Altyazı',
'List of commission payments to the wallet': 'Cüzdana yapılan komisyon ödemelerinin listesi',
'Referral Marketing': 'Referans Pazarlama',
waiting: 'Bekliyor',
'Transfer completed successfully': 'Transfer başarıyla tamamlandı',
'All User': 'Tüm Kullanıcılar',
'Perfect Money buyers/sellers': 'Mükemmel Para alıcıları/satıcıları',
'CryptoCurrency buyers/sellers': 'Kripto Para alıcıları/satıcıları',
'Paysafe buyers/sellers': 'Paysafe alıcıları/satıcıları',
'Vouchers buyers/sellers': 'Kupon alıcıları/satıcıları',
'Referral Users': 'Referans Kullanıcılar',
'Verified Users': 'Onaylanmış Kullanıcılar',
"Users Who hasn't trade yet": 'Henüz işlem yapmayan kullanıcılar',
'This category does not apply to header alerts and shows for everyone': 'Bu kategori başlık uyarılarına uygulanmaz ve herkes için gösterilir',
'The title does not apply to sms': 'Başlık, SMS için uygulanmaz',
'Unverified Users': 'Onaylanmamış Kullanıcılar',
'Notification type': 'Bildirim türü',
'Users whos Google Authenticator is inactive': "Google Kimlik Doğrulama'yı etkin olmayan kullanıcılar",
'Users whos SMS Authenticator is inactive': "SMS Kimlik Doğrulama'yı etkin olmayan kullanıcılar",
'Basic level Users': 'Temel düzey kullanıcılar',
'Intermediate Users': 'Orta düzey kullanıcılar',
'Advanced Users': 'İleri düzey kullanıcılar',
'User Statistics': 'Kullanıcı İstatistikleri',
'Open trades': 'Açık işlemler',
'Futures Open position': 'Vadeli İşlem Açık Pozisyon',
'Deposit Statistics': 'Para Yatırma İstatistikleri',
'Crypto Statistics': 'Kripto İstatistikleri',
'Successful Orders': 'Başarılı İşlemler',
'Withdraw Statistics': 'Para Çekme İstatistikleri',
'Unsuccessful Orders': 'Başarısız işlem',
'12 H': '12 Saat',
'1 Day': '1 Gün',
'1 Week': '1 Hafta',
'1 Months': '1 Ay',
'1 Years': '1 Yıl',
'last day': 'Son Gün',
'Buy/Sell': 'Al/Sat',
Desktop: 'Masaüstü',
Select: 'Seç',
Report: 'Rapor',
'Deactivate user': 'Kullanıcıyı Devre Dışı Bırak',
'Active user': 'Aktif Kullanıcı',
Excel: 'Excel',
PDF: 'PDF',
Gateway: 'Ara yüz',
'Post New Ad': 'Yeni İlan Gönder',
Remainder: 'Kalan',
'Ads List': 'İlan Listesi',
Post: 'Gönder',
'Total Amount': 'Toplam Miktar',
'With Fiat': 'Fiat ile',
'Minimum order limit': 'Minimum sipariş limiti',
'Maximum order limit': 'Maksimum sipariş limiti',
'Please select the type!': 'Lütfen türü seçin!',
'Perfect Money': 'Mükemmel Para',
'Voucher PaySafe': 'PaySafe Kuponu',
'You can also enter the amount': 'Ayrıca miktarı da girebilirsiniz',
'Date & Time': 'Tarih ve Saat',
'Serial number': 'Seri numarası',
Continue: 'Devam',
Hot: 'Sıcak',
'Choose Payment method': 'Ödeme yöntemi seçin',
Previous: 'Önceki',
Confirm: 'Onayla',
'History of your orders': 'Siparişlerinizin geçmişi',
Fiat: 'Fiat',
'P2P History': 'P2P Geçmişi',
'History of your P2P orders': 'P2P siparişlerinizin geçmişi',
TAKE: 'AL',
MAKE: 'YAP',
COMPLETED: 'TAMAMLANDI',
FAILED: 'BAŞARISIZ',
PARTIALLY_COMPLETED: 'KISMEN TAMAMLANDI',
PENDING: 'BEKLEMEDE',
'Please input your oldMobile!': 'Lütfen eski cep telefonunuzu girin!',
'Please input your new mobile!': 'Lütfen yeni cep telefonunuzu girin!',
'Change Mobile': 'Cep Telefonunu Değiştir',
'New Mobile': 'Yeni Cep Telefonu',
'Please input your oldEmailAddress!': 'Lütfen eski e-posta adresinizi girin!',
'Paysafe total value': 'Paysafe toplam değeri',
'Perfect Money total value': 'Perfect Money toplam değeri',
'Tether total value': 'Tether toplam değeri',
'Automatic Deposit History': 'Otomatik Yatırım Geçmişi',
'Sheba number': 'Sheba numarası',
Unblock: 'Engeli Kaldır',
undefined: 'Tanımsız',
Approved: 'Onaylandı',
Rejected: 'Reddedildi',
'You can see the list of commissions received by this user through introduction.':
'Bu kullanıcının tanıtım yoluyla aldığı komisyonların listesini görebilirsiniz.',
INTERMEDIATE: 'ORTA',
ADVANCED: 'İLERİ',
Family: 'Aile',
'Please input first name!': 'Lütfen adınızı girin!',
'Please input email address!': 'Lütfen e-posta adresinizi girin!',
'Please input last name!': 'Lütfen soyadınızı girin!',
"If you don't decide to change the password, leave this field blank":
'Şifre değiştirmeye karar vermezseniz, bu alanı boş bırakın',
'Access Denied': 'Erişim Engellendi',
'Fixed panel page header': 'Sabit panel sayfa başlığı',
'By notification': 'Bildirimle',
'Header panel pages only once': 'Başlık paneli sayfaları yalnızca bir kez',
'by Email': 'E-postayla',
'Via SMS': 'SMS ile',
Desktap: 'Masaüstü',
'Deactive user': 'Kullanıcıyı Devre Dışı Bırak',
'Buy Crypto': 'Kripto Satın Al',
'Contact Us': 'Bize Ulaşın',
'Privacy policy': 'Gizlilik Politikası',
'E-money': 'Elektronik Para',
Verify: 'Doğrula',
Announcement: 'Duyuru',
API: 'API (Uygulama Programlama Arayüzü)',
'OTC Terms': 'OTC (Tezgah Üstü) Koşulları',
'Convert Terms': 'Dönüştürme Koşulları',
'Please enter {{min}}-{{max}}': 'Lütfen {{min}}-{{max}} aralığında girin',
Favorites: 'Favoriler',
'All Cryptos': 'Tüm Kriptolar',
'Spot Markets': 'Spot Piyasalar',
'Futures Markets': 'Vadeli İşlem Piyasaları',
'New Markets': 'Yeni Piyasalar',
Metaverse: 'Metaverse',
Gaming: 'Oyun',
Defi: 'DeFi (Merkezi Olmayan Finans)',
Innovation: 'İnovasyon',
'Layer1/layer2': 'Katman 1/Katman 2',
'Fan Toket': 'Fan Tokeni',
NFT: 'NFT (Değiştirilemez Tokenler)',
Storage: 'Depolama',
Palkadot: 'Polkadot',
POS: 'DPoS (Paylaşımlı Delege İş Kanıtı)',
POW: 'PoW (İş Kanıtı)',
'1 Month': '1 Ay',
Symbols: 'Semboller',
'1 Year': '1 Yıl',
Open: 'Açık',
Remove: 'Kaldır',
FINANCE: 'Finans',
TECHNICAL: 'Teknik',
'Close ticket': 'Bilet Kapat',
'Updated Date': 'Güncelleme Tarihi',
'Type a message': 'Bir mesaj yazın',
'How satisfying it was ?': 'Ne kadar memnun kaldınız?',
'Ticket closed': 'Bilet kapatıldı',
'Your current Limit': 'Mevcut Limitiniz',
'Switch to enterprise Account': 'Kurumsal Hesaba Geçiş Yapın',
'Video Guide': 'Video Kılavuzu',
'Personal Information': 'Kişisel Bilgiler',
'Crypto Deposit': 'Kripto Yatırım',
Unlimited: 'Sınırsız',
'Most Popular': 'En Popüler',
'All basic Requirements': 'Tüm Temel Gereksinimler',
'Government ID': 'Hükümet Kimliği',
'Facial Verification': 'Yüz Doğrulama',
'Review time': 'İnceleme süresi',
days: 'gün',
'Fiat Deposit & Withdrawal Limit': 'Fiat Yatırım ve Çekim Limiti',
Daily: 'Günlük',
'P2P Transaction Limits': 'P2P İşlem Limitleri',
'Crypto Withdraw': 'Kripto Para Çekme',
'Highest Fiat Limit': 'En Yüksek Fiat Limiti',
'Proof of Address': 'Adres Kanıtı',
'Quick & Easy': 'Hızlı ve Kolay',
'WAITING': 'BEKLEMEDE',
'Advanced requested': 'İleri talep edilen',
'Intermediate requested': 'Orta talep edilen',
'ACTIVE': 'AKTİF',
'Intermediate by admin': 'Orta yönetici tarafından',
'Advanced by admin': 'İleri yönetici tarafından',
'Registered': 'Kayıtlı',
'Select Approve or Reject status': 'Onayla veya Reddet durumunu seçin',
'Blocked': 'Engellendi',
'USDT Value': 'USDT Değeri',
'USD Value': 'USD Değeri',
'Please fill the blank': 'Lütfen boşluğu doldurun',
'Please select one': 'Lütfen birini seçin',
'ID Card': 'Kimlik Kartı',
'User Image': 'Kullanıcı Resmi',
'Country / region': 'Ülke / Bölge',
'The user reject successfully': 'Kullanıcı başarıyla reddedildi',
'Advanced Verification': 'İleri Düzey Doğrulama',
'Confirm Residential Address': 'Konut Adresini Onayla',
'Please choose and upload one of the following documents': 'Lütfen aşağıdaki belgelerden birini seçin ve yükleyin',
'Utility bill (electricity, gaz. water/sewage, fibre/broadband service)':
'Fatura (elektrik, gaz, su/kanalizasyon, fiber/geniş bant hizmeti)',
'Select Photo': 'Fotoğraf Seç',
'Upload .JPG/.JPEG/.PNG file and no more than 5M': ".JPG/.JPEG/.PNG dosyasını yükleyin ve 5M'den fazla olmasın",
'Bank statement': 'Banka Hesap Özeti',
'Basic Verification': 'Temel Doğrulama',
'Identity Information': 'Kimlik Bilgileri',
'Nationality': 'Uyruğu',
'Select your nationality': 'Uyruğunuzu seçin',
'date of birth': 'Doğum tarihi',
'Please input your street address!': 'Lütfen sokak adresinizi girin!',
'Please input your city!': 'Lütfen şehrinizi girin!',
'Please input your phone number!': 'Lütfen telefon numaranızı girin!',
'Please input your postal code!': 'Lütfen posta kodunuzu girin!',
'Use a valid government-issued document': 'Geçerli bir hükümet tarafından verilen belge kullanın',
'Only the following documents listed below will be accepted': 'Aşağıda listelenen sadece belgeler kabul edilecektir',
'Id Card': 'Kimlik Kartı',
'Government-issued': 'Hükümet tarafından verilen',
'place documents against a single-colored background': 'Belgeleri tek renkli bir arka plana yerleştirin',
'Readable, well-lit, colored images': 'Okunabilir, iyi aydınlatılmış, renkli görüntüler',
'Original Full-size, unedited documents': 'Orijinal tam boyutlu, düzenlenmemiş belgeler',
'No black and white images': 'Siyah beyaz görüntüler yok',
'No edited or expired documents': 'Düzenlenmiş veya süresi dolmuş belgeler yok',
'file size must be between 10kb and 512kb in jpg/jpeg/png format':
'Dosya boyutu jpg/jpeg/png formatında 10 KB ile 512 KB arasında olmalıdır',
'Basic information': 'Temel bilgiler',
'Change Password': 'Parolayı Değiştir',
'Two-step verification': 'İki adımlı doğrulama',
'Change email': 'E-postayı Değiştir',
'Receive SMS after transactions': 'İşlemlerden sonra SMS al',
'Receive notifications after transactions (Guide)': 'İşlemlerden sonra bildirim al (Kılavuz)',
'Activities': 'Etkinlikler',
'Receive SMS after each login': 'Her girişten sonra SMS al',
'Receive emails after transactions': 'İşlemlerden sonra e-posta al',
'Receive emails after each login': 'Her girişten sonra e-posta al',
'User ID': 'Kullanıcı Kimliği',
'Registration via : Website': 'Kayıt : Websitesi üzerinden',
'Registration Date': 'Kayıt Tarihi',
'Save Changes': 'Değişiklikleri Kaydet',
'Default': 'Varsayılan',
'Please input a valid oldEmailAddress': 'Lütfen geçerli bir eski e-posta adresi girin',
'Please input a valid email': 'Lütfen geçerli bir e-posta adresi girin',
'GO BACK': 'GERİ DÖN',
'Total Buy': 'Toplam Satın Alma',
'Total Referrals': 'Toplam Tavsiyeler',
'Total Tickets': 'Toplam Biletler',
'Total Transactions': 'Toplam İşlemler',
'Highest Buy': 'En Yüksek Satın Alma',
'Highest Sell': 'En Yüksek Satış',
'Total Trade': 'Toplam Ticaret',
'Total Order': 'Toplam Sipariş',
'Total Fiat Income': 'Toplam Fiat Geliri',
'Block user': 'Kullanıcıyı Engelle',
'Intermediate user': 'Orta Düzey Kullanıcı',
'Advanced user': 'Gelişmiş Kullanıcı',
'items': 'parça',
'Close By Admin': 'Yönetici Tarafından Kapatıldı',
'All tickets': 'Tüm biletler',
'Ticket Title': 'Bilet Başlığı',
'Pending tickets': 'Bekleyen biletler',
'Total messages': 'Toplam Mesajlar',
'Open tickets ': 'Açık biletler',
'Please input dollar limit amount!': 'Lütfen dolar sınırlama tutarı girin!',
'Please input rial limit amount!': 'Lütfen riyal sınırlama tutarı girin!',
'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.':
"Etkinleştirmek için önce size etkinleştirme kodu içeren bir metin mesajı göndermek için SMS gönder düğmesine tıklamanız ve gad'ı alana eklemeniz ve etkinleştir düğmesine tıklamanız gerekmektedir.",
'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.':
'Etkinleştirmek için önce size e-postanıza bir kod göndermek için e-posta gönder düğmesine tıklamanız ve kodu alanına eklemeniz ve etkinleştir düğmesine tıklamanız gerekmektedir. (Spam / Junk) klasöründe bir e-posta almış olabilirsiniz.',
'To activate this feature, follow these steps:': 'Bu özelliği etkinleştirmek için aşağıdaki adımları izleyin:',
'2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program.':
'2. Google Authenticator programını aşağıdaki yöntemlerden biriyle kurduktan ve çalıştırdıktan sonra, anahtarı programa ekleyin.',
'- Enter a provided key: Select this option and enter the following code carefully.':
'- Sağlanan bir anahtar girin: Bu seçeneği seçin ve aşağıdaki kodu dikkatlice girin.',
'3. Enter the received code (6-digit number) in the box below and click the activate button.':
'3. Alınan kodu (6 haneli numara) aşağıdaki kutuya girin ve etkinleştir düğmesine tıklayın.',
'1. Get the latest version of Google Authenticator from Google Play or Apple Store':
"1. Google Play veya Apple Store'dan Google Authenticator'ın en son sürümünü edinin",
'- Scan a barcode: Select this option and scan the following barcode.':
'- Bir barkodu tarayın: Bu seçeneği seçin ve aşağıdaki barkodu tarayın.',
'WalletID': 'Cüzdan Kimliği',
'All Networks Status': 'Tüm Ağ Durumu',
'Networks Status': 'Ağ Durumu',
'UnVerified': 'Doğrulanmamış',
'User level settings can be changed from this section, and please change the settings of this section carefully.':
'Kullanıcı düzeyi ayarları bu bölümden değiştirilebilir ve lütfen bu bölümün ayarlarını dikkatlice değiştirin.',
'Please input withdrawal of cryptocurrency!': 'Lütfen kripto para çekme miktarını girin!',
'Please input withdrawal of fiat!': 'Lütfen fiat çekme miktarını girin!',
'Please input buying digital money (equivalent to dollar)!':
'Lütfen dijital para alım miktarını (dolar karşılığı) girin!',
'Please input buying digital money (equivalent to rial)!':
'Lütfen dijital para alım miktarını (riyal karşılığı) girin!',
'Please input fees for buy cryptocurrency!': 'Lütfen kripto para satın alma ücretlerini girin!',
'Please input fees for sell cryptocurrency!': 'Lütfen kripto para satma ücretlerini girin!',
'Please input trade maker fee!': 'Lütfen ticaret yapma ücretini girin!',
'Please input futures maker fee!': 'Lütfen vadeli işlem yapma ücretini girin!',
'Please input trade taker fee!': 'Lütfen ticaret yapma ücretini girin!',
'Please input futures taker fee!': 'Lütfen vadeli işlem yapma ücretini girin!',
'Please input internal transaction fee!': 'Lütfen iç işlem ücretini girin!',
'Please input OTC minimum buy!': 'Lütfen OTC minimum alım miktarını girin!',
'Cryptocurrency & Fiat Withdrawal': 'Kripto Para ve Fiat Çekme',
'Tether Fee': 'Tether Ücreti',
'Calculation of percentage in the price for buy': 'Satın alma fiyatındaki yüzde hesaplama',
'Calculation of the percentage in the price for sale': 'Satış fiyatındaki yüzde hesaplama',
'Futures Fee': 'Vadeli İşlem Ücreti',
'Futures Fee (Coin-M Futures)': 'Vadeli İşlem Ücreti (Coin-M Futures)',
'Futures Fee (USDⓈ-M Futures)': 'Vadeli İşlem Ücreti (USDⓈ-M Futures)',
'Trade Fee': 'Ticaret Ücreti',
'Internal Transaction Fee': 'İç İşlem Ücreti',
'OTC Minimum Buy': 'OTC Minimum Alım',
'DISAPPROVAL': 'ONAYLANMADI',
'Advanced rejected': 'Gelişmiş reddedildi',
'Are you sure to remove this user from users?': 'Bu kullanıcıyı kullanıcılardan kaldırmak istediğinizden emin misiniz?',
'Are you sure to remove this ticket?': 'Bu bileti kaldırmak istediğinizden emin misiniz?',
'Please input your mobile number!': 'Lütfen mobil numaranızı girin!',
'Please select your birth date': 'Lütfen doğum tarihinizi seçin!',
'Please select one!': 'Lütfen birini seçin!',
'Please input your email address!': 'Lütfen e-posta adresinizi girin!',
'Please input your photo!': 'Lütfen fotoğrafınızı yükleyin!',
'Fiat type': 'Fiat Türü',
'Fiat Status': 'Fiat Durumu',
'Withdraw Status': 'Çekim Durumu',
'Awaiting Approval': 'Onay Bekliyor',
'Cancelled': 'İptal Edildi',
'Email Sent': 'E-posta Gönderildi',
'Processing': 'İşleniyor',
'Rejected': 'Reddedildi',
'Transaction ID': 'İşlem Kimliği',
'Deposit Status': 'Depozito Durumu',
'Deposit Id': 'Depozito Kimliği',
'Create Date': 'Oluşturulma Tarihi',
'Transaction Type': 'İşlem Türü',
'Transaction Id': 'İşlem Kimliği',
'Sheba': 'Sheba',
'Deposit type': 'Depozito Türü',
'Created Date': 'Oluşturulma Tarihi',
'Expired': 'Süresi Dolmuş',
'Partially Filled': 'Kısmen Doldurulmuş',
'Pending Cancel': 'İptal Bekliyor',
'Limit': 'Sınırlı',
'Stop Limit': 'Durdur Limiti',
'Filled': 'Doldurulmuş',
'Transaction Date': 'İşlem Tarihi',
'Stop Price': 'Durdur Fiyatı',
'Stop limit': 'Durdur limiti',
'Minimum limit': 'Minimum Limit',
'Stop price': 'Durdur fiyatı',
'Are you sure to remove this admin account?': 'Bu yönetici hesabını kaldırmak istediğinizden emin misiniz?',
'Dynamic profit percentage': 'Dinamik kar yüzdesi',
'User Share': 'Kullanıcı Payı',
'Iran': 'İran',
'Armenia': 'Ermenistan',

'can see the list of commissions received by this user through introduction.':
'bu kullanıcının tanıtım yoluyla aldığı komisyon listesini görebilir.',
'Crypto Deposit': 'Kripto Para Yatırma',
'Monthly': 'Aylık',
'Country / region': 'Ülke / bölge',
'Use a valid government-issued document': 'Geçerli bir devlet tarafından verilen belge kullanın',
'place documents against a single-colored background': 'Belgeleri tek renkli bir arka plana yerleştirin',
'Readable, well-lit, colored images': 'Okunaklı, iyi aydınlatılmış, renkli görüntüler',
'Total Trade': 'Toplam Ticaret',
// Fiat
'Account': 'Hesap',
'Tracking code': 'Takip kodu',
'This transaction is in progress, and after review, you can either confirm or reject it.':
'Bu işlem devam ediyor ve inceleme sonrası onaylayabilir veya reddedebilirsiniz.',
'Are you sure to deny this transaction?': 'Bu işlemi reddetmek istediğinizden emin misiniz?',
'Are you sure to accept this transaction?': 'Bu işlemi kabul etmek istediğinizden emin misiniz?',
// Missing Translations
'List of withdrawals': 'Çekim Listesi',
'There is no record for this table': 'Bu tablo için kayıt bulunmuyor',
'Recurring Buy': 'Tekrarlayan Alış',
'In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.':
'Hesabınızın güvenlik seviyesini artırmak için iki adımlı girişi etkinleştirmenizi öneririz. Google, e-posta ve SMS üzerinden iki adımlı girişi içeren aşağıdaki iki adımlı girişi etkinleştirebilirsiniz ve en güvenli yöntem Google üzerinden iki adımlı giriştir, ancak her birini etkinleştirmenizi kolaylaştırmak için üç türü de düşünmeniz gerekiyor.',
'Are you sure to remove this order?': 'Bu siparişi kaldırmak istediğinizden emin misiniz?',
'I want to pay': 'Ödemek istiyorum',
'I want to sell': 'Satmak istiyorum',
'I will receive': 'Alacağım',
'Deposit account number': 'Yatırım hesap numarası',
'The total number of users': 'Toplam kullanıcı sayısı',
'Total balance(Fiat)': 'Toplam bakiye(Fiat)',
'Total number of purchases': 'Toplam satın alma sayısı',
'Total sales': 'Toplam satış',
'Total sales amount': 'Toplam satış tutarı',
'number of orders': 'Sipariş sayısı',
'total order amount': 'Toplam sipariş tutarı',
'Transaction': 'İşlem',
'Referral Users': 'Yönlendirme Kullanıcıları',
'Existing users': 'Mevcut kullanıcılar',
'Active users': 'Aktif kullanıcılar',
'All tickets': 'Tüm biletler',
'Opened Tickets': 'Açılan Biletler',
'Change': 'Değiştir',
'Price': 'Fiyat',
'Pair': 'Çift',
'Market Trades': 'Piyasa İşlemleri',
'Time Amount(MATIC) price(USDT)': 'Zaman Miktarı(MATIC) fiyat(USDT)',
'Market Activities': 'Piyasa Aktiviteleri',
'XMRBUSD': 'XMRBUSD',
'MANAUSDT': 'MANAUSDT',
'SLAPETH': 'SLAPETH',
'ONGBTS': 'ONGBTS',
'MATIC/USDT': 'MATIC/USDT',
'Binance': 'Binance',
'Volume USDT': 'Hacim USDT',
'Volume MATIC': 'Hacim MATIC',
'low': 'Düşük',
'Hight': 'Yüksek',
'Change': 'Değişiklik',
'Polygon': 'Poligon',
'STOP_LIMIT': 'DURDUR LİMİT',
'MARKET': 'PAZAR',
'LIMIT': 'SINIR',
'Total': 'Toplam',
'Amount(MATIC)': 'Miktar(MATIC)',
'Price(USDT)': 'Fiyat(USDT)',
'Recurring Buy': 'Tekrarlayan Alış',
'Rol 0.00% got .years for USDT Recurring Buy': 'USDT Tekrarlayan Alış için 0.00% Rol .yıl',
'Oder Book': 'Sipariş Kitabı',
'Sum(BTC)': 'Toplam(BTC)',
'Size(BTC)': 'Boyut(BTC)',
'Trades': 'İşlemler',
'BTC/USDT': 'BTC/USDT',
'Sep': 'Eyl',
'Oct': 'Eki',
'Nov': 'Kas',
'3y': '3 yıl',
'50y': '50 yıl',
'Trade History': 'İşlem Geçmişi',
'Open Trades': 'Açık İşlemler',
'Position': 'Pozisyon',
'Margin Ratio': 'Marj Oranı',
'Liq. Price': 'Liq. Fiyat',
'Entry Price': 'Giriş Fiyatı',
'Size': 'Boyut',
'BTCUSDT': 'BTCUSDT',
'Layer1': 'Katman 1',
'Layer2': 'Katman 2',
'Fav': 'Favori',
'Number of referrals': 'Yönlendirme Sayısı',
'Number of transactions': 'İşlem Sayısı',
'your total income is based on USD': 'Toplam geliriniz USD' ,
'Updated At': 'Güncelleme Tarihi',
'Create At': 'Oluşturma Tarihi',
'Deleted': 'Silindi',
'Close by admin': 'Yönetici tarafından kapatıldı',
'Oder ID': 'Sipariş Kimliği',
'Paysafe History': 'Paysafe Geçmişi',
'Perfect Money History': 'Perfect Money Geçmişi',
'Oder ID': 'Sipariş Kimliği',
'Tx Code': 'Tx Kodu',
'sum': 'Toplam',
'BUY': 'AL',
'Unit price': 'Birim Fiyat',
'in order to increase the security leveled of yor account. we recommend that you enable your two-step login. you can enable the following two-step login. which includes two-step login via Google. email and SMS,and the safest method is two-step login via Google.but for you to consider three types we ot to make it easier for you to activate with each one.':'Hesabınızın güvenliğini artırmak için iki aşamalı girişi etkinleştirmenizi öneriyoruz. Aşağıdaki iki aşamalı girişi etkinleştirebilirsiniz. Bu, Google, e-posta ve SMS ile iki aşamalı girişi içerir ve en güvenli yöntem Google ile iki aşamalı giriştir, ancak her birini düşünmenizi kolaylaştırmak için üç türü de göz önünde bulundurmanızı öneririz.',
'Two-step login via Google': 'Google üzerinden iki aşamalı giriş',
'Two-step login via Sms': 'Sms ile iki aşamalı giriş',
'Two-step login via Email': 'E-posta ile iki aşamalı giriş',
'Withdrawal of cryptocurrency(equivalent to Dollar)': 'Kripto para çekme (Dolara eşdeğer)',
'Withdrawal of Fiat(equivalent to Dollar)': 'Fiat para çekme (Dolara eşdeğer)',
'Buying digital money(equivalent to Dollar)': 'Dijital para satın alma (Dolara eşdeğer)',
'Buying digital money(equivalent to Rial)': 'Dijital para satın alma (Riyal eşdeğerinde)',
'Fees for buy Tether': 'Tether satın alma ücretleri',
'Fees for Sell Tether': 'Tether satış ücretleri',
'Future Fee(Marker)': 'Gelecekteki Ücret (Marker)',
'Future Fee(Taker)': 'Gelecekteki Ücret (Taker)',
'Trade Fee(Marker)': 'İşlem Ücreti (Marker)',
'Trade Fee(Taker)': 'İşlem Ücreti (Taker)',
'Fees for internal transaction': 'Dahili işlem ücretleri',
'Min internal transaction': 'Minimum dahili işlem',
'Max internal transaction': 'Maksimum dahili işlem',
'Max leverage': 'Maksimum kaldıraç',
'Max Margin': 'Maksimum Marj',
'Card Number': 'Kart Numarası',
'Month Of date': 'Tarih Ayı',
'Year of date': 'Tarih Yılı',
'Paysafe Price': 'Paysafe Fiyatı',
'Deposit Type': 'Depozito Türü',
'Voucher Currency': 'Kupon Para Birimi',
'First Name': 'Ad',
'Last Name': 'Soyadı',
'Old Password': 'Eski Şifre',
'New Password': 'Yeni Şifre',
'Confirm Password': 'Şifreyi Onayla',
'Add Mobile': 'Mobil Ekle',
'There is no record for this table': 'Bu tablo için kayıt bulunmuyor',
'Paysafe Price': 'Paysafe Fiyatı',
'Deposit Type': 'Depozito Türü',
'Voucher Currency': 'Kupon Para Birimi',
'Fiat Type': 'Fiat Türü',
'Perfect Money Price': 'Perfect Money Fiyatı',
'TxID': 'Tx Kimliği',
'Origin': 'Menşe',
'Entry Price': 'Giriş Fiyatı',
'Leverage': 'Kaldıraç',
'Margin Ratio': 'Marj Oranı',
'Value currency': 'Değer Para Birimi',
'Address': 'Adres',
'Recurring Buy': 'Yinelenen Satın Alma',
'Long': 'Uzun',
'Short': 'Kısa',
'Cost': 'Maliyet',
'Max': 'Maksimum',
'Introduction link': 'Tanıtım Bağlantısı',
'Introduction Code': 'Tanıtım Kodu',
'Number of referrals': 'Yönlendirme Sayısı',
'Number of transactions': 'İşlem Sayısı',
'Your total income is based on USD': 'Toplam geliriniz USD üzerinden hesaplanmıştır',
'Verify Now': 'Şimdi Doğrula',
'After Basic': 'Temel Sonrası',
'After Intermediate': 'Orta Düzey Sonrası',
'Fiat': 'Fiat',
"You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.": "Henüz herhangi bir etkinliğiniz yok. Cryptizi'de ticarete başladıktan sonra burada canlı bir grafik gösterilecektir.",
'Save Change': 'Değişiklikleri Kaydet',
'Setting Saved Successfully.': 'Ayarlar Başarıyla Kaydedildi.',
'Default setting restored successfully.': 'Varsayılan ayarlar başarıyla geri yüklendi.',
'Please enter Spend amount!': 'Lütfen Harcama miktarını girin!',
'added to favorites successfully.': 'Başarıyla favorilere eklendi.',
'Bad Request': 'Kötü İstek',
'Enter the amount!': 'Miktarı girin!',
'Please enter total amount!': 'Lütfen toplam tutarı girin!',
'Internal Server Error': 'Dahili Sunucu Hatası',
'Insufficient funds': 'Yetersiz bakiye',
'Please select a coin!': 'Lütfen bir coin seçin!',
'Please fill in all fields': 'Lütfen tüm alanları doldurun',
'Share percentage changed successfully.': 'Pay yüzdesi başarıyla değiştirildi.',
'deleted from your favorites': 'favorilerinizden silindi',
'Invalid request': 'Geçersiz istek',
'Invalid symbol USDTUSDT': 'Geçersiz sembol USDTUSDT',
'Card ExpiryDate Month is invalid': 'Kart Son Kullanma Tarihi Ayı geçersiz',
'card is expired': 'kart süresi dolmuş',
'Month of date': 'Tarih Ayı',
'Account Number': 'Hesap Numarası',
'Card ExpiryDate Month is invalid': 'Kart Son Kullanma Tarihi Ayı geçersiz',
"To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.": "Bu özelliği etkinleştirmek için şu adımları izleyin:\n 1. Google Play veya Apple Store'dan Google Authenticator'ın en son sürümünü alın.\n 2. Google Authenticator programını aşağıdaki yöntemlerden biriyle kurduktan ve çalıştırdıktan sonra, anahtarı programa ekleyin. - Bir barkodu tarayın: Bu seçeneği seçin ve aşağıdaki barkodu tarayın.",
'- Enter a provided key: Select this option and enter the following code carefully.': '- Sağlanan bir anahtar girin: Bu seçeneği seçin ve aşağıdaki kodu dikkatlice girin.',
'3. Enter the received code (6-digit number) in the box below and click the activate button.': '3. Aşağıdaki kutuya alınan kodu (6 haneli numara) girin ve etkinleştir düğmesine tıklayın.',
'Google Code': 'Google Kodu',
'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.': "Etkinleştirmek için önce size etkinleştirme kodunu içeren bir metin mesajı göndermek için SMS gönder düğmesine tıklamanız gerekmektedir ve gad'ı alana yerleştirip etkinleştir düğmesine tıklamanız gerekmektedir. Bu kodu almak için SMS düğmesine tıklamanız gerekecektir.",
'Send SMS': 'SMS Gönder',
'Email Code': 'E-posta Kodu',
'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.': 'Etkinleştirmek için önce size e-postanıza bir kod göndermek için e-posta gönder düğmesine tıklamanız ve kodu alana yerleştirip etkinleştir düğmesine tıklamanız gerekmektedir. E-postanızı (Spam / Junk) klasöründe almış olabilirsiniz.',
'Send Email': 'E-posta Gönder',
'In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We got to make it easier for you to activate with each one.': 'Hesabınızın güvenliğini artırmak için iki aşamalı girişi etkinleştirmenizi öneriyoruz. Aşağıdaki iki aşamalı girişi etkinleştirebilirsiniz. Bu, Google, e-posta ve SMS ile iki aşamalı girişi içerir ve en güvenli yöntem Google ile iki aşamalı giriştir, ancak her birini düşünmenizi kolaylaştırmak için üç türü de göz önünde bulundurmanızı öneririz.',
'Selecting higher leverage such as [10x] increases your liquidation risk. Always manage your risk levels.': 'Daha yüksek kaldıraç seçmek (örneğin [10x]) likidasyon riskinizi artırır. Her zaman risk seviyelerinizi yönetin.',
'Maximum position: 15,000,000 USDT': 'Maksimum pozisyon: 15,000,000 USDT',
'Adjust Leverage': 'Kaldıracı Ayarla',
'Leverage': 'Kaldıraç',
'Select Symbol': 'Sembol Seç',
'See what the potential risk and reward will be in monetary terms on any given trade. Use our Futures Calculator to establish your potential profit/loss on a future trade. Read tips on how to use.':
'Herhangi bir ticarette para birimi cinsinden potansiyel risk ve ödülün ne olabileceğini görün. Gelecekteki bir işlemde potansiyel kar/zararınızı belirlemek için Futures Hesaplayıcımızı kullanın. Nasıl kullanılacağına dair ipuçlarını okuyun.',
'Liquidation Price': 'Likidasyon Fiyatı',
'Target Price': 'Hedef Fiyat',
'Initial Margin': 'Başlangıç Marjı',
'Shot': 'Satış',
'Long': 'Alış',
'Entry Price': 'Giriş Fiyatı',
'Quantity': 'Miktar',
'Calculate': 'Hesapla',
'Result': 'Sonuç',
'Maximum position': 'Maksimum pozisyon',
'Introduction code': 'Tanıtım Kodu',
'Introduction link': 'Tanıtım Bağlantısı',
'No Data': 'Veri Yok',
'Total': 'Toplam',
'Number of referrals': 'Yönlendirme Sayısı',
'Number of transactions': 'İşlem Sayısı',
'Your total income is based on USD': 'Toplam geliriniz USD üzerinden hesaplanmıştır',
'introduction code': 'tanıtım kodu',
'introduction link': 'tanıtım bağlantısı',
      //***********************************************************************//
'The total number of users': 'Toplam kullanıcı sayısı',
'Total number of orders': 'Toplam sipariş sayısı',
'Total sales amount': 'Toplam satış tutarı',
'Total number of purchases': 'Toplam satın alma sayısı',
'Total balance ( Fiat )': 'Toplam bakiye (Fiat)',
'Total order amount': 'Toplam sipariş tutarı',
'Total sales': 'Toplam satışlar',
'Total amount of purchases': 'Toplam satın alma tutarı',
'Opened Tickets': 'Açık biletler',
'Existing users': 'Mevcut kullanıcılar',
'All tickets': 'Tüm biletler',
'Referral Users': 'Yönlendirme kullanıcıları',
'Active users': 'Aktif kullanıcılar',
'Transaction': 'İşlem',
'Purchases amount': 'Satın alma tutarı',
'Sales amount': 'Satış tutarı',
'Creat At': 'Oluşturulma Tarihi',
'Updated At': 'Güncelleme Tarihi',
'Order': 'Sipariş',
'Enter Order ID': 'Sipariş Kimliği Girin',
'Order ID': 'Sipariş Kimliği',
'First Name': 'Adı',
'Last Name': 'Soyadı',
'Open Trades': 'Açık İşlemler',
'Tx-Code': 'Tx Kodu',
'Enter Tx-code': 'Tx Kodu Girin',
'Tx Code': 'Tx Kodu',
'Paysafe Price': 'Paysafe Fiyatı',
'Deposit Type': 'Yatırım Türü',
'Voucher Currency': 'Kupon Para Birimi',
'Paysafe History': 'Paysafe Geçmişi',
'Perfect Money History': 'Mükemmel Para Geçmişi',
'Fiat Amount': 'Fiat Tutarı',
'Perfect Money Price': 'Mükemmel Para Fiyatı',
'There is no record for this table': 'Bu tablo için kayıt bulunmuyor',
'Origin': 'Köken',
'TxID': 'Tx Kimliği',
'Entry Price': 'Giriş Fiyatı',
'Leverage': 'Kaldıraç',
'Margin Ratio': 'Marj Oranı',
'User id': 'Kullanıcı Kimliği',
'Fiat withdraw transaction waiting': 'Fiat çekme işlemi bekleniyor',
'You can see the list of fiat transactions whose information must be confirmed.': 'Bilgileri onaylanması gereken fiat işlemlerinin listesini görebilirsiniz.',
'Fiat Type': 'Fiat Türü',
'Account': 'Hesap',
'Actions': 'Eylemler',
'Fiat deposit transaction waiting': 'Fiat yatırma işlemi bekleniyor',
'You can see the list of fiat transactions whose information must be confirmed.': 'Bilgileri onaylanması gereken fiat işlemlerinin listesini görebilirsiniz.',
'Receipt': 'Fiş',
'Token': 'Jeton',
'Getway': 'Geçit',
'Percentage in price for sale': 'Satış fiyatındaki yüzde',
'Purchase price in dollars': 'Dolar cinsinden satın alma fiyatı',
'Percentage on the price to buy': 'Satın alma fiyatında yüzde',
'Percentage in the price for ( marketing )': 'Pazarlama için fiyatta yüzde',
'Selling price in dollars': 'Dolar cinsinden satış fiyatı',
'Percentage in price for sale': 'Satış fiyatındaki yüzde',
'Percentage in the price for ( marketing )': 'Pazarlama için fiyatta yüzde',
'Percentage in the price for purchase': 'Satın alma fiyatındaki yüzde',
'Percentage in price for sale': 'Satış fiyatındaki yüzde',
'Inventory': 'Envanter',
'Active': 'Aktif',
'Minimum allowed purchase': 'Minimum izin verilen satın alma',
"Only Iranian IPs are allowed": "Yalnızca İran IP'leri izin verilir",
'Deactivating purchase from site': 'Siteden satın alma devre dışı bırakılıyor',
'IP Block': 'IP Engellemesi',
'Num': 'Numara',
'Delete': 'Sil',
'Withdrawal of cryptocurrency (equivalent to Dollar)': 'Kripto para çekimi (Dolar karşılığı)',
'Buying digital money (equivalent to Dollar)': 'Dijital para satın alma (Dolar karşılığı)',
'Fees for buy Tether': 'Tether satın alma ücretleri',
'Marker': 'İşareti',
'Taker': 'Alıcı',
'Fees for internal transaction': 'Dahili işlem ücretleri',
'Min internal transaction': 'Minimum dahili işlem',
'Internal Transaction': 'Dahili İşlem',
'Internal Transaction': 'Dahili İşlem',
'Max Leverage': 'Maksimum Kaldıraç',
'Withdrawal of Fiat (equivalent to Dollar)': 'Fiat çekimi (Dolar karşılığı)',
'Buying digital money (equivalent to Rial)': 'Dijital para satın alma (Rial karşılığı)',
'Fees for Sell Tether': 'Tether satış ücretleri',
'OTC Minimum Buy': 'OTC Minimum Satın Alma',
'Max internal transaction': 'Maksimum dahili işlem',
'Max Margin': 'Maksimum Marj',
'Futures settings': 'Vadeli İşlem Ayarları',
'Disabled': 'Devre Dışı',
'Enabled': 'Etkin',
'Max Borrow': 'Maksimum Borç',
'Add More Coins': 'Daha Fazla Coin Ekle',
'List of bank accounts': 'Banka hesapları listesi',
'Bank name': 'Banka adı',
'Card number': 'Kart numarası',
'Account number': 'Hesap numarası',
'Shaba number': 'Shaba numarası',
'Add New': 'Yeni Ekle',
"Transfer methods": "Transfer yöntemleri",
"Name": "Ad",
"Card to Card": "Karttan Karta",
"Bank deposit": "Banka yatırımı",
"Remember me": "Beni hatırla",
"Web Advertisement Setting": "Web Reklam Ayarı",
"Show how many days": "Kaç gün göster",
"Advanced editor": "Gelişmiş düzenleyici",
"Mobile Advertisement Setting": "Mobil Reklam Ayarı",
"Write Something awesome...": "Harika bir şeyler yaz...",
"Zibal": "Zibal",
"Vander": "Vander",
"Default": "Varsayılan",
"Total users": "Toplam kullanıcılar",
"Verified users": "Doğrulanmış kullanıcılar",
"Users are waiting": "Kullanıcılar bekliyor",
"Rejected users": "Reddedilen kullanıcılar",
"User Id": "Kullanıcı Kimliği",
"Mobile": "Mobil",
"Tell": "Söyle",
"Select or Drag photo": "Fotoğraf Seçin veya Sürükleyin",
"Introduced user": "Tanıtılan Kullanıcı",
"Total orders": "Toplam Siparişler",
"Total commission orders": "Toplam Komisyon Siparişleri",
"Average commission per order": "Sipariş Başına Ortalama Komisyon",
"There is no record for this table": "Bu tablo için kayıt bulunmuyor",
"Fiat Wallet Transaction": "Fiat Cüzdan İşlemi",
"Notification Tite": "Bildirim Başlığı",
"Color background": "Renk Arka Plan",
"Normal(black)": "Normal (siyah)",
"Users": "Kullanıcılar",
"Active days": "Aktif Günler",
"Headers": "Başlıklar",
"Notification /Email /SMS": "Bildirim / E-posta / SMS",
"Types": "Türler",
"Recipient": "Alıcı",
"Color": "Renk",
"Active Days": "Aktif Günler",
"Total Trade": "Toplam İşlem",
"Russia": "Rusya",
"United Arab Emirates": "Birleşik Arap Emirlikleri",
"Saudi Arabia": "Suudi Arabistan",
"User Statistics": "Kullanıcı İstatistikleri",
"Old Password": "Eski Şifre",
"New Password": "Yeni Şifre",
"Confirm Password": "Şifreyi Onayla",
"Hesabınızın güvenlik seviyesini artırmak için iki aşamalı girişi etkinleştirmenizi öneririz. Aşağıdaki iki aşamalı girişi etkinleştirebilirsiniz, bu da Google, e-posta ve SMS ile iki aşamalı girişi içerir ve en güvenli yöntem Google üzerinden iki aşamalı giriş yapmaktır. Ancak her birini etkinleştirmenizi kolaylaştırmak için üç türü de düşünmeniz için hazırladık.": "Bu özelliği etkinleştirmek için şu adımları izleyin: 1. Google Play veya Apple Store'dan Google Authenticator'ün en son sürümünü edinin. 2. Google Authenticator programını aşağıdaki yöntemlerden biriyle kurup çalıştırdıktan sonra anahtarı programa ekleyin. - Barkodu Tara: Bu seçeneği seçin ve aşağıdaki barkodu tarayın.",
"- Sağlanan anahtarı girin: Bu seçeneği seçin ve aşağıdaki kodu dikkatlice girin.": "3. Aşağıdaki kutuya alınan kodu (6 haneli numara) girin ve etkinleştir düğmesine tıklayın.",
"Google Code": "Google Kodu",
"New Email": "Yeni E-posta",
"Add Mobile": "Mobil Ekleyin",
"Internal Server Error": "İç Sunucu Hatası",
"Only normal user allowed": "Sadece normal kullanıcı izinlidir",
"Please input your old password!": "Lütfen eski şifrenizi girin!",
"Passwords must have at least 8 characters and contain at least one uppercase letter, lowercase letter, number, and symbol.": "Şifreler en az 8 karakter içermeli ve en az bir büyük harf, küçük harf, rakam ve sembol içermelidir.",
"Please input your confirm password!": "Lütfen şifrenizi onaylayın!",
"Invalid soft otp": "Geçersiz soft otp",
"Session not found": "Oturum bulunamadı",
"Send SMS": "SMS Gönder",
"send SMS otp need phone number!": "SMS otp gönderimi için telefon numarası gerekiyor!",
"User Id": "Kullanıcı Kimliği",
"Final amount": "Son Miktar",
"Fiat Type": "Fiat Türü",
"Waller ID": "Cüzdan Kimliği",
"Pay Safe Voucher": "Pay Safe Kupon",
"Pay Safe": "Pay Safe",
"Black": "Siyah",
"Red": "Kırmızı",
"Green": "Yeşil",
"Advanced level": "Gelişmiş Seviye",
"Fixed alert": "Sabit Uyarı",
"Notification Fixed alert": "Bildirim Sabit Uyarı",
"Test": "Test",
"Fixed alert test": "Sabit Uyarı Testi",
"Fixed alert test 2": "Sabit Uyarı Testi 2",
"Welcome back": "Hoş geldiniz",
"Please active your 2FA Authenticator": "Lütfen 2FA Authenticator'ünüzü etkinleştirin",
"Success (green)": "Başarılı (yeşil)",
"Danger (red)": "Tehlike (kırmızı)",
"Warning (yellow)": "Uyarı (sarı)",
"Order Book": "Emir Defteri",
"Futures Fee(Marker)": "Futures Ücreti (Marker)",
"Futures Fee(Taker)": "Futures Ücreti (Taker)",
"Trade Fee(Marker)": "Trade Ücreti (Marker)",
"Trade Fee(Traker)": "Trade Ücreti (Traker)",
        'Popup link': 'Açılır bağlantı',
        'Please choose an image': 'Lütfen bir resim seçin',
        Tokens: 'Jetonlar',
        'Add token': 'Jeton ekle',
        'Main wallet setting': 'Ana cüzdan ayarı',
        'Network setting': 'Ağ ayarları',
        "Listing":'Listeleme',
        "Cryptizi offers a powerful platform to take advantage of a broad user base and solid infrastructure, improving your token's market visibility and accessibility." : "Cryptizi, geniş bir kullanıcı tabanından ve sağlam altyapıdan yararlanmak için güçlü bir platform sunarak tokenınızın pazar görünürlüğünü ve erişilebilirliğini artırır.",
        "Application Process" :'Başvuru süreci',
        "Listing Application" : 'Listeleme Başvurusu',
        "To apply for listing, simply click on 'Listing Application'. Our business manager will promptly get in touch with you.": "Listelemeye başvurmak için 'Listeleme Başvurusu'na tıklamanız yeterlidir. İşletme yöneticimiz sizinle hemen iletişime geçecektir.",
        "For any queries, reach out to us at listing@cryptizi.com. Thank you!":"Sorularınız için listing@cryptizi.com adresinden bize ulaşın. Teşekkür ederim!" ,
        "Ticket Number" : 'Bilet numarası',
        "Created At" : 'Oluşturulma Tarihi',
        "Dedicated Wallet Settings" : 'Özel Cüzdan Ayarı'

    },
};
  
  export default tr;
  