import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Media from 'react-media';
import { useTheme } from '@mui/material';
import { FiSearch } from 'react-icons/fi';
import { Row, Col, Tabs, Segmented, Input, Spin, AutoComplete } from 'antd';

import BuySell from './buySell/buySell';
import PairTable from './pairTable/pairTable';
import MarketTradeTable from './marketTradeTable/marketTradeTable';
import MarketActivitiesTable from './marketActivitiesTable/marketActivitiesTable';
import OpenOrdersTable from './tabTables/openOrdersTable/openOrdersTable';
import OrderHistoryTable from './tabTables/orderHistoryTable/orderHistoryTable';
import TradingViewChart from '../../../components/tardingViewChart/tardingViewChart';
import Api from '../../../http/serviceApi';
import CustomListBoxContainer from './customListBoxContainer/customListBoxContainer';
import usePrevious from '../../../hooks/usePrevious';
import { screenSize } from '../../../common/screenSize';
import PairFavoriteScroll from './pairTable/pairFavoriteScroll';

import iconListRed from '../../../assets/trade/listRed.svg';
import iconListGreen from '../../../assets/trade/listGreen.svg';
import iconListRedGreen from '../../../assets/trade/listRedGreen.svg';
import iconListRedLight from '../../../assets/trade/listRedLight.svg';
import iconListGreenLight from '../../../assets/trade/listGreenLight.svg';
import iconListRedGreenLight from '../../../assets/trade/listRedGreenLight.svg';

import { SET_PAGE_STATE } from '../../../store/actionTypes';

import classesLight from './trade-light.module.scss';
import classesDark from './trade.module.scss';

import './trade.scss';
import 'antd/dist/antd.min.css';
import 'antd/dist/antd.dark.css';

const { TabPane } = Tabs;

const tabKey = {
  OpenOrders: 'openOrders',
  OrderHistory: 'orderHistory',
  Funds: 'funds',
};

const Trade = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SET_PAGE_STATE,
      payload: { title: 'Trade' },
    });
  }, []);

  const classes = theme.palette.mode === 'dark' ? classesDark : classesLight;

  const [topInfos, setTopInfos] = useState({
    baseName: '-',
    baseAsset: '-',
    quoteAsset: '-',
    highPrice: '-',
    lastPrice: 0,
    lowPrice: '-',
    priceChange: '',
    priceChangePercent: 0,
    quoteVolume: '-',
    symbol: '-',
    volume: '-',
  });
  const [pairCoinList, setPairCoinList] = useState({
    mainList: [],
    tempList: [],
  });
  const [loading_getAllSymbolInfo, setLoading_getAllSymbolInfo] = useState(true);
  const [activeKey, setActiveKey] = useState(tabKey.Orders);
  const [orderBookListFilter, setOrderBookListFilter] = useState('both');
  const [selectedSymbol, setSelectedSymbol] = useState({
    symbol: 'BTCUSDT',
    baseAsset: 'BTC',
    quoteAsset: 'USDT',
  });
  const funcLoaded = useRef(false);
  const openTradeInfos = useSelector((store) => store.openTradeInfos);
  // const prevLastPrice = usePrevious(topInfos?.lastPrice);
  const location = useLocation();
  const orderBook = useSelector((store) => store.orderBook);
  const prevLastPrice = usePrevious(orderBook?.tradeInfos[0]?.p);

  useEffect(() => {
    getAllSymbolInfo();
    const interval = setInterval(fetchDataEveryOneMinute, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const selectedTradeSymbol = window.localStorage.getItem('selectedTradeSymbol');

    console.log('passedFromWallet: ', location?.state?.passedFromWallet);
    console.log('selectedTradeSymbol: ', JSON.parse(selectedTradeSymbol));

    if (location?.state?.passedFromWallet) {
      setSelectedSymbol(location?.state?.passedFromWallet);
      window.localStorage.setItem('selectedTradeSymbol', JSON.stringify(location?.state?.passedFromWallet));
    }
    // TODO: From local storage
    else if (selectedTradeSymbol) {
      setSelectedSymbol(JSON.parse(selectedTradeSymbol));
    }
  }, [location?.state?.passedFromWallet]);

  const fetchDataEveryOneMinute = () => {
    getAllSymbolInfo();
    // getUserTradeInfo();
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const favoritList = [
    { id: 0, coin: 'BUSD' },
    { id: 1, coin: 'USDT' },
    { id: 2, coin: 'BNB' },
    { id: 3, coin: 'BTC' },
    { id: 4, coin: 'ETH' },
    { id: 5, coin: 'XRP' },
    { id: 6, coin: 'TRX' },
    { id: 7, coin: 'DOGE' },
    { id: 7, coin: 'DOT' },
  ];

  const getAllSymbolInfo = async () => {
    // setLoading_getAllSymbolInfo(true);
    const response = await Api.getAllSymbolInfo();

    if (response) {
      const dataList = Object.entries(response.symbolMap).map(([key, value]) => value);

      if (!funcLoaded.current) {
        const selectedTradeSymbol = window.localStorage.getItem('selectedTradeSymbol');

        if (location?.state?.passedFromWallet) {
          const foundSelected = dataList.find((coin) => coin.symbol === location?.state?.passedFromWallet?.symbol);
          setDefaultTopInfos(foundSelected);
        }
        // TODO: From local storage
        else if (selectedTradeSymbol) {
          const storageSymbol = JSON.parse(selectedTradeSymbol);
          const foundSelected = dataList.find((coin) => coin.symbol === storageSymbol?.symbol);
          setDefaultTopInfos(foundSelected);
        } else {
          setDefaultTopInfos(response.symbolMap?.BTCUSDT);
        }
      }

      const list = {
        mainList: dataList,
        tempList: dataList,
      };
      setPairCoinList(list);
      onHorizentalListItemClick(null, list);
    }
    setLoading_getAllSymbolInfo(false);
  };

  const setDefaultTopInfos = async (data) => {
    setTopInfos(data);
    funcLoaded.current = true;
  };

  const onRowPairTable = (record, rowIndex) => {
    return {
      onClick: (event) => {
        // TODO: Set to local storage
        window.localStorage.setItem(
          'selectedTradeSymbol',
          JSON.stringify({
            symbol: record.symbol,
            baseAsset: record.baseAsset,
            quoteAsset: record.quoteAsset,
          })
        );

        setSelectedSymbol(record);
        setTopInfos(record);
      },
    };
  };

  const onSearchInputChange = (symbolSearch) => {
    setPairCoinList({
      mainList: pairCoinList?.tempList?.filter((coin) => coin.symbol.includes(symbolSearch.toUpperCase())),
      tempList: [...pairCoinList?.tempList],
    });
  };

  const [userFavorites, setUserFavorites] = useState([]);
  const userFavoritesRef = useRef([]);
  const fetchFavorites = async () => {
    setLoading_getAllSymbolInfo(true);

    try {
      const response = await Api.getUserFavoriteSymbol();
      if (response?.status === 'SUCCESS') {
        setUserFavorites(response.userFavoriteSymbol);
        userFavoritesRef.current = response.userFavoriteSymbol;
      }
    } catch (e) {}

    setLoading_getAllSymbolInfo(false);
  };

  useEffect(() => {
    fetchFavorites();
  }, []);

  const horizentalListItemRef = useRef('');
  const onHorizentalListItemClick = (item = null, pairCoins = null) => {
    if (item !== null) {
      horizentalListItemRef.current = item;
    } else {
      item = horizentalListItemRef.current || '';
    }

    if (item) {
      if (item === 'favorite') {
        setPairCoinList({
          mainList: (pairCoins || pairCoinList)?.tempList?.filter((coin) =>
            userFavoritesRef.current.find((fSymbol) => fSymbol === coin.symbol)
          ),
          tempList: [...(pairCoins || pairCoinList)?.tempList],
        });
      } else {
        setPairCoinList({
          mainList: (pairCoins || pairCoinList)?.tempList?.filter((coin) =>
            coin.quoteAsset.includes(item.coin.toUpperCase())
          ),
          tempList: [...(pairCoins || pairCoinList)?.tempList],
        });
      }
    } else {
      setPairCoinList({
        mainList: (pairCoins || pairCoinList)?.tempList,
        tempList: [...(pairCoins || pairCoinList)?.tempList],
      });
    }
  };

  return (
    <div className={classes.container}>
      <Media
        query={screenSize.largerThan_1399}
        render={() => {
          return (
            <Row>
              <Col style={{ display: 'inline-block', width: 'calc(100% - 310px)' }}>
                <Row align='middle' style={{ height: '80px', borderBottom: '1px solid #34384C' }}>
                  {loading_getAllSymbolInfo && (
                    <div className={classes.topInfoLoading}>
                      <Spin />
                    </div>
                  )}
                  <div className={classes.topInfosDiv}>
                    <div>
                      <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        {topInfos?.baseAsset + '/' + topInfos?.quoteAsset}
                      </span>
                      <span>{topInfos?.baseName}</span>
                    </div>
                    <div className={classes.verticalLine}></div>
                    <div>
                      <span
                        style={{ fontSize: '21px' }}
                        className={
                          orderBook?.tradeInfos[0]?.p >= prevLastPrice ? classes.changeGreen : classes.changeRed
                        }
                      >
                        {orderBook?.tradeInfos[0]?.p ? orderBook?.tradeInfos[0]?.p : '-'}
                      </span>
                      <span>{'$' + (orderBook?.dollarPrice ? orderBook?.dollarPrice : '-')}</span>
                    </div>
                    <div>
                      <span>24h Change</span>
                      <span className={topInfos?.priceChange >= 0 ? classes.changeGreen : classes.changeRed}>
                        {topInfos?.priceChange + ' ' + topInfos?.priceChangePercent + '%'}
                      </span>
                    </div>
                    <div>
                      <span>24h High</span>
                      <span>{topInfos?.highPrice}</span>
                    </div>
                    <div>
                      <span>24h low</span>
                      <span>{topInfos?.lowPrice}</span>
                    </div>
                    <div>
                      <span>24h Volume {topInfos?.baseAsset}</span>
                      <span>{topInfos?.volume}</span>
                    </div>
                    <div>
                      <span>24h Volume {topInfos?.quoteAsset}</span>
                      <span>{topInfos?.quoteVolume}</span>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col className='listBox' style={{ width: '310px' }}>
                    <div style={{ marginBottom: '10px' }}>
                      <Segmented
                        className='listboxSegmented'
                        value={orderBookListFilter}
                        onChange={setOrderBookListFilter}
                        style={{
                          backgroundColor: 'transparent',
                          color: '#A5ADCF',
                          padding: '10px',
                          marginLeft: '10px',
                          marginRight: '20px',
                        }}
                        options={[
                          {
                            value: 'both',
                            icon: (
                              <img
                                src={theme.palette.mode === 'light' ? iconListRedGreenLight : iconListRedGreen}
                                sizes={20}
                                alt=''
                              />
                            ),
                          },
                          {
                            value: 'green',
                            icon: (
                              <img
                                src={theme.palette.mode === 'light' ? iconListGreenLight : iconListGreen}
                                sizes={20}
                                alt=''
                              />
                            ),
                          },
                          {
                            value: 'red',
                            icon: (
                              <img
                                src={theme.palette.mode === 'light' ? iconListRedLight : iconListRed}
                                sizes={20}
                                alt=''
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                    <CustomListBoxContainer
                      symbolInfo={selectedSymbol}
                      shape={orderBookListFilter}
                      style={{ width: '310px' }}
                    />
                  </Col>
                  <Col style={{ display: 'inline-block', width: 'calc(100% - 310px)' }}>
                    <div className={classes.chartContainer} style={{ height: '550px' }}>
                      {/* {
                        <div className={classes.tradingViewChartLoading}>
                          <Spin />
                        </div>
                      } */}
                      <TradingViewChart symbolInfo={selectedSymbol} height='550px' />
                    </div>
                    <BuySell symbolInfo={selectedSymbol} />
                  </Col>
                </Row>
              </Col>
              <Col style={{ width: '310px' }}>
                <div style={{ margin: '20px 10px 20px 10px' }}>
                  <AutoComplete
                    disabled={loading_getAllSymbolInfo}
                    className='autoCompleteSearch_trade'
                    // dataSource={pairCoinList?.tempList?.map(coin => coin.symbol)}
                    onChange={onSearchInputChange}
                  >
                    <Input prefix={<FiSearch color='#34384C' />} placeholder='Search' />
                  </AutoComplete>
                  {/* <Input placeholder="Search" prefix={<FiSearch />} className={classes.inputSearch} /> */}
                </div>

                <PairFavoriteScroll favoritList={favoritList} onHorizentalListItemClick={onHorizentalListItemClick} />

                <PairTable
                  dataSource={pairCoinList?.mainList}
                  loading={loading_getAllSymbolInfo}
                  onRow={onRowPairTable}
                  userFavorites={userFavorites}
                  onReloadUserFavorites={fetchFavorites}
                  style={{ height: '290px' }}
                />
                <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                  <span>Market Trades</span>
                </div>
                <MarketTradeTable symbolInfo={selectedSymbol} />
                <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                  <span>Market Activities</span>
                </div>
                <MarketActivitiesTable />
              </Col>
            </Row>
          );
        }}
      />

      <Media
        query={screenSize.between_1399_768}
        render={() => {
          return (
            <Row>
              <Col span={24}>
                <Row align='middle' style={{ height: '80px', borderBottom: '1px solid #34384C' }}>
                  {loading_getAllSymbolInfo && (
                    <div className={classes.topInfoLoading}>
                      <Spin />
                    </div>
                  )}
                  <div className={classes.topInfosDiv}>
                    <div>
                      <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        {topInfos?.baseAsset + '/' + topInfos?.quoteAsset}
                      </span>
                      <span>{topInfos?.baseName}</span>
                    </div>
                    <div className={classes.verticalLine}></div>
                    <div>
                      <span
                        style={{ fontSize: '21px' }}
                        className={
                          orderBook?.tradeInfos[0]?.p >= prevLastPrice ? classes.changeGreen : classes.changeRed
                        }
                      >
                        {orderBook?.tradeInfos[0]?.p ? orderBook?.tradeInfos[0]?.p : '-'}
                      </span>
                      <span>{'$' + (orderBook?.dollarPrice ? orderBook?.dollarPrice : '-')}</span>
                    </div>
                    <div>
                      <span>24h Change</span>
                      <span className={topInfos?.priceChange >= 0 ? classes.changeGreen : classes.changeRed}>
                        {topInfos?.priceChange + ' ' + topInfos?.priceChangePercent + '%'}
                      </span>
                    </div>
                    <div>
                      <span>24h High</span>
                      <span>{topInfos?.highPrice}</span>
                    </div>
                    <div>
                      <span>24h low</span>
                      <span>{topInfos?.lowPrice}</span>
                    </div>
                    <div>
                      <span>24h Volume {topInfos?.baseAsset}</span>
                      <span>{topInfos?.volume}</span>
                    </div>
                    <div>
                      <span>24h Volume {topInfos?.quoteAsset}</span>
                      <span>{topInfos?.quoteVolume}</span>
                    </div>
                  </div>
                </Row>
              </Col>

              <Col span={24}>
                <div style={{ height: '550px', width: '100%' }}>
                  {/* {
                    <div className={classes.tradingViewChartLoading}>
                      <Spin />
                    </div>
                  } */}
                  <TradingViewChart symbolInfo={selectedSymbol} height='550px' />
                </div>
              </Col>
              <Row>
                <Col className='listBox' span={12}>
                  <div style={{ marginBottom: '10px' }}>
                    <Segmented
                      className='listboxSegmented'
                      value={orderBookListFilter}
                      onChange={setOrderBookListFilter}
                      style={{
                        backgroundColor: 'transparent',
                        color: '#A5ADCF',
                        padding: '10px',
                        marginLeft: '10px',
                        marginRight: '20px',
                      }}
                      options={[
                        {
                          value: 'both',
                          icon: <img src={iconListRedGreen} sizes={20} alt='' />,
                        },
                        {
                          value: 'green',
                          icon: <img src={iconListGreen} sizes={20} alt='' />,
                        },
                        {
                          value: 'red',
                          icon: <img src={iconListRed} sizes={20} alt='' />,
                        },
                      ]}
                    />
                  </div>
                  <CustomListBoxContainer symbolInfo={selectedSymbol} shape={orderBookListFilter} />
                </Col>
                <Col span={12}>
                  <div style={{ margin: '20px 10px 20px 10px' }}>
                    <AutoComplete
                      disabled={loading_getAllSymbolInfo}
                      className='autoCompleteSearch_trade'
                      // dataSource={pairCoinList?.tempList?.map(coin => coin.symbol)}
                      onChange={onSearchInputChange}
                    >
                      <Input prefix={<FiSearch color='#34384C' />} placeholder='Search' />
                    </AutoComplete>
                  </div>

                  <PairFavoriteScroll favoritList={favoritList} onHorizentalListItemClick={onHorizentalListItemClick} />

                  <PairTable
                    dataSource={pairCoinList?.mainList}
                    loading={loading_getAllSymbolInfo}
                    onRow={onRowPairTable}
                    userFavorites={userFavorites}
                    onReloadUserFavorites={fetchFavorites}
                    style={{ height: '206px' }}
                  />
                  <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                    <span>Market Trades</span>
                  </div>
                  <MarketTradeTable symbolInfo={selectedSymbol} />
                  <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                    <span>Market Activities</span>
                  </div>
                  <MarketActivitiesTable />
                </Col>
              </Row>

              <Col span={24}>
                <div style={{ borderTop: '1px solid #34384C' }}>
                  <BuySell symbolInfo={selectedSymbol} />
                </div>
              </Col>
            </Row>
          );
        }}
      />

      <Media
        query={screenSize.smallerThanMd}
        render={() => {
          return (
            <Row>
              <Col span={24}>
                <div>
                  {loading_getAllSymbolInfo && (
                    <div className={classes.topInfoLoadingM}>
                      <Spin />
                    </div>
                  )}

                  <Row align='middle' style={{ borderBottom: '1px solid #34384C', padding: '20px 0px 20px 20px' }}>
                    <Col span={10}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
                            {topInfos?.baseAsset + '/' + topInfos?.quoteAsset}
                          </span>
                          <span style={{ fontSize: '12px' }}>{topInfos?.baseName}</span>
                        </div>
                        <span
                          className={
                            orderBook?.tradeInfos[0]?.p >= prevLastPrice ? classes.changeGreen : classes.changeRed
                          }
                          style={{ fontSize: '15px', fontWeight: 'bold' }}
                        >
                          {orderBook?.tradeInfos[0]?.p ? orderBook?.tradeInfos[0]?.p : '-'}
                        </span>
                        <span
                          className={topInfos?.priceChange >= 0 ? classes.changeGreen : classes.changeRed}
                          style={{ fontSize: '12px' }}
                        >
                          {topInfos?.priceChange + ' ' + topInfos?.priceChangePercent + '%'}
                        </span>
                      </div>
                    </Col>
                    <Col span={14}>
                      <Row style={{ marginBottom: '10px' }}>
                        <Col span={12}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={classes.topInfoTitleM}>24h High</span>
                            <span className={classes.topInfoTextM}>{topInfos?.highPrice}</span>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={classes.topInfoTitleM}>24h low</span>
                            <span className={classes.topInfoTextM}>{topInfos?.lowPrice}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={classes.topInfoTitleM}>24h Volume</span>
                            <span className={classes.topInfoTitleSymbolM}>{topInfos?.baseAsset}</span>
                            <span className={classes.topInfoTextM}>{topInfos?.volume}</span>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <span className={classes.topInfoTitleM}>24h Volume</span>
                            <span className={classes.topInfoTitleSymbolM}>{topInfos?.quoteAsset}</span>
                            <span className={classes.topInfoTextM}>{topInfos?.quoteVolume}</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <div className={classes.topInfosDiv}></div>
                  </Row>
                </div>
              </Col>
              <Col className='listBox' span={24}>
                <div style={{ marginBottom: '10px' }}>
                  <Segmented
                    className='listboxSegmented'
                    value={orderBookListFilter}
                    onChange={setOrderBookListFilter}
                    style={{
                      backgroundColor: 'transparent',
                      color: '#A5ADCF',
                      padding: '10px',
                      marginLeft: '10px',
                      marginRight: '20px',
                    }}
                    options={[
                      {
                        value: 'both',
                        icon: <img src={iconListRedGreen} sizes={20} alt='' />,
                      },
                      {
                        value: 'green',
                        icon: <img src={iconListGreen} sizes={20} alt='' />,
                      },
                      {
                        value: 'red',
                        icon: <img src={iconListRed} sizes={20} alt='' />,
                      },
                    ]}
                  />
                </div>
                <CustomListBoxContainer symbolInfo={selectedSymbol} shape={orderBookListFilter} />
              </Col>

              <Col span={24}>
                <div style={{ height: '550px', width: '100%' }}>
                  {/* {
                    <div className={classes.tradingViewChartLoading}>
                      <Spin />
                    </div>
                  } */}
                  <TradingViewChart symbolInfo={selectedSymbol} height='550px' />
                </div>
                <BuySell symbolInfo={selectedSymbol} />
              </Col>

              <Col span={24}>
                <div style={{ margin: '20px 10px 20px 10px' }}>
                  <AutoComplete
                    disabled={loading_getAllSymbolInfo}
                    className='autoCompleteSearch_trade'
                    // dataSource={pairCoinList?.tempList?.map(coin => coin.symbol)}
                    onChange={onSearchInputChange}
                  >
                    <Input prefix={<FiSearch color='#34384C' />} placeholder='Search' />
                  </AutoComplete>
                </div>

                <PairFavoriteScroll favoritList={favoritList} onHorizentalListItemClick={onHorizentalListItemClick} />

                <PairTable
                  dataSource={pairCoinList?.mainList}
                  loading={loading_getAllSymbolInfo}
                  onRow={onRowPairTable}
                  userFavorites={userFavorites}
                  onReloadUserFavorites={fetchFavorites}
                  style={{ height: '206px' }}
                />
                <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                  <span>Market Trades</span>
                </div>
                <MarketTradeTable symbolInfo={selectedSymbol} />
                <div style={{ marginLeft: '10px', marginTop: '10px' }}>
                  <span>Market Activities</span>
                </div>
                <MarketActivitiesTable />
              </Col>
            </Row>
          );
        }}
      />

      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <Tabs
            defaultActiveKey={tabKey.Orders}
            onChange={onChange}
            activeKey={activeKey}
            style={{ margin: '0 10px' }}
            tabBarStyle={{ ...(theme.palette.mode === 'light' && { color: '#000' }) }}
          >
            <TabPane key={tabKey.OpenOrders} tab={`Open Orders ( ${openTradeInfos?.length} )`}>
              <OpenOrdersTable symbolInfo={selectedSymbol} />
            </TabPane>
            <TabPane key={tabKey.OrderHistory} tab='Order History'>
              <OrderHistoryTable symbolInfo={selectedSymbol} />
            </TabPane>
            {/* <TabPane
                            key={tabKey.Funds}
                            tab="Funds">
                            <FundsTable />
                        </TabPane> */}
          </Tabs>
        </Col>
      </Row>
    </div>
  );
};

export default Trade;
