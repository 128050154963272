import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import './App.scss';
import { LoadingPortal } from './components/FakeLoadingScreen';
import MainLayout from './layouts/mainLayout/mainLayout';
import AdminLayout from './layouts/mainLayout/adminMainLayout/adminLayout';
import LoadingScreen from './components/LoadingScreen';
import { ChartStyle } from './components/chart';
import { SET_LANGUAGE, SET_THEME_DIRECTION, SET_THEME_MODE, SET_TOAST } from './store/actionTypes';

import MainPage from './pages/panel/mainPage/mainPage';
import Login from './pages/panel/login/login';
import Register from './pages/panel/register/register';
import Verify from './pages/panel/verify/verify';
import Authentication from './pages/panel/authentication/authentication';
import BasicAuthIdentityInfo from './pages/panel/authentication/basicAuthentication/basicAuthIdentityInfo/basicAuthIdentityInfo';
import BasicAuthAdditionalInfo from './pages/panel/authentication/basicAuthentication/basicAuthAdditionalInfo/basicAuthAdditionalInfo';
import BasicAuthVerified from './pages/panel/authentication/basicAuthentication/basicAuthVerified/basicAuthVerified';
import InterAuthSelect from './pages/panel/authentication/intermediateAuthentication/select/interAuthSelect';
import InterAdditionalInfo from './pages/panel/authentication/intermediateAuthentication/interAdditionalInfo/interAdditionalInfo';
import InterFaceScan from './pages/panel/authentication/intermediateAuthentication/interFaceScan/interFaceScan';
import Wallet from './pages/panel/wallet/wallet';
import AdvancedAuthAddress from './pages/panel/authentication/advancedAuthentication/advancedAuthAddress/advancedAuthAddress';
import AdvancedAuthSelect from './pages/panel/authentication/advancedAuthentication/advancedAuthSelect/advancedAuthSelect';
import AdvancedAuthVerified from './pages/panel/authentication/advancedAuthentication/advancedAuthVerified/advancedAuthVerified';
import ForgotPassword from './pages/panel/forgotPassword/forgotPassword';
import ForgotPassCheckEmail from './pages/panel/forgotPassword/forgotPassCheckEmail/forgotPassCheckEmail';
import ForgotNewPass from './pages/panel/forgotPassword/forgotNewPass/forgotNewPass';
import ForgotPassResetOk from './pages/panel/forgotPassword/forgotPassResetOk/forgotPassResetOk';
import BankCard from './pages/panel/bankCard/bankCard';
import Trade from './pages/panel/trade/trade';
import InterVerified from './pages/panel/authentication/intermediateAuthentication/interVerified/interVerified';
import NotFound from './pages/panel/resultPages/notFound';
import InternetDisconnected from './pages/panel/resultPages/internetDisconnected';
import VerifySoftOtp from './pages/panel/verifySoftOtp/verifySoftOtp';
import FiatDeposit from './pages/panel/fiatDeposit/fiatDeposit';
import FiatWithdraw from './pages/panel/fiatWithdraw/fiatWithdraw';
import P2PList from './pages/panel/p2p/list/p2pList';
import P2PRequest from './pages/panel/p2p/request/p2pRequest';
import PerfectMoney from './pages/panel/perfectMoney/perfectMoney';
import PerfectMoneyPayment from './pages/panel/perfectMoney/perfectMoneyPayment';
import WithdrawCryptoRequest from './pages/panel/withdrawCrypto/request/withdrawCryptoRequest';
import WithdrawCryptoList from './pages/panel/withdrawCrypto/list/withdrawCryptoList';
import DepositCryptoRequest from './pages/panel/depositCrypto/request/depositCryptoRequest';
import DepositCryptoList from './pages/panel/depositCrypto/list/depositCryptoList';
import OTC from './pages/panel/otc/otc';
import Convert from './pages/panel/convert/convert';
import ReferralsIncome from './pages/panel/referralsIncome/referralsIncome';
import Market from './pages/panel/market/market';
import Futures from './pages/panel/futures/futures';

import User from './pages/admin/user/user/user';
import AddUser from './pages/admin/user/addUser/addUser';
import EditUser from './pages/admin/user/edit/editUser';
import UserManagement from './pages/admin/user/userManagement/userManagement';
import UserLevelSetting from './pages/admin/settings/userLevelSetting/userLevelSetting';
import NetworkSetting from './pages/admin/settings/network/network';
import AutoDepositSetting from './pages/admin/settings/autoDepositSetting/autoDepositSetting';
import DepositSetting from './pages/admin/settings/depositSetting/depositSetting';
import GeneralSettings from './pages/admin/settings/generalSettings/generalSettings';
import DedicatedGateway from './pages/admin/settings/dedicatedGateway/dedicatedGateway';
import InventorySetting from './pages/admin/settings/inventorySetting/inventorySetting';
import PopupSettings from './pages/admin/settings/popupSettings/popupSettings';
import CommissionSettings from './pages/admin/settings/commissionSettings/commissionSettings';
// import VoucherSettings from './pages/admin/settings/voucherSettings/voucherSettings';
import PerfectMoneySettings from './pages/admin/settings/perfectMoneySettings/perfectMoneySettings';
import PaySafeSettings from './pages/admin/settings/paySafeSettings/paySafeSettings';
import CryptoCurrencySetting from './pages/admin/settings/cryptoCurrencySetting/cryptoCurrencySetting';
// import TetherSettings from './pages/admin/settings/tetherSettings/tetherSettings';
// import WithdrawalCeiling from './pages/admin/settings/withdrawalCeiling/withdrawalCeiling';
// import BuyDigitalCurrency from './pages/admin/settings/buyDigitalCurrency/buyDigitalCurrency';
import Verification from './pages/admin/settings/verification/verification';
import AdminLogin from './pages/admin/adminLogin/adminLogin';
import AdminVerifyEmail from './pages/admin/adminVerifyEmail/adminVerifyEmail';
import IpBlock from './pages/admin/ipBlock/ipBlock';
import AdminForgotPassword from './pages/admin/adminForgotPassword/adminForgotPassword';
import AdminForgotPassCheckEmail from './pages/admin/adminForgotPassword/adminForgotPassCheckEmail/adminForgotPassCheckEmail';
import AdminForgotNewPass from './pages/admin/adminForgotPassword/adminForgotNewPass/adminForgotNewPass';
import AdminForgotPassOk from './pages/admin/adminForgotPassword/adminForgotPassOk/adminForgotPassOk';
import AdminMainPage from './pages/admin/mainPage/mainPage';
import AdminReport from './pages/admin/report/report';
import NewNetwork from './pages/admin/settings/newNetwork/newNetwork';
import AdminList from './pages/admin/admin/adminList/adminList';
import AddAdmin from './pages/admin/admin/addAdmin/addAdmin';
import EditAdmin from './pages/admin/admin/editAdmin/editAdmin';
import WalletCryptoTransaction from './pages/admin/wallet/walletCryptoTransaction/walletCrptoTransaction';
import WalletFiatTransaction from './pages/admin/wallet/walletFiatTransaction/walletFiatTransaction';
import AdminBankCard from './pages/admin/bankCard/bankCard';
import ReferralMarketing from './pages/admin/referralMarketing/referralMarketing';
import WaitingUser from './pages/admin/user/waitingUser/waitingUser';
import AdminNotification from './pages/admin/adminNotification/adminNotification';
import AutomaticDepositHistory from './pages/admin/automaticDepositHistory/automaticDepositHistory';
import FuturesSetting from './pages/admin/settings/futuresSetting/futuresSetting';
import WaitingWithdrawFiat from './pages/admin/fiat/waitingWithdrawFiat/waitingWithdrawFiat';
import WaitingDepositFiat from './pages/admin/fiat/waitingDepositFiat/waitingDepositFiat';
import { AddToken } from './pages/admin/tokens/add';
import { EditToken } from './pages/admin/tokens/edit';
import { TokenList } from './pages/admin/tokens/list';
import { MainWalletSetting } from './pages/admin/tokens/mainWalletSetting';
import { NetworkSetting as TokenNetworkSetting } from './pages/admin/tokens/networkSetting';

import Landing from './pages/landing/landing';

import AccountSetting from './pages/common/accountSetting/accountSetting';
import TicketList from './pages/common/ticket/ticketList/ticketList';
import AddTicket from './pages/common/ticket/addTicket/addTicket';
import ViewTicket from './pages/common/ticket/viewTicket/viewTicket';
import Notification from './pages/common/notification/notification';
import TradeHistoryTable from './pages/common/history/tradeHistory/tradeHistoryTable';
import CryptoTransactionTable from './pages/common/history/cryptoTransaction/cryptoTransactionTable';
import FiatTransactionTable from './pages/common/history/fiatTransaction/fiatTransactionTable';
import ConvertHistoryTable from './pages/common/history/convertHistory/convertHistoryTable';
import OrderHistoryTable from './pages/common/history/orderHistory/orderHistoryTable';
import P2PHistoryTable from './pages/common/history/p2pHistory/p2pHistoryTable';
import FuturesHistoryTable from './pages/common/history/futuresHistory/futuresHistoryTable';
import PaysafeHistoryTable from './pages/common/history/paysafeHistory/paysafeHistoryTable';
import PerfectMoneyHistoryTable from './pages/common/history/perfectMoneyHistory/perfectMoneyHistoryTable';

import TermsOfUse from './pages/static/termsOfUse';
import PrivacyPolicy from './pages/static/privacyPolicy';
import TermsConvert from './pages/static/termsConvert';
import TermsOTC from './pages/static/termsOtc';
import ContactUs from './pages/static/contactUs';

import './globalStyles/antdStyles.scss';
import './globalStyles/globalStyles.scss';
import Listing from './pages/static/listing';
import DedicatedWalletSettings from './pages/admin/dedicatedWalletSettings/DedicatedWalletSettings';
import FuturesWallet from './pages/panel/futuresWallet/futuresWallet';
import GasPumpWalletSetting from './pages/admin/tokens/gasPumpWalletSetting/GasPumpWalletSetting';

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const toast = useSelector((state) => state.toast);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (toast.message) {
      enqueueSnackbar(toast.message, { variant: toast.type });
      dispatch({ type: SET_TOAST, payload: { type: '', message: '' } });
    }
  }, [toast.message]);

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    const dir = localStorage.getItem('dir');
    const themeMode = localStorage.getItem('themeMode');

    i18n.changeLanguage(lang || 'en');
    dispatch({ type: SET_LANGUAGE, payload: lang });
    dispatch({ type: SET_THEME_DIRECTION, payload: dir });
    dispatch({ type: SET_THEME_MODE, payload: themeMode || 'dark' });
  }, []);

  return (
    <>
      <ChartStyle />

      <Suspense fallback={<LoadingScreen />}>
        <Router>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/verify' element={<Verify />} />
            <Route path='/VerifySoftOtp' element={<VerifySoftOtp />} />
            <Route path='/forgotpass' element={<ForgotPassword />} />
            <Route path='/forgotpass/checkemail' element={<ForgotPassCheckEmail />} />
            <Route path='/forgotpass/newpass' element={<ForgotNewPass />} />
            <Route path='/forgotpass/resetok' element={<ForgotPassResetOk />} />
            <Route path='/perfectMoney/payment' element={<PerfectMoneyPayment success />} />
            <Route path='/perfectMoney/noPayment' element={<PerfectMoneyPayment success={false} />} />

            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='/listing' element={<Listing />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-otc' element={<TermsOTC />} />
            <Route path='/terms-convert' element={<TermsConvert />} />
            <Route path='/contact' element={<ContactUs />} />

            <Route path='/panel' element={<MainLayout />}>
              <Route index element={<MainPage />} />
              <Route path='authentication' element={<Authentication />} />
              <Route path='authentication/basic/identityinfo' element={<BasicAuthIdentityInfo />} />
              <Route path='authentication/basic/additionalinfo' element={<BasicAuthAdditionalInfo />} />
              <Route path='authentication/basic/verified' element={<BasicAuthVerified />} />
              <Route path='authentication/intermediate/select' element={<InterAuthSelect />} />
              <Route path='authentication/intermediate/additionalinfo' element={<InterAdditionalInfo />} />
              <Route path='authentication/intermediate/verified' element={<InterVerified />} />
              <Route path='authentication/intermediate/facescan' element={<InterFaceScan />} />
              <Route path='authentication/advanced/address' element={<AdvancedAuthAddress />} />
              <Route path='authentication/advanced/select' element={<AdvancedAuthSelect />} />
              <Route path='authentication/advanced/verified' element={<AdvancedAuthVerified />} />
              <Route path='accountSetting' element={<AccountSetting isAdmin={false} />} />
              <Route path='wallet' element={<Wallet />} />
              <Route path='futuresWallet' element={<FuturesWallet />} />

              <Route path='otc' element={<OTC />} />
              <Route path='withdrawCrypto/request' element={<WithdrawCryptoRequest />} />
              <Route path='withdrawCrypto/list' element={<WithdrawCryptoList />} />
              <Route path='tradeHistory' element={<TradeHistoryTable isAdmin={false} />} />
              <Route path='openTrade' element={<TradeHistoryTable isAdmin={false} showOpenTrades />} />
              <Route path='cryptoTransaction' element={<CryptoTransactionTable isAdmin={false} />} />
              <Route path='internalTransaction' element={<CryptoTransactionTable onlyInternalType isAdmin={false} />} />
              <Route path='fiatTransaction' element={<FiatTransactionTable isAdmin={false} />} />
              <Route path='convertHistory' element={<ConvertHistoryTable isAdmin={false} />} />
              <Route path='futuresHistory' element={<FuturesHistoryTable isAdmin={false} />} />
              <Route path='orderHistory' element={<OrderHistoryTable isAdmin={false} />} />
              <Route path='p2pHistory' element={<P2PHistoryTable isAdmin={false} />} />
              <Route path='paysafeHistory' element={<PaysafeHistoryTable isAdmin={false} />} />
              <Route path='perfectMoneyHistory' element={<PerfectMoneyHistoryTable isAdmin={false} />} />
              <Route path='bankcard' element={<BankCard />} />
              <Route path='trade' element={<Trade />} />
              <Route path='fiatDeposit' element={<FiatDeposit />} />
              <Route path='fiatWithdraw' element={<FiatWithdraw />} />
              <Route path='p2p/list' element={<P2PList />} />
              <Route path='p2p/request' element={<P2PRequest />} />
              <Route path='market' element={<Market />} />
              <Route path='eMoney' element={<PerfectMoney />} />
              <Route path='depositCrypto/request' element={<DepositCryptoRequest />} />
              <Route path='depositCrypto/list' element={<DepositCryptoList />} />
              <Route path='convert' element={<Convert />} />
              <Route path='ticket/list' element={<TicketList isAdmin={false} />} />
              <Route path='ticket/new' element={<AddTicket />} />
              <Route path='ticket/view/:id' element={<ViewTicket />} />
              <Route path='referralsIncome' element={<ReferralsIncome />} />
              <Route path='notification' element={<Notification />} />
              <Route path='futures' element={<Futures />} />
            </Route>
            <Route path='/404' element={<NotFound />} />
            <Route path='/0' element={<InternetDisconnected />} />
            <Route path='*' element={<Navigate to='/404' />} />

            <Route path='/admin/login' element={<AdminLogin />} />
            <Route path='/admin/verifyEmail' element={<AdminVerifyEmail />} />
            <Route path='/admin/forgotpass' element={<AdminForgotPassword />} />
            <Route path='/admin/forgotpass/checkemail' element={<AdminForgotPassCheckEmail />} />
            <Route path='/admin/forgotpass/newpass' element={<AdminForgotNewPass />} />
            <Route path='/admin/forgotpass/resetok' element={<AdminForgotPassOk />} />
            <Route path='admin' element={<AdminLayout />}>
              <Route index element={<AdminMainPage />} />
              <Route path='token' element={<TokenList />} />
              <Route path='token/add' element={<AddToken />} />
              <Route path='token/edit/:id' element={<EditToken />} />
              <Route path='token/mainWalletSetting' element={<MainWalletSetting />} />
              <Route path='token/gasPumpWalletSetting' element={<GasPumpWalletSetting />} />

              <Route path='token/networkSetting' element={<TokenNetworkSetting />} />
              <Route path='report' element={<AdminReport />} />
              <Route path='user' element={<User />} />
              <Route path='waitingUser' element={<WaitingUser />} />
              <Route path='waitingWithdrawFiat' element={<WaitingWithdrawFiat />} />
              <Route path='waitingDepositFiat' element={<WaitingDepositFiat />} />
              <Route path='addUser' element={<AddUser />} />
              <Route path='user/edit/:id' element={<EditUser />} />
              <Route path='user/management' element={<UserManagement />} />
              <Route path='admin/list' element={<AdminList />} />
              <Route path='admin/new' element={<AddAdmin />} />
              <Route path='admin/edit/:id' element={<EditAdmin />} />
              <Route path='setting/userLevel' element={<UserLevelSetting />} />
              <Route path='setting/ipBlock' element={<IpBlock />} />
              <Route path='setting/network' element={<NetworkSetting />} />
              <Route path='setting/network/new' element={<NewNetwork />} />
              <Route path='setting/general' element={<GeneralSettings />} />
              <Route path='setting/autoDeposit' element={<AutoDepositSetting />} />
              <Route path='setting/dedicatedGateway' element={<DedicatedGateway />} />
              <Route path='setting/deposit' element={<DepositSetting />} />
              <Route path='setting/inventory' element={<InventorySetting />} />
              <Route path='setting/popup' element={<PopupSettings />} />
              <Route path='setting/commission' element={<CommissionSettings />} />
              <Route path='setting/futures' element={<FuturesSetting />} />
              {/* <Route path='setting/voucher' element={<VoucherSettings />} /> */}
              <Route path='setting/perfectMoney' element={<PerfectMoneySettings />} />
              <Route path='setting/paySafe' element={<PaySafeSettings />} />
              <Route path='setting/cryptoCurrency' element={<CryptoCurrencySetting />} />
              {/* <Route path='setting/tether' element={<TetherSettings />} /> */}
              {/* <Route path='setting/withdrawalCeiling' element={<WithdrawalCeiling />} />
              <Route path='setting/digitalCurrency' element={<BuyDigitalCurrency />} /> */}
              <Route path='setting/verification' element={<Verification />} />
              <Route path='dedicated-wallet-settings' element={<DedicatedWalletSettings />} />
              <Route path='wallet/cryptoTransaction' element={<WalletCryptoTransaction />} />
              <Route path='wallet/transaction' element={<WalletFiatTransaction />} />
              <Route path='referralMarketing' element={<ReferralMarketing />} />
              <Route path='bankCard' element={<AdminBankCard />} />
              <Route path='accountSetting' element={<AccountSetting isAdmin />} />
              <Route path='ticket/list' element={<TicketList isAdmin />} />
              <Route path='ticket/new' element={<AddTicket />} />
              <Route path='ticket/view/:id' element={<ViewTicket isAdmin />} />
              <Route path='settingNotification' element={<AdminNotification />} />
              <Route path='notification' element={<Notification />} />
              <Route path='automaticDepositHistory' element={<AutomaticDepositHistory />} />
              <Route path='tradeHistory' element={<TradeHistoryTable isAdmin />} />
              <Route path='openTrade' element={<TradeHistoryTable isAdmin showOpenTrades />} />
              <Route path='cryptoTransaction' element={<CryptoTransactionTable isAdmin />} />
              <Route path='internalTransaction' element={<CryptoTransactionTable onlyInternalType isAdmin />} />
              <Route path='fiatTransaction' element={<FiatTransactionTable isAdmin />} />
              <Route path='orderHistory' element={<OrderHistoryTable isAdmin />} />
              <Route path='p2pHistory' element={<P2PHistoryTable isAdmin />} />
              <Route path='convertHistory' element={<ConvertHistoryTable isAdmin />} />
              <Route path='futuresHistory' element={<FuturesHistoryTable isAdmin />} />
              <Route path='paysafeHistory' element={<PaysafeHistoryTable isAdmin />} />
              <Route path='perfectMoneyHistory' element={<PerfectMoneyHistoryTable isAdmin />} />
            </Route>
          </Routes>
        </Router>
      </Suspense>

      <LoadingPortal />
    </>
  );
}

export default App;
