import { useSnackbar } from 'notistack';

import Api from '../../../../../http/adminHttp/serviceApiAdmin';
import { useState } from 'react';
import { imageToBase64 } from '../../../../../utils';

import { useTranslation } from 'react-i18next';


export default function usePopupMobileSubmit() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (payload, attach) => {
    attach && (attach = await imageToBase64(attach));
    const data = {
      ...payload,
      popupType: 'MOBILE',
      showDays: payload.showDays,
      value: payload.value.replace('<p>', '').replace('</p>', ''),
      image: attach,
    };
    setIsLoading(true);

    try {
      const response = await Api.editPopUpbData(data);
      if (response?.status === 'SUCCESS') {
        enqueueSnackbar(t('The values saved successfully.'), { variant: 'success' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return {
    onSubmit,
    isLoading,
  };
}
