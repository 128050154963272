import { TableCell, TableRow, Typography, Box, Stack, IconButton } from '@mui/material';
import moment from 'jalali-moment';
import { useSelector } from 'react-redux';
import Iconify from '../../../../../../components/Iconify';

export default function AdminTomanTransactionTableRow({ row, index }) {
  const language = useSelector((store) => store.language);
  const { name, currency, type, arrow, amount, date, description, detail } = row;

  return (
    <TableRow hover sx={{ '& td': { py: 1, typography: 'body2' } }}>
      <TableCell align='center' sx={{ width: 100 }}>
        {index + 1}
      </TableCell>

      <TableCell>
        <Typography variant='body2'>{name}</Typography>
      </TableCell>

      <TableCell>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          <Iconify
            icon={arrow === 'up' ? 'ph:arrow-down-fill' : 'ph:arrow-up-fill'}
            color={arrow === 'up' ? 'error.darker' : 'positive.main'}
            height={18}
          />
          <Typography variant='body2' color={arrow === 'up' ? 'error.darker' : 'positive.main'}>
            {currency}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell>{type}</TableCell>

      <TableCell align='center'>{amount}</TableCell>

      <TableCell>{moment(date).locale(language).format('D MMM YYYY - HH:mm')}</TableCell>

      <TableCell>{description}</TableCell>

      <TableCell sx={{ width: 170 }} align='center'>
        <Box
          sx={{
            width: 130,
            borderRadius: '22px',
            textAlign: 'center',
            m: 'auto',
            p: 0.8,
            bgcolor: 'rgba(48, 224, 161, 0.2)',
          }}
        >
          <Typography variant='body2' color={'#30E0A1'}>
            {detail}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align='center' sx={{ width: 100 }}>
        <IconButton>
          <Iconify icon='ph:link-duotone' />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
