import { Button, MenuItem, Stack, TextField } from '@mui/material';
import DatePicker from '../../../../../../components/DatePicker';
import NumberRange from '../../../../../../components/NumberRange';
import { useTranslation } from 'react-i18next';
import ComplexToolbar from '../../../../../../components/ComplexToolbar';
import NumRangeWithInput from '../../../../../../components/NumRangeWithInput';

export default function AdminTomanTransactionToolbar({
  conditionFilter,
  setConditionFilter,
  bankFilter,
  setBankFilter,
  amountFilter,
  setAmountFilter,
  typeFilter,
  setTypeFilter,
  dateFromFilter,
  setDateFromFilter,
  dateToFilter,
  setDateToFilter,
}) {
  const { t } = useTranslation();

  return (
    <>
      <ComplexToolbar
        onSearch={(v) => console.log(v)}
        onExport={() => console.log('export')}
        onPrint={() => console.log('print')}
      >
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} sx={{ mt: 2 }} alignItems={'center'}>
            <TextField
              select
              label={t('Condition')}
              variant='standard'
              fullWidth
              value={conditionFilter}
              onChange={(e) => setConditionFilter(e.target.value)}
              sx={{ maxWidth: 300 }}
              SelectProps={{
                MenuProps: {
                  sx: {
                    '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                    '& .MuiMenuItem-root': { typography: 'body2' },
                  },
                },
              }}
            >
              <MenuItem value={'ALL'}>{t('All')}</MenuItem>
              <MenuItem value={'active'}>{t('Active')}</MenuItem>
              <MenuItem value={'inactive'}>{t('Inactive')}</MenuItem>
            </TextField>

            <TextField
              select
              label={t('Bank')}
              variant='standard'
              fullWidth
              value={bankFilter}
              onChange={(e) => setBankFilter(e.target.value)}
              sx={{ maxWidth: 300 }}
              SelectProps={{
                MenuProps: {
                  sx: {
                    '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                    '& .MuiMenuItem-root': { typography: 'body2' },
                  },
                },
              }}
            >
              <MenuItem value={'all'}>{t('All')}</MenuItem>
            </TextField>

            <Stack direction={'row'}>
              <DatePicker
                label={t('From')}
                value={dateFromFilter}
                setValue={setDateFromFilter}
                variant='standard'
                maxDate={dateToFilter}
                fullWidth
                sx={{ maxWidth: 150 }}
                InputProps={null}
              />
              <DatePicker
                label={t('To')}
                value={dateToFilter}
                setValue={setDateToFilter}
                variant='standard'
                minDate={dateFromFilter}
                fullWidth
                sx={{ maxWidth: 150 }}
              />
            </Stack>
          </Stack>

          <Stack direction={{ xs: 'column', md: 'row' }} alignItems={'center'} spacing={5}>
            {/*<NumberRange label={t('Amount of')} value={amountFilter} setValue={setAmountFilter} sx={{ maxWidth: 300 }} />*/}
            <NumRangeWithInput label={t('Amount of')} value={amountFilter} setValue={setAmountFilter} sx={{ maxWidth: 300 }} />

            <TextField
              select
              label={t('Type')}
              variant='standard'
              fullWidth
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              sx={{ maxWidth: 300 }}
              SelectProps={{
                MenuProps: {
                  sx: {
                    '& .MuiPaper-root': { maxHeight: 260, backgroundColor: 'common.black' },
                    '& .MuiMenuItem-root': { typography: 'body2' },
                  },
                },
              }}
            >
              <MenuItem value={'all'}>{t('All')}</MenuItem>
            </TextField>
          </Stack>
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 2, md: 5 }} sx={{ mt: 5, mb: 5.75 }}>
          <Button fullWidth variant='contained' sx={{ maxWidth: 300, typography: 'body3', fontWeight: 500 }}>
            {t('Filter')}
          </Button>
        </Stack>
      </ComplexToolbar>
    </>
  );
}
