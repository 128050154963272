import { useEffect, useState } from 'react';
import { Box, Stack, Table, TableBody, TableContainer, TablePagination, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../../../../../components/Scrollbar';
import { TableHeadCustom, TableLoading, TableNoData } from '../../../../../../components/table';
import useTable from '../../../../../../hooks/useTable';
import AdminTomanTransactionToolbar from './adminTomanTransactionToolbar';
import AdminTomanTransactionTableRow from './adminTomanTransactionTableRow';

const TABLE_HEAD = [
  { id: 'num', label: 'Num', align: 'center' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'currency', label: 'Currency', align: 'left' },
  { id: 'type', label: 'Type', align: 'left' },
  { id: 'amount', label: 'Amount', align: 'center' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'description', label: 'Description', align: 'left' },
  { id: 'status', label: 'Status', align: 'center' },
  { id: 'detail', label: 'Detail', align: 'center' },
];

export default function AdminTomanTransactionTab() {
  const { t } = useTranslation();

  const { page, order, orderBy, rowsPerPage, onSort, onChangePage, onChangeRowsPerPage } = useTable({
    defaultOrderBy: '',
  });

  const [tableData, setTableData] = useState([]);

  const [conditionFilter, setConditionFilter] = useState('active');
  const [bankFilter, setBankFilter] = useState('all');
  const [typeFilter, setTypeFilter] = useState('all');
  const [amountFilter, setAmountFilter] = useState([10000, 23000]);
  const [regardingFilter, setRegardingFilter] = useState('regarding');

  const [dateFromFilter, setDateFromFilter] = useState(null);
  const [dateToFilter, setDateToFilter] = useState(null);

  const fetchData = async () => {
    setTableData([
      {
        id: 1,
        name: 'keyvan Yazdi',
        currency: 'deposit',
        type: '10,000,090',
        arrow: 'up',
        amount: '19,000,090',
        date: new Date(),
        description: 'Test',
        detail: 'Success',
      },
      {
        id: 2,
        name: 'keyvan Yazdi',
        currency: ' Withdraw',
        type: '10,000,090',
        arrow: 'down',
        amount: '19,000,090',
        date: new Date(),
        description: 'Test',
        detail: 'Success',
      },
    ]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Stack spacing={1}>
        <Typography variant='h6' fontWeight={500} sx={{ mt: 5 }}>
          {t('Wallet transactions')}
        </Typography>

        <Typography variant='body3' color={'grey.100'}>
          {t('User wallet transactions that have been changed by this admin')}
        </Typography>
      </Stack>

      <AdminTomanTransactionToolbar
        conditionFilter={conditionFilter}
        setConditionFilter={setConditionFilter}
        bankFilter={bankFilter}
        setBankFilter={setBankFilter}
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        amountFilter={amountFilter}
        setAmountFilter={setAmountFilter}
        regardingFilter={regardingFilter}
        setRegardingFilter={setRegardingFilter}
        dateFromFilter={dateFromFilter}
        setDateFromFilter={setDateFromFilter}
        dateToFilter={dateToFilter}
        setDateToFilter={setDateToFilter}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Table size='small'>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              onSort={onSort}
            />
            <TableBody>
              {tableData === null ? (
                <TableLoading isLoading />
              ) : (
                <>
                  {tableData?.map((row, index) => (
                    <AdminTomanTransactionTableRow key={row.id} row={row} index={index} />
                  ))}

                  <TableNoData isNotFound={!tableData?.length} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={tableData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          labelRowsPerPage={t('Rows per page')}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} ${t('of')} ${count}`}
        />
      </Box>
    </>
  );
}
