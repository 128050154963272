import jsPDF from 'jspdf';

export const exportPDF = ({ language, fileName, heads, columnsWidth, list }) => {
  function createHeaders(keys) {
    return keys.map((key) => ({
      id: key,
      name: key,
      prompt: key,
      align: 'center',
      padding: 0,
    }));
  }

  const headers = createHeaders(heads);

  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'l' });

  doc
    .table(
      1,
      1,
      list.map((item, rowNumber) =>
        heads.reduce((acc, h, index) => {
          h === '#' ? (acc['#'] = `${rowNumber + 1}`) : (acc[h] = item[index - 1] ?? '-');

          return acc;
        }, {})
      ),
      headers,
      { autoSize: true, printHeaders: true }
    )
    .save();
};
