const fa = {
  translation: {
    Cryptizi: 'Cryptizi',
    'Hi, Welcome Back': 'سلام، خوش آمدید.',
    'Nice to meet you!': '!خوش آمدید',
    'Best Wallet For Your Coins': 'بهترین کیف پول برای ارزهای شما',
    Dashboard: 'داشبورد',
    Wallet: 'کیف پول',
    '{{number}} Currencies': '{{number}} ارز',
    'Sign up': 'ثبت نام',
    'Log in': 'ورود',
    Search: 'جستجو',
    No: 'خیر',
    Yes: 'بله',
    Balance: 'موجودی',
    Income: 'درآمد',
    Expenses: 'مخارج',
    Exchange: 'تبادل',
    'Top Assets': 'بالاترین دارایی‌ها',
    Transaction: 'تراکنش',
    All: 'همه',
    Receive: 'دریافتی',
    Send: 'ارسال شده',
    'Overall Growth': 'رشد کل',
    'Open trade': 'ترید آزاد',
    Market: 'بازار',
    Trade: 'ترید',
    P2P: 'نظیر به نظیر',
    'E-Money': 'پرفکت مانی',
    Convert: ' تبدیل مستقیم',
    Crypto: 'خرید رمزارز',
    Futures: 'معاملات فیوچرز',
    Possibilities: 'احتمالات',
    'Referrals & Income': 'ارجاعات و درآمد',
    Ticket: 'تیکت',
    History: 'تاریخچه',
    Authentication: 'احراز هویت',
    'Bank Card': 'کارت بانکی',
    'Account settings': 'تنظیمات حساب کاربری',
    Academy: 'آکادمی',
    Security: 'امنیت',
    Identification: 'تشخیص هویت',
    Referral: 'معرف',
    Verified: '  تاییدشده',
    'Regular user': 'کاربر عادی',
    'Log out': 'خروج',
    'Add New favorite': 'اضافه کردن موارد دلخواه جدید',
    'Total Balance': 'موجودی کل',
    '30 Days PNL': 'شاخص PNL سی روزه',
    "Today's PNL": 'شاخص PNL ',
    Withdraw: 'برداشت',
    Deposit: 'واریز',
    'Your inventory': 'موجودی شما',
    Rial: 'ریال',
    Coin: 'ارز',
    Total: 'مجموع',
    Available: 'قابل استفاده',
    'In Order': 'ترتیب',
    'BTC Value': 'مقدار BTC',
    Action: 'اقدام',
    Buy: 'خرید',
    'Rows per page': 'تعداد ردیف',
    of: 'از',

    'All Situations': 'همه موقعیت‌ها',
    'History of your orders or transactions': 'تاریخچه سفارشات یا معاملات شما',
    'You can enter the name and symbol of Coin, transaction ID, etc. in the search field':
      'می توانید نام و نماد کوین، شناسه تراکنش و ... را در قسمت جستجو وارد کنید.',
    Spend: '',
    'Transaction requirements': 'الزامات معامله',
    'Convert ': 'تبدیل مستقیم',

    // '1 min': '1 دقیقه',
    // '3 min': '3 دقیقه',
    // '30 min': '30 دقیقه',
    // '1 hour': '1 ساعت',
    // '24 hours': '24 ساعت',
    // '1 day': '1 روز',
    // '1 week': '1 هفته',
    // '1 month': '1 ماه',
    // '3 month': '3 ماه',

    'last 1hr': '1 ساعت گذشته',
    'last 24hr': '24 ساعت گذشته',
    'last week': 'هفته گذشته',
    'last month': 'ماه گذشته',

    'Crypto convert': 'تبدیل مستقیم کریپتو',
    'Futures Classic': 'فیوچرز کلاسیک',
    'Token Listing': ' فهرست توکن‌ها',
    'Total Sell': 'فروش کل',
    APIs: 'APIs',
    'iOS App': 'اپ iOS',
    'Android App': 'اپ اندرویدی',
    'Bank deposit': 'سپرده بانکی',
    'Credit/Debit card': 'کارت اعتباری/دبیت',
    'P2P trading': 'ترید نظیر به نظیر',
    'Crytizi Convert': 'تبدیل مستقیم کریپتیزی',
    Spot: 'اسپات',
    Margin: 'مارجین',
    Markets: 'مارکت‌ها',
    'Strategy Trading': 'استراتژی ترید',
    'About Us': 'درباره ما',
    Service: 'سرویس',
    More: 'بیشتر',
    Learn: 'یادگیری',
    Community: 'انجمن',
    About: 'درباره ما ',
    'Contact us': 'ارتباط با ما',
    Terms: 'مقررات',
    FAQ: 'سوالات متداول',
    'Token listing': 'فهرست توکن‌ها',
    'Cryptizi Convert': 'تبدیل مستقیم کریپتیزی',
    'Buy/Sell Crypto': 'خرید/فروش کریپتو',
    'USDⓈ-M Futures': 'معاملات فیوچرز USDⓈ-M',
    'Coin-M Futures': 'معاملات فیوچرز Coin-M',
    'Submit A request': 'ارسال درخواست',
    Fees: 'هزینه‌ها',
    'Trading Rules': 'قوانین ترید',
    'Browse Crypto Prices': 'یادگیری و کسب درآمد',
    'Cryptizi Academy': 'آکادمی کریپتزی',
    'Sign Up': 'ثبت نام',

    'We are the most trusted cryptocurrency platform.': 'ما قابل اعتمادترین پلتفرم ارزهای دیجیتال هستیم.',

    'View more prices': 'مشاهده قیمت‌های بیشتر',

    'Build your crypto portfolio anywhere.': 'پورتفولیو رمزنگاری خود را در هر جایی بسازید.',

    'A powerful cryptocurrency exchange in your pocket. Buy, sell and trade crypto on the go.':
      'یک صرافی قدرتمند ارز دیجیتال در جیب شماست. با امکانات خرید، فروش و تجارت رمزنگاری شده.',

    'Get the Cryptizi Wallet Mobile App Now!': 'اکنون اپلیکیشن موبایل کیف پول کریپتیزی را دریافت کنید!',

    'The most trusted cryptocurrency platform.': 'قابل اعتمادترین پلتفرم ارزهای دیجیتال.',

    'Cryptizi has a variety of features that make it the best place to start trading.':
      'کریپتیزی دارای ویژگی‌های مختلفی است که آن را به بهترین مکان برای شروع معامله تبدیل می‌کند.',

    'Buy and sell popular digital currencies, keep track of them in the one place.':
      'خرید، فروش و پیگیری ارزهای دیجیتال محبوب در یک سایت.',

    'For added security, store your funds in a vault with time delayed withdrawals.':
      'برای امنیت بیشتر، وجوه خود را به صورت برداشت با تأخیر زمانی در یک صندوق ذخیره کنید.',

    'Stay on top of the markets with the Cryptizi app for Android or iOS.':
      'با اپ اندروید و آیفون کریپتیزی در صدر بازارها بمانید.',

    'Portofolio Manager': 'مدیریت پورتفولیو',
    'Mobile Apps': 'برنامه‌های موبایل',

    'One click, instant payout with credit or debit card.': 'یک کلیک، پرداخت فوری با کارت اعتباری یا نقدی.',

    'Become a crypto owner in minutes using your debit or credit card and quickly purchase top cryptocurrencies.':
      'با استفاده از کارت نقدی یا اعتباری خود در عرض چند دقیقه صاحب ارز دیجیتال شوید و به سرعت برترین ارزهای دیجیتال را خریداری کنید.',

    'Intermediate Verification': 'احراز هویت متوسط',
    'Intermediate Verified': 'احراز هویت متوسط تایید شد.',
    'Additional Information': 'اطلاعات تکمیلی',
    OK: 'تایید',
    'Upgrade to Higher Limits': 'ارتقا به محدودیت‌های بالاتر',

    'New users can earn up to {{amount}} in crypto rewards.':
      'کاربران جدید می‌توانند تا {{amount}} در جوایز رمزنگاری کسب کنند.',

    'Ready to start trading cryptocurrency?': 'آیا برای شروع تجارت ارز دیجیتال آماده هستید؟',

    'Enter you email address': 'ایمیل خود را وارد کنید.',
    'Get Started': 'شروع ترید',

    'Your guide to the world of an open financial system. Get started with the easiest and most secure platform to buy and trade cryptocurrency.':
      'با ساده‌ترین و امن‌ترین پلتفرم برای خرید و ترید ارز، ورود به دنیای سیستم‌های مالی باز دیجیتال را شروع کنید.',

    'A trusted and secure crypto currency exchange.': 'یک صرافی ارز دیجیتال قابل اعتماد و امن.',

    'Cryptizi ©. All rights reserved.': 'Cryptizi © تمام حقوق محفوظ است ',
    'Terms Of Use': 'شرح خدمات',
    'Privacy Policy': 'قوانین و مقررات',
    Assets: 'دارایی‌ها',
    'Last price': 'آخرین قیمت',
    Change: 'تغییرات',
    Chart: 'نمودار',

    'Create an Account': 'ایجاد حساب کاربری',
    'You are not a normal user!': 'شما یک کاربر عادی نیستید!',
    'Forgot Password': 'فراموشی گذرواژه',
    Login: 'ورود',
    'Please input your username!': 'لطفا نام کاربری خود را وارد کنید!',
    'Please input your password!': 'لطفا گذرواژه خود را وارد کنید!',
    Username: 'نام کاربری',
    Password: 'گذرواژه',
    'Remember me': 'مرا به خاطر بسپار',
    "You don't have permission to login!": 'شما مجاز به ورود نیستید!',
    'Please input your first name!': 'نام خود را ورد کنید!',
    'Please input your last name!': 'نام خانوادگی خود را وارد کنید!',
    'Please input your email!': 'لطفا ایمیل خود را وارد کنید!',
    'Please input your password': 'لطفا گذرواژه خود را وارد کنید!',
    'Please input your confirm password!': 'لطفا گذرواژه خود را تایید کنید!',
    'The confirm password does not match the password': 'عدم تطابق گذرواژه',
    'You must accept the terms and conditions!': 'شما باید قوانین و ضوابط را بپذیرید!',
    "I have read and agree to Cryptizi's Terms of Service and Privacy Policy.":
      'من شرایط خدمات و خط مشی رازداری کریپتیزی را خوانده ام و با آن موافقم.',
    'Confirm Password': 'تایید رمز عبور',
    Register: 'ثبت نام',
    'First Name': 'نام ',
    'Last Name': 'نام خانوادگی',
    Email: 'ایمیل',
    'Enter the email verification code!': 'کد تایید ایمیل را وارد کنید!',
    'Enter the phone number verification code!': 'کد تایید ارسال شده به شماره تلفن را وارد کنید!',
    'Enter the authenticator code!': 'Enter the authenticator code!کد احراز هویت را وارد کنید!',
    'Enter the {{number}}-digit code send to {{email}}': 'کد {{number}} رقمی ارسال شده به {{email}} را وارد کنید',
    'Email verification code': 'کد تایید ایمیل',
    'Phone number verification code': 'کد تایید ارسال شده به شماره تلفن',
    'Enter the {{number}}-digit code send to {{phone}}': 'کد {{number}} رقمی ارسال شده به {{phone}} را وارد کنید',
    'Authenticator code': 'کد احراز هویت',

    'Enter the {{number}}-digit code from Google authenticator':
      'کد {{number}} رقمی احراز هویت گوگل را وارد کنید',

    'Passwords must have at least 8 characters and contain at least one uppercase letters, lowercase letters, number and symbol.':
      'رمز عبور باید حداقل 8 کاراکتر داشته باشد و حداقل شامل یک حرف بزرگ، کوچک، عدد و نماد باشد.',

    'You do not have a registered bank card': 'شما کارت بانکی را ثبت نکردید!',
    'Card added successfully.': 'کارت با موفقیت ثبت شد.',
    'Add a bank card': 'افزودن کارت بانکی',
    'Type of Card': 'نوع کارت',
    'Register the card': 'ثبت کارت',
    'Please input the type of your card': 'لطفا نوع کارت را مشخص کنید.',
    'Done Successfully': 'با موفقیت انجام شد.',
    'Account Number': 'شماره حساب',
    CVV2: 'CVV2',
    'Month of date': 'ماه',
    'Year of date': 'سال',
    'Card Number': 'شماره کارت',
    'Please input the type of your card!': 'لطفا نوع کارت را مشخص کنید!',
    'Please input your card number!': 'لطفا شماره کارت را وارد کنید!',
    'Please input month of date!': 'لطفا ماه را وارد کنید!',
    'Please input 2-digits number': 'لطفا عدد را به صورت دو رقمی وارد کنید.',
    'Please input year of date!': 'لطفا سال را وارد کنی!',
    'Please input CVV2!': 'لطفا CVV2 را وارد کنید!',
    'Please input your account number!': 'لطفا شماره حساب را وارد کنید!',
    'Please input your Sheba number!': 'لطفا شماره شبا را وارد کنید!',
    'Please input a valid Sheba number!': 'لطفا شماره شبا را به صورت صحیح وارد کنید!',
    'Card Holder name': 'نام دارنده کارت',
    'Expiry date': 'تاریخ انقضا',

    'To insert a bank card, fill out the form below and note that the bank card must be in your name.':
      'برای درج کارت بانکی فرم زیر را پر کنید و توجه داشته باشید که کارت بانکی باید به نام شما باشد.',

    'A trusted and secure cryptocurrency exchange.': 'یک صرافی امن و مطمئن ارزهای دیجیتال.',
    'Vault Protection': 'Vault Protection',
    Successful: 'موفقیت آمیز',
    'Learn & Earn': 'آموزش و یادگیری',
    'GET IT ON': 'شروع کنید',
    'Google Play': 'گوگل پلی',
    'Download on the': 'دانلود از طریق',
    'App Store': 'اپ استور',
    'Additional Infomation': 'اطلاعات اضافی',
    Countries: 'کشورها',
    Traders: 'تریدها',
    'Trading Pairs': 'جفت‌های معاملاتی',

    'Are you sure to remove this symbol from your favorites list?':
      'آیا مطمئنید که می‌خواهید این ارز را از لیست علاقه مندی های خود حذف کنید؟',

    Tips: 'نکات',
    'Deposit via receipt': 'واریز از طریق رسید',
    'Deposit to wallet': 'واریز به کیف پول',
    'Deposit payment gateway': 'درگاه پرداخت سپرده',
    Back: 'بازگشت',
    'Go to payment': 'درگاه پرداخت',
    Save: 'ذخیره',

    'When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is {{amount}}.':
      'هنگام تعیین مبلغ سپرده دقت کنید که حداقل مبلغ خرید و فروش در صرافی {{amount}} باشد.',

    'In order to deposit money, you must use bank cards in your own name, which are registered and approved in the user section.':
      'برای واریز وجه باید از کارت‌های بانکی به نام خود که در قسمت کاربری ثبت و تایید شده‌اند استفاده کنید.',

    'Payment is only possible through approved accounts, otherwise your payment will be unsuccessful.':
      'پرداخت فقط از طریق حساب‌های تایید شده امکان پذیر است، در غیر این صورت پرداخت شما ناموفق خواهد بود.',

    'Only for level {{level}} it is possible to deposit with a receipt.':
      'فقط برای سطح {{level}} امکان واریز با رسید وجود دارد.',

    'We believe Cryptizi is here to stay - and that a future worth building is one which opens its doors and invites everyone in.':
      'ما معتقدیم کریپتیزی در تلاش برای ساخت آینده‌ای ارزشمند است و از همه برای پیوستن دعوت می کند.',

    'Please input the repeat password!': 'لطفا گذرواژه را مجددا وارد نمایید!',
    'The repeat password does not match the password': 'گذرواژه مجدد وارد شده با گذرواژه فعلی مطابقت ندارد',
    'Repeat Password': 'تکرار گذرواژه',
    Successfully: 'با موفقیت انحام شد.',
    'Your email has been successfully verified': 'ایمیل شما با موفقیت تأیید شد.',
    'Verify Email': 'ایمیل خود را تاسسد کنید.',
    'Request Again': 'در خواست مجدد؟',
    'Didn’t recieve code?': 'هنوز کدی دریافت نکرده‌اید؟',
    'Forgot password': 'فراموشی گذرواژه',
    'The input is not a valid email!': 'ایمیل وارد شده صحیح نمی‌باشد!',
    'Reset password': 'بازیابی گذرواژه',
    'Back to login': 'بازگشت به ورود',
    'No worries, we’ll send you reset instructions':
      'نگران نباشید، ما دستورالعمل های بازیابی را برای شما ارسال خواهیم کرد',
    Submit: 'ثبت',
    Others: 'دیگران',
    'Fiat Deposit': 'سپرده فیات',
    USD: 'USD',

    'You can enter the amount, transaction ID, etc. in the search field.':
      'می توانید مبلغ، شناسه تراکنش و ... را در قسمت جستجو وارد کنید.',

    'List of Toman deposits and withdrawals': 'لیست سپرده ها و برداشت های تومانی',
    'Please input the value!': 'لطفا مقدار را وارد کنید!',
    'Please select a bank card!': 'لطفا یک کارت بانکی انتخاب کنید!',
    'You will see a list of your approved bank accounts': 'لیستی از حساب های بانکی مورد تایید خود را مشاهده می‌کنید',
    Num: 'شماره',
    Date: 'تاریخ',
    'Creation Date': 'تاریخ ساخت',
    Type: 'نوع',
    Value: 'مقدار',
    Inventory: 'فهرست',
    Condition: 'شرایط',
    Description: 'توضیحات',
    details: 'جزئیات',
    'Please input the tracking code!': 'لطفا کد رهگیری را وارد کنید!',
    'Optional and to speed up transaction confirmation time': 'اختیاری و برای سرعت بخشیدن به زمان تایید تراکنش',
    'select to deposit': 'نوع واریز را مشخص کنید',
    Payer: 'پرداخت کننده',
    'Tracking Code': 'کد رهگیری',
    'Date and time of transaction': 'تاریخ و زمان معامله',
    'How to deposit': 'نحوه واریز',
    'Receipt image (optional)': 'تصویر رسید (اختیاری)',
    Sell: 'فروش',
    USDT: 'تتر',
    'Stay tuned for more coin types!': 'منتظر انواع ارزهای بیشتر باشید!',
    'Enter Amount': 'مقدار را وارد کنید',
    'All Payment Methods': 'تمام روش های پرداخت',
    Refresh: 'رفرش',
    Advertiser: 'تبلیغ کننده',
    Price: 'قیمت',
    'Available/Limits': 'موجودی‌ها/محدودیت‌ها',
    'Payment Method': 'روش پرداخت',
    'Order(s)': 'سفارشات',
    Limits: 'محدودیت‌ها',
    'Please select the currency!': 'لطفا نوع ارز را انتخاب کنید!',
    'Please input your wallet ID!': 'لطفا شناسه کیف پول خود را وارد کنید!',

    'Complete the following information to buy digital currency': 'برای خرید ارز دیجیتال اطلاعات زیر را تکمیل کنید.',

    'Received money': 'دریافت پول',
    'Please input the value currency!': 'لطفا مقدار ارز را وارد کنید!',
    'Voucher Perfect Money': 'ووچر پرفکت مانی',
    'Price per unit of Perfect Money': 'قیمت هر واحد پرفکت مانی',
    'You can also enter the amount in tomans': 'می‌توانید مبلغ را به تومان نیز وارد کنید',
    'Enter the relevant account ID': 'شناسه حساب مربوطه را وارد کنید',
    'With description': 'با توضیحات',
    'With security code': 'با کد امنیتی',
    'Payment method': 'روش پرداخت',
    'Visa/Mastercards': 'ویزا/مستر کارت',
    'Shetab Card': 'کارت شتاب',
    'Fast Pay': 'پرداخت سریع',

    'By charging the wallet, make your payment without going to the bank portal':
      'با شارژ کیف پول، پرداخت خود را بدون مراجعه به درگاه بانک انجام دهید',

    Pay: 'پرداخت',
    'Select currency': 'واحد پول را انتخاب کنید',
    'Value currency': 'مقدار ارز',
    'Wallet ID': 'شناسه کیف پول',
    'One percent equals 0 Dollar': 'یک درصد برابر 0 دلار است',
    'The sales fee is deducted from the amount you receive': 'هزینه فروش از مبلغ دریافتی شما کسر می شود',
    'Sales fee': 'کارمزد فروش',
    'Voucher Code': 'کد کوپن',
    'Activation code': 'کد فعال سازی',
    'The purchase fee will be added to your purchase amount': 'هزینه خرید به مبلغ خرید شما اضافه می شود',
    'Purchase fee': 'کارمزد خرید',
    'cryptizi convert': 'تبدیل cryptizi',
    'Guaranteed price': 'قیمت تضمینی',
    'Any pairs': '  هر جفت ارز',
    'Zero fee': 'کارمزد صفر',
    Orders: 'سفارشات',
    From: 'از طرف',
    'Spot wallet available': 'کیف پول اسپات موجود است',
    'Please enter {{range}}': 'لطفاً {{range}} را وارد کنید',
    To: 'به',
    'Please enter 20-42000000': 'لطفا 20-42000000 را وارد کنید',
    'Enter an amount': 'مقداری را وارد کنید',
    'Select Crypto': 'Crypto را انتخاب کنید',
    'Deposit Crypto': 'Crypto سپرده گذاری کنید',
    'Withdraw Crypto': 'Crypto را برداشت کنید',
    OTC: 'OTC معاملات',
    'Please input coin!': 'لطفا نوع ارز را وارد کنید!',
    'Please input TXID!': 'لطفا هش کد را وارد کنید!!',
    'Deposit crypto': 'سپرده گذاری ارزهای دیجیتال',
    'Recent Deposit': 'آخرین سپرده',
    Bitcoin: 'بیت کوین',
    Network: 'شبکه',
    'Select Deposit network': 'شبکه ارز سپرده را انتخاب کنید',
    Copy: 'کپی',
    'Expected Arrival': 'ورودی مورد انتظار',
    '{{number}} Network Confirmations': 'تأیید شبکه {{number}}',
    'Expected Unlock': 'باز کردن قفل مورد انتظار',
    'Minimum deposit': 'حداقل سپرده',
    'Selected wallet': 'کیف پول انتخاب شده',
    'Main wallet': 'کیف پول اصلی',
    change: 'تغییر',
    'Send Only {{symbol}} to this deposit address': 'فقط {{symbol}} را به این آدرس سپرده ارسال کنید',
    'Ensure the network is': 'اطمینان حاصل کنید که شبکه:',
    'Do not send NFTs to this address.': 'از ارسال NFT به این آدرس خودداری کنید.',
    'learn how to deposit NFTs': 'یاد بگیرید که چگونه NFT ها را واریز کنید',
    submit: 'ارسال',
    'Video Tutorial': 'آموزش تصویری',
    'How to Withdraw crypto step-by-step Guide': 'راهنمای گام به گام نحوه برداشت رمزارز',
    'Why has my withdrawal Not Arrived?': 'چرا برداشت من وارد نشده است؟',
    'How to find My transaction ID (TxID)?': 'نحوه یافتن شناسه تراکنش من (TxID)؟',
    'how to recover My BEP-20 token?': 'چگونه توکن BEP-20 خود را بازیابی کنیم؟',
    'Deposit & withdrawal status query': 'استعلام وضعیت سپرده و برداشت',
    'Select coin': 'ارز را انتخاب کنید',
    TXID: 'هش کد',

    'Scan the code on the withdrawal page of the trading platform APP or wallet App':
      '  کد موجود در صفحه بازگشت پول اپلیکشن ترید یا کیف پول خود را اسکن کنید',

    'Send only {{symbol}} to this deposit address': 'Send only {{symbol}} to this deposit address',
    'Learn how to deposit NFTs': 'نحوه واریز NTFها را بیاموزید',

    'Unmatched networks automatically removed. Select a network below':
      'شبکه های بی همتا به صورت خودکار حذف شدند. یکی از شبکه های زیر را انتخاب کنید',

    SOL: 'SOL',
    Solana: 'سولانا',
    TRX: 'تی آر ایکس',
    'Tron (TRC20)': 'ترون (TRC20)',
    'Arrival time = {{min}} min': 'زمان رسیدن = {{min}} دقیقه',
    fee: 'هزینه',
    'BSC ': 'BSC ',
    'BNB Smart chain (BEP20)': 'زنجیره هوشمند BNB (BEP20)',
    'Fee rules': 'قوانین هزینه',
    'Select Network': 'Network را انتخاب کنید',
    'Crypto Address': 'آدرس کریپتو',
    Completed: 'تکمیل شد',
    'Withdraw wallet': 'برداشت کیف پول',
    'Spot Wallet': 'کیف پول نقطه‌ای',
    TxID: 'TxID',
    'View All': 'مشاهده همه',
    'Cryptizi user': 'کاربر Cryptizi',
    'Recent Withdrawals': 'برداشت های اخیر',
    'Select Withdrawal network': 'شبکه برداشت را انتخاب کنید',

    'SQl Network Withdrawal suspended. withdrawals can be processed through alternative network.':
      'برداشت شبکه SQl به حالت تعلیق درآمد. برداشت‌ها را می توان از طریق شبکه جایگزین پردازش کرد.',

    withdrawal: 'برداشت از حساب',

    Address: 'آدرس کیف پول',
    'Enter Address': 'آدرس کیف پول خود را وارد کنید',

    'USDT balance': 'موجودی USDT',
    'Network Fee': 'هزینه شبکه',
    'Minimum withdrawal': 'حداقل برداشت',
    '24h remaining limit': '24 ساعت محدودیت باقی مانده',
    'Please enter SMS verification code!': 'لطفا کد تایید پیامکی را وارد کنید!',
    Amount: 'مقدار',
    'Receive {{receive}} {{symbol}} (Network fee {{fee}} USDT)':
      'دریافت {{دریافت}} {{symbol}} (هزینه شبکه {{fee}} USDT)',
    Source: 'منابع',
    'Spot wallet': 'کیف پول نقطه‌ای',
    'Verification code sent': 'کد تایید ارسال شد',
    'security verification unavailable?': 'تأیید امنیتی در دسترس نیست؟',
    'Security verification': 'تایید امنیتی',
    'Withdrawal Request Submitted': 'درخواست برداشت ارسال شد',

    'Please note that you will receive an email once it is completed':
      'لطفا توجه داشته باشید که پس از تکمیل آن یک ایمیل دریافت خواهید کرد',

    'The receiver will get': 'گیرنده دریافت خواهد کرد',
    'Save Address': 'ذخیره آدرس',
    'One-Step Withdrawal': 'برداشت یک مرحله‌ای',

    'Enable One-Step Withdrawal to withdraw to whitelis addresses easily.':
      'برداشت یک مرحله‌ای را فعال کنید تا به راحتی به آدرس‌های لیست سفید خارج شوید.',

    'Please input your Recipient!': 'لطفاً گیرنده خود را وارد کنید!',
    'Cryptizi ID': 'شناسه Cryptizi',
    'Recipient’s email': 'ایمیل گیرنده',
    'Orders history': 'تاریخچه سفارشات',
    Recive: 'دریافت',
    'Buy Crypto with Debit / Credit Card': 'خرید کریپتو با کارت بدهی / اعتباری',
    max: 'ماکسیمم',
    'Trades History': ' تاریخچه معاملات',
    'Orders History': 'تاریخچه سفارشات',
    'Convert History': ' تاریخچه معاملات کانورت',
    'Internal Transactions': 'معاملات داخلی',
    'Futures History': 'تاریخچه معاملات فیوچرز',
    'Crypto Transactions': 'تراکنش‌های رمزارز',
    'Fiat Transactions': 'تراکنش‌های فیات',
    ALL: 'همه',
    BUY: 'خرید',
    SELL: 'فروش',
    MARKET: 'مارکت',
    LIMIT: 'محدودیت',
    'STOP LIMIT': 'محدودیت توقف',
    CANCELED: 'لغو شده',
    EXPIRED: 'منقضی شده',
    FILLED: 'پر شده',
    NEW: 'جدید',
    'PARTIALLY FILLED': 'تا حدی پر شده است',
    'PENDING CANCEL': 'در انتظار لغو',
    REJECTED: 'رد شده',
    Pair: 'جفت ارز',
    Side: 'سمت',
    Filed: 'ثبت شده',
    Commission: 'کارمزد',
    State: 'دولت',
    'Credit Card': 'کارت اعتباری',
    'Please input a valid CVV2!': 'لطفا یک CVV2 معتبر وارد کنید!',
    'Master Card': 'مستر کارت',
    'Visa Card': 'ویزا کارت',
    'Please input your old email address!': 'لطفا آدرس ایمیل قدیمی خود را وارد کنید!',
    'Change Email': 'تغییر ایمیل',
    'Please input your new email!': 'لطفا ایمیل جدید خود را وارد کنید',
    'New Email': 'New Email',
    'Email changed successfully': 'ایمیل با موفقیت تغییر کرد',
    Tickets: 'تیکت‌ها',
    Order: 'سفارشات',
    'Account Settings': 'تنظیمات حساب',
    'Settings Network': 'تنظیمات شبکه',
    Settings: 'تنظیمات',
    Users: 'کاربران',
    Admins: 'ادمین‌ها',
    'Referral marketing': 'بازاریابی ارجاعی',
    'Crypto Wallet Transactions': 'تراکنش های کیف پول رمزنگاری شده',
    'Wallet Transactions': 'معاملات کیف پول',
    'Automatic Transactions': 'معاملات خودکار',
    'List Of All Bank Cards': 'لیست همه کارت های بانکی',
    "User's": 'کاربران',
    Notification: 'نوتیفیکیشن',
    Reporting: 'گزارش گیری',
    'Tether Setting': 'تنظیمات تتر',
    'Inventory Settings': 'تنظیمات موجودی',
    'Daily Rial withdrawal ceiling': 'سقف برداشت روزانه ریالی',
    'Buy Digital Currency': 'خرید ارز دیجیتال',
    'API settings': 'تنظیمات API',
    'General settings': 'تنظیمات عمومی',
    'IP Block': 'بلاک IP',
    'User level settings': 'تنظیمات سطح کاربر',
    'Deposit With Receipt': 'واریز با رسید',
    'Popup Settings': 'تنظیمات پاپ آپ',
    'Commission settings': 'تنظیمات کمیسیون',
    'Related To Reference Account': 'مربوط به حساب مرجع',
    'Automatic Deposit settings': 'تنظیمات واریز خودکار',
    'Dedicated Gateway settings': 'تنظیمات درگاه اختصاصی',
    'All users': 'تمامی کاربران',
    'Add user': 'اضافه کردن کاربر جدبد',
    'Add Admin': 'اضلفه کردن ادمین',
    'Two-step login via Google': 'ورود دو مرحله ای از طریق گوگل',
    Activation: 'فعال سازی',
    '6-digit google code': 'کد ۶ رقمی گوگل',
    'Two-step login via Sms': 'ورود دو مرحله ای از طریق پیامک',
    'Send SMS': 'ارسال پیامک',
    'SMS Code': 'کد پیامکی',
    'Two-step login via Email': 'ورود دو مرحله ای از طریق ایمیل',
    'Send Email': 'ارسال ایمیل',
    'Email Code': 'کد ایمیل',
    'Tether price inquiry from api and automatic': 'استعلام قیمت تتر از api و اتوماتیک',
    'Settings Tether': 'تنظیمات تتر',
    Caption: 'عنوان',
    'Please select a gateway!': 'لطفا یک درگاه انتخاب کنید!',
    'Inventory settings': 'تنظیمات موجودی',
    'API status for inventory': 'وضعیت API برای موجودی',
    Cancel: 'لغو',
    'Buy digital currency': 'خرید ارز دیجیتال',
    'Sell digital currency': 'فروش ارز دیجیتال',
    'Digital currency': 'ارز دیجیتال',
    'Gateway Setting': 'تنظیمات getway',
    'Voucher Settings': 'تنظیمات کوپن',
    'Pay Safe Settings': 'تنظیمات ایمن پرداخت',
    'Perfect Money Settings': 'تنظیمات پرفکت مانی',
    'Cryptocurrency Setting': 'تنظیمات ارزهای دیجیتال',
    'Settings related to vouchers': 'تنظیمات مربوط به کوپن‌ها',
    'Purchase status': 'وضعیت خرید',
    'Sales status': 'وضعیت فروش',
    apikey: 'کلید api',
    seckey: 'کلید امنیتی',
    'Query the price of dollars from the api': 'قیمت دلار را از api استعلام کنید',
    'Purchase price in dollars': 'قیمت خرید به دلار',
    'Percentage on the price to buy': 'درصدی از قیمت برای خرید',
    'Purchase price times this number': 'قیمت خرید چند برابر این عدد',
    'Selling price in dollars': 'قیمت فروش به دلار',
    'Percentage in price for sale': 'Percentage in price for sale',
    'Selling price times this number': 'قیمت فروش برابر این عدد',

    'Percentage in the price for purchase (above one hundred tomans)': 'درصد قیمت برای خرید (بالای صد تومان)',

    'Percentage in the price for sale (above one hundred tomans)': 'درصد در قیمت فروش (بالای صد تومان)',

    'Settings related to Pay Safe Voucher': 'تنظیمات مربوط به کوپن ایمن پرداخت',
    UserName: 'نام کاربری',
    AccountID: 'شناسه حساب',
    'Purchase status of Perfect Money voucher': 'وضعیت خرید کوپن پرفکت مانی',
    'Settings related to Perfect Money': 'تنظیمات مربوط به پرفکت مانی',
    'Perfect Money Voucher sales status': 'وضعیت فروش کوپن پرفکت مانی',
    'Perfect Money Voucher': 'کوپن پول پرفکت',
    'Settings related to cryptocurrency reference account': 'تنظیمات مربوط به حساب مرجع ارز دیجیتال',
    'Binance apikey': 'کلید api بایننس',
    'Binance seckey': 'کلید امنیتی بایننس',
    'Settings Cryptocurrency': 'تنظیمات ارز دیجیتال',

    'Enter the code that was sent to your previous mobile number and email in the box below':
      'کدی که به شماره موبایل و ایمیل قبلی شما ارسال شده است را در کادر زیر وارد کنید',

    'New Email verification code': 'کد تایید ایمیل جدید',
    'Add Market': 'افزودن مارکت',
    Name: 'نام',
    Symbol: 'نماد',
    'Name Persian': 'نام فارسی',
    Selector: 'انتخاب کننده',
    'Network Status': 'وضعیت شبکه',
    'Settings network': 'شبکه تنظیمات',
    "Please input network's name!": 'لطفا نام شبکه را وارد کنید!',
    "Please input network's selector!": 'لطفا انتخابگر شبکه را وارد کنید!',
    "Please input network's persian name!": 'لطفا نام فارسی شبکه را وارد کنید!',
    "Please input network's symbol!": 'لطفا نماد شبکه را وارد کنید!',
    'Please input Minimum allowed!': 'لطفا حداقل مجاز را وارد کنید!',
    'Minimum allowed purchase': 'حداقل خرید مجاز',
    'Please input Daily purchase amount!': 'لطفا مبلغ خرید روزانه را وارد کنید!',
    'IP test mode (to disable, leave the field blank)': 'حالت تست IP (برای غیرفعال کردن، قسمت را خالی بگذارید)',

    'Deactivating purchases from the site (inserting a message in the box below means deactivating purchases from the site)':
      'غیرفعال کردن خرید از سایت (درج پیام در کادر زیر به معنی غیرفعال کردن خرید از سایت است)',

    'Daily purchase amount during registration': 'مبلغ خرید روزانه هنگام ثبت نام',
    'Deposit settings with receipt': 'تنظیمات واریز با رسید',
    'Bank name': 'نام بانک',
    'Card number': 'شماره کارت',
    'Account number': 'شماره حساب',
    'Shaba number': 'شماره شبا',
    'Please input number of days!': 'لطفا تعداد روز را وارد کنید!',
    'Popup settings': 'تنظیمات پاپ آپ',

    'After each login, it shows the password (that is, if he has not used remember me) and also shows it every few days if he has remembered me!':
      'بعد از هر بار ورود درخواست گذرواژه میکند البته اگر گزینه‌ی Remember me فعال نباشد و اگر فعال باشد بعد از چند روز دوباره درخواست رمز می‌آید.',

    Percentage: 'درصد',
    'Automatic deposit settings': 'تنظیمات واریز خودکار',
    Business: 'کسب و کار',
    'Deposit model': 'مدل سپرده',
    Mobile: 'تلفن همراه',
    'Rejection of automatic deposit for the following banks': 'رد سپرده خودکار برای بانک های زیر',
    Sat: 'شنبه',
    Sun: 'یکشنبه',
    Mon: 'دوشنبه',
    Tue: 'سه‌شنبه',
    Wed: 'چهارشنبه',
    Thu: 'پنج‌شنبه',
    Fri: 'جمعه',
    Active: 'فعال',
    Token: 'توکن',
    Inactive: 'غیر فعال',
    'Iran GATEWAY': 'درگاه ایران',
    'Contract text': 'متن قرارداد',
    'SMS confirmation for withdrawal and purchase': 'پیامک تایید برداشت و خرید',
    'International GATEWAY': 'درگاه بین المللی',
    'Iranian payment gateways Appearance': 'درگاه های پرداخت ایرانی ظاهر',
    'Minimum wallet withdrawal amount': 'حداقل مبلغ برداشت کیف پول',
    'Minimum amount of currency sales': 'حداقل میزان فروش ارز',
    'Dedicated  Gateway settings': 'تنظیمات درگاه اختصاصی',
    disable: 'غیر فعال',
    active: 'فعال',

    'This is the agreement between Iranianex.com and the user who uses the various services of the site. All users of Iranianex.com should read these rules completely and then become a member of the site if they accept them. Iranianex.com can update the terms of this agreement every month according to the changes in the laws. Registering on this site, using its services such as buying and selling, etc., means that you have read and accepted the terms of this contract. The user accepts that he is the legal owner.This is the agreement between Iranianex.com and the user who uses the various services of the site. ':
      'این قرارداد بین Iranianex.com و کاربری است که از خدمات مختلف سایت استفاده می کند. تمامی کاربران ایرانیانکس دات کام باید این قوانین را به طور کامل مطالعه کرده و در صورت قبولی در سایت عضو شوند. Iranianex.com می تواند طبق تغییرات قوانین هر ماه مفاد این قرارداد را به روز کند. ثبت نام در این سایت، استفاده از خدمات آن مانند خرید و فروش و ... به این معنی است که شما شرایط این قرارداد را مطالعه کرده و پذیرفته اید. کاربر می پذیرد که مالک قانونی خود است. این توافق نامه بین Iranianex.com و کاربری است که از خدمات مختلف سایت استفاده می کند.',

    'Add New Admin': 'افزودن ادمین جدید',
    Admin: 'ادمین',
    Block: 'بلاک',
    Edit: 'ویرایش',
    Delete: 'حذف',
    'List of accounts': 'لیست حساب ها',
    'Crypto transactions': 'تراکنش های رمزنگاری شده',
    'Bank cards': 'کارت های بانکی',
    Log: 'لاگ',
    'Toman transactions': 'معاملات تومان',
    'Please input name!': 'لطفا نام را وارد کنید!',
    'Please input mobile!': 'لطفا شماره موبایل را وارد کنید!',
    'Please input email!': 'لطفا ایمیل را وارد کنید!',
    'Please input password!': 'لطفا گذر واژه را وارد کنید!',
    'Add new admin': 'ادمین جدید اضافه کنید',
    Deactive: 'غیر فعال',
    'Delete user': 'حذف کاربر',
    Access: 'دسترسی',
    'Two-step login': 'ورود دو مرحله ای',
    'Upload photo': 'بارگذاری عکس',
    'Change photo': 'تغییر عکس',
    Notifications: 'اطلاعیه ها',
    'Wallet deposit and withdrawal': 'واریز و برداشت کیف پول',
    Filter: 'فیلتر',
    'View transactions': 'مشاهده معاملات',
    'Edit Admin': 'ویرایش ادمین',
    'Wallet transactions': 'معاملات کیف پول',

    'User wallet transactions that have been changed by this admin':
      'تراکنش های کیف پول کاربر که توسط این ادمین تغییر کرده است',

    Bank: 'بانک',
    'Amount of': 'مقداری از',
    Currency: 'واحد پول',
    Status: 'وضعیت',
    Detail: 'جزئیات',
    'Cryptocurrency wallet transactions': 'تراکنش های کیف پول ارزهای دیجیتال',
    Regarding: 'با توجه به',
    'Business transactions': 'معاملات کسب و کار',
    'Transactions of a transaction': 'معاملات یک معامله',
    'ID transaction': 'شناسه معامله',

    'You can see the cryptocurrency wallet transactions that the admin has changed':
      'می‌توانید تراکنش‌های کیف پول ارزهای دیجیتال را که ادمین تغییر کرده است، ببینید',

    'The list of users whose document image has been changed by this admin.':
      'لیست کاربرانی که تصویر سندشان توسط این مدیر تغییر کرده است.',

    Level: 'مرحله',
    'Add admin': 'ادمین اضافه کنید',
    'New Admin': 'ادمین جدید',

    'You can not disable a port that is in the default state.':
      'شما نمی توانید پورتی را که در حالت پیش فرض است غیرفعال کنید.',

    'Order type': 'نوع سفارش',
    Tether: 'تتر',
    User: 'کاربر',
    'Transfer fee': 'هزینه نقل و انتقال',
    network: 'شبکه',
    'The amount': 'مقدار',
    Close: 'بستن',
    'Address wallet': 'آدرس کیف پول',
    'Displaying saved parameters (Click)': 'نمایش پارامترهای ذخیره شده (کلیک کنید)',
    'Transactions Details': 'جزئیات معاملات',
    Success: 'موفقیت آمیز',
    Failed: 'ناموفق',

    'You can see the total number of records on the current page, and for the total number of all displaysl':
      'می توانید تعداد کل رکوردها را در صفحه فعلی و تعداد کل همه نمایشگرها را مشاهده کنید',

    'Account list': 'لیست حساب',
    'Group payment': 'پرداخت گروهی',
    "Sheba's list": 'لیست شبا',
    'You can see the wallet transactions of users.': 'می توانید تراکنش های کیف پول کاربران را مشاهده کنید.',
    View: 'دیدگاه',
    'The transfer encountered an error': 'انتقال با خطا مواجه شد',
    'Transaction number': 'شماره معامله',
    'Transaction amount': 'مقدار تراکنش',
    IP: 'آی پی',
    Done: 'انجام شد',
    New: 'جدید',
    'Your Notifications': 'اطلاعیه های شما',
    'View All Notifications': 'مشاهده همه اعلان‌ها',
    'Sort by': 'مرتب سازی بر اساس',
    'last 2 week': '2 هفته گذشته',
    'No Data': 'اطلاعاتی جهت وجود ندارد',
    Dollar: 'دلار',
    'Payment unit': 'واحد پرداخت',
    'Done successfully': 'با موفقیت انجام شد.',
    'Voucher code': 'کد ووچر',
    MAX: 'بالاترین',
    'Select Coin': 'سکه را انتخاب کنید',
    'Main Wallet': 'کیف پول اصلی',
    'Funding Wallet': 'کیف پول تامین مالی',
    'Select Wallet': 'کیف پول را انتخاب کنید',
    'Please select a coin firstly!': 'لطفا ابتدا یک سکه انتخاب کنید!',
    'Enter Tag': 'برچسب را وارد کنید',
    'Add New Address': 'اضافه کردن آدرس جدید',
    'Select from Address Book': 'از کتاب آدرس انتخاب کنید',
    Phone: 'تلفن',
    balance: 'حساب',
    'Receive {{receive}} {{symbol}}': 'دریافت {{receive}} {{symbol}}',
    'Top gainer (24h)': 'افزایش دهنده برتر (24 ساعت)',
    'Highest volume (24h)': 'بالاترین حجم (24 ساعت)',
    'Most Visited': 'بیشترین بازدید',
    'New listing': 'فهرست جدید',
    '24h Change': 'تغییر 24 ساعته',
    '24h Volume': 'حجم 24 ساعته',
    'Market Cap': 'ارزش بازار',
    'Crypto Market': 'بازار کریپتو',
    'If you need help, watch the guide video in this section':
      'اگر به کمک نیاز دارید، ویدیوی راهنما را در این بخش تماشا کنید',
    'Watch the movie': 'فیلم را تماشا کنید',
    'Your Share': 'سهم شما',
    'Share friends': 'دوستان را به اشتراک بگذارید',
    Reports: 'گزارش‌ها',
    'List of trades in your subset': 'لیست معاملات زیر مجموعه شما',
    'Your income chart': 'نمودار درآمد شما',
    'List of introduced users': 'لیست کاربران معرفی شده',
    'Link and introduction code': 'لینک و کد معرفی',
    'By providing a link or referral code to your friends, you can earn money from their transaction fees and also allocate a part of this income to them, which is both for their benefit and for your benefit.':
      'با ارائه لینک یا کد معرفی به دوستان خود می توانید از کارمزد تراکنش آنها کسب درآمد کنید و همچنین بخشی از این درآمد را به آنها اختصاص دهید که هم به نفع آنهاست و هم به نفع شما.',
    'Set percentage for submissions': 'تعیین درصد برای ارسال',
    '30% of your friends transaction fee is provided for you and by setting this section you can divide your income among the people you have introduced.':
      '30 درصد از کارمزد تراکنش دوستان برای شما در نظر گرفته شده است و با تنظیم این قسمت می توانید درآمد خود را بین افرادی که معرفی کرده اید تقسیم کنید.',
    Number: 'شماره',
    Details: 'جزئیات',
    'Add Ticket': 'اضافه کردن تیکت',
    Subject: 'موضوع',
    'Please input section!': 'لطفا بخش را وارد کنید!',
    'Please input description!': 'لطفا توضیحات را وارد کنید!',
    Finance: 'مالی',
    Technical: 'تکنیکال',
    KYC: 'KYC',
    'Name Ticket': 'نام تیکت',
    Section: 'بخش',
    'History of your trades': 'تاریخچه معاملات شما',
    Time: 'زمان',
    'History of your transactions': 'تاریخچه معاملات شما',
    'Past 30 days': '30 روز گذشته',
    'Past 90 days': '90 روز گذشته',
    Credited: 'اعتبار داده شده است',
    Pending: 'در حال انتظار',
    'Past 7 days': '7 روز گذشته',
    Asset: 'دارایی',
    'Enter TxID': 'TxID را وارد کنید',
    'Select Asset': 'Asset را انتخاب کنید',
    Destination: 'مقصد',
    Failure: 'عدم موفقیت',
    Canceled: 'لغو شد',
    'Deposit ID': 'شناسه سپرده',
    'Please input your old Email address!': 'لطفا آدرس ایمیل قدیمی خود را وارد کنید!',
    'Fiat Withdraw': 'برداشت فیات',
    'Withdrawal of wallet': 'برداشت از کیف پول',
    'Your recoverable inventory:': 'موجودی قابل بازیابی شما:',
    'Equivalent daily withdrawal amount: 0 out of 500,000,000 Tomans':
      'معادل مبلغ برداشت روزانه: 0 از 500,000,000 تومان',
    'When determining the deposit amount, make sure that the minimum purchase and sale amount in the exchange is $ 11.':
      'هنگام تعیین مبلغ سپرده دقت کنید که حداقل مبلغ خرید و فروش در صرافی 11 دلار باشد.',
    'Bank account to receive': 'حساب بانکی برای دریافت',
    'Buy & Sell': 'خرید و فروش',
    'All Model': 'همه مدل',
    Management: 'مدیریت',
    'Account Setting': 'تنظیمات حساب',
    Profile: 'پروفایل',
    'Waiting Actions': 'اقدامات انتظار',
    'Admin Utilities': 'ابزارهای مدیریت',
    Title: 'عنوان',
    Today: 'امروز',
    Yesterday: 'دیروز',
    'This week': 'این هفته',
    'This month': 'این ماه',
    'This Year': 'امسال',
    'Total value': 'ارزش کل',
    Sales: 'حراجی',
    'System status': 'وضعیت سیستم',
    Website: 'وبسایت',
    'PaySafe Voucher': 'کوپن PaySafe',
    PMvoucher: 'PMvoucher',
    'Name Currency': 'نام ارز',
    Sale: 'فروش',
    Stock: 'موجودی',
    'Amount of orders': 'مقدار سفارشات',
    'Sales number': 'شماره فروش',
    'Selling price': 'قیمت فروش',
    Purchases: 'خریدها',
    'Purchase amount': 'مبلغ خرید',
    'New User': 'کاربر جدید',
    'Most sales': 'بیشترین فروش',
    'Most purchases': 'اکثر خریدها',
    'Users waiting for Verify': 'کاربرانی که منتظر تأیید هستند',
    'Fiat Transaction Waiting': 'در انتظار معامله فیات',
    Reset: 'بازنشانی',
    'You can see the list of users whose information must be confirmed.':
      'می توانید لیست کاربرانی که اطلاعات آنها باید تایید شود را مشاهده کنید.',
    'Edit / Delete': 'ویرایش / حذف',
    Basic: 'پایه',
    'Crypto wallet': 'کیف پول کریپتو',
    'Fiat wallet': 'کیف پول فیات',
    'Crypto Transaction': 'تراکنش کریپتو',
    Cards: 'کارت‌ها',
    'Trade history': 'تاریخ معاملات',
    'Earn ': 'بدست آوردن ',
    'please fill the blank': 'لطفا جای خالی را پر کنید',
    Other: 'دیگران',
    Intermediate: 'متوسط',
    Advanced: 'پیشرفته',
    'Send Form': 'ارسال فرم',
    'Middle Name': 'نام میانی',
    Country: 'کشور',
    'Street Address': 'آدرس خیابان',
    birthDate: 'تاریخ تولد',
    'Postal Code': 'کد پستی',
    City: 'شهر',
    'Phone Number': 'شماره تلفن',
    Approve: 'تایید',
    Reject: 'رد کردن',
    'Send Notification': 'ارسال اطلاعیه',
    'Wallet’s User': 'کاربر کیف پول',
    'Total inventory': 'کل موجودی',
    'Equivalent to Dollar': 'معادل دلار',
    'Inventory available': 'موجودی موجود است',
    'Create a transaction': 'یک تراکنش ایجاد کنید',
    'Add transaction': 'اضافه کردن تراکنش',
    Error: 'خطا',
    'Edit User': 'ویرایش کاربر',
    Create: 'ايجاد كردن',
    'Update photo': 'به روز رسانی عکس',
    'Value Transaction': 'ارزش معامله',
    'Account Transaction': 'تراکنش حساب',
    'Add Card': 'اضافه کردن کارت',
    'Add card': 'اضافه کردن کارت',
    Shaba: 'شبا',
    Waiting: 'در انتظار',
    Message: 'پیام',
    Payment: 'پرداخت',
    Port: 'درگاه',
    'Order value': 'ارزش سفارش',
    success: 'موفقیت آمیز',
    Earn: 'بدست آوردن',
    'List of users that this user has introduced': 'لیست کاربرانی که این کاربر معرفی کرده است',
    'Value Order': 'ارزش سفارش',
    'User commission': 'کمیسیون کاربر',
    'ID card': 'کارت شناسایی',
    Passport: 'پاسپورت',
    'Upload .PDF/.JPG/.JPEG/.PNG file and no more than 5M': 'فایل .PDF/.JPG/.JPEG/.PNG و حداکثر 5M بارگذاری کنید',
    'Select your Country / region': 'کشور / منطقه خود را انتخاب کنید',
    'Change level': 'تغییر سطح',
    'Change password': 'گذرواژه را تغییر دهید',
    'Representative mobile': 'نماینده موبایل',
    'List of all bank cards': 'لیست تمام کارت های بانکی',
    'To edit, delete or approve or disapprove a bank card, click on the link to be redirected to the bank cards section in the users profile.':
      'برای ویرایش، حذف یا تایید یا رد یک کارت بانکی، روی لینک کلیک کنید تا به قسمت کارت های بانکی در پروفایل کاربران هدایت شوید.',
    'Edit card': 'ویرایش کارت',
    'Edit Card': 'ویرایش کارت',
    'Fiat transactions': 'تراکنش فیات',
    'Edit admin': 'ویرایش ادمین',
    'API Setting': 'تنظیمات API',
    'Network Setting': 'تنظیمات شبکه',
    Referrals: 'ارجاعات',
    'Dollar balance': 'موجودی دلار',
    'Rial balance': 'موجودی ریال',
    'Percentage in the price for ( marketing )': 'درصد در قیمت برای ( بازاریابی )',
    'Perfect Money ': 'پرفکت مانی ',
    'Percentage in the price for purchase': 'درصد در قیمت برای خرید',
    'Percentage in the price for sale': 'درصد در قیمت فروش',
    'Network Name': 'نام شبکه',
    'General Settings': 'تنظیمات عمومی',
    'Type ...': 'نوع ...',
    "Account Holder's name": 'نام دارنده حساب',
    Vandar: 'Vandar',
    PIR: 'PIR',
    Zibal: 'Zibal',
    'Add User': 'افزودن کاربر',
    BASIC: 'پایه',
    Disapproval: 'عدم پذیرش',
    REGISTERED: 'ثبت شده',
    BASIC_BY_ADMIN: 'پایه توسط ادمین',
    INTERMEDIATE_REQUESTED: 'متوسط درخواست شده است',
    ADVANCED_BY_ADMIN: 'پیشرفته توسط ادمین',
    INTERMEDIATE_BY_ADMIN: 'متوسط توسط ادمین',
    ADVANCED_REQUESTED: 'درخواست پیشرفته',
    Google: 'Google',
    LastName: 'نام خانوادگی',
    Sms: 'پیامک',
    'Father Name': 'نام پدر',
    'National Number': 'شماره ملی',
    'Birth Date': 'تاریخ تولد',
    'Login Date': 'تاریخ ورود',
    Verification: 'تایید',
    Tell: 'گفتن',
    'Add New Card': 'اضافه کردن کارت جدید',
    Support: 'پشتیبانی',
    caption: 'عنوان',
    'List of commission payments to the wallet': 'لیست پرداخت کمیسیون به کیف پول',
    'Referral Marketing': 'بازاریابی ارجاعی',
    waiting: 'در انتظار',
    'Transfer completed successfully': 'انتقال با موفقیت انجام شد',
    'All User': 'تمامی کاربران',
    'Perfect Money buyers/sellers': 'خریداران/فروشندگان پرفکت مانی',
    'CryptoCurrency buyers/sellers': 'خریداران/فروشندگان کریپتوکارنسی',
    'Paysafe buyers/sellers': 'خریداران/فروشندگان Paysafe',
    'Vouchers buyers/sellers': 'خریداران/فروشندگان کوپن',
    'Referral Users': 'کاربران ارجاعی',
    'Verified Users': 'کاربران تایید شده',
    'Users Who hasnt trade yet': 'کاربرانی که هنوز معامله نکرده اند',
    'This category does not apply to header alerts and shows for everyone':
      'این دسته برای هشدارها و نمایش‌های سرصفحه برای همه اعمال نمی‌شود',
    'The title does not apply to sms': 'عنوان برای پیامک صدق نمی کند',
    'Unverified Users': 'کاربران تایید نشده',
    'Notification type': 'نوع اعلان',
    'Users whos Google Authenticator is inactive': 'کاربرانی که Google Authenticator غیرفعال است',
    'Users whos SMS Authenticator is inactive': 'کاربرانی که SMS Authenticator غیرفعال است',
    'Basic level Users': 'کاربران سطح پایه',
    'Intermediate Users': 'کاربران متوسط',
    'Advanced Users': 'کاربران پیشرفته',
    'User Statistics': 'User Statistics',
    'Open trades': 'معاملات باز',
    'Futures Open position': 'موقعیت باز فیوچرز',
    'Deposit Statistics': 'آمار سپرده',
    'Crypto Statistics': 'آمار رمزنگاری',
    'Successful Orders': 'معاملات موفق',
    'Withdraw Statistics': 'آمار برداشت',
    'Unsuccessful Orders': 'معامله ناموفق',
    '12 H': '12 ساعت',
    '1 Day': '1 روز',
    '1 Week': '1 هفته',
    '1 Months': '1 ماه',
    '1 Years': '1 سال',
    'last day': 'روز گذشته',
    'Buy/Sell': 'خرید فروش',
    Desktop: 'دسکتاپ',
    Select: 'انتخاب کنید',
    Report: 'گزارش',
    'Deactivate user': 'کاربر غیرفعال',
    'Active user': 'کاربر فعال',
    Excel: 'Excel',
    PDF: 'PDF',
    Gateway: 'درگاه',
    'Post New Ad': 'ارسال آگهی جدید',
    Remainder: 'باقیمانده',
    'Ads List': 'لیست آگهی‌ها',
    Post: 'ارسال',
    'Total Amount': 'مجموع مبلغ',
    'With Fiat': 'با فیات',
    'Minimum order limit': 'حداقل محدودیت سفارش',
    'Maximum order limit': 'حداکثر محدودیت سفارش',
    'Please select the type!': 'لطفاً نوع را انتخاب کنید!',
    'Perfect Money': 'پرفکت‌مانی',
    'Voucher PaySafe': 'واچر پی‌سیف',
    'You can also enter the amount': 'شما همچنین می‌توانید مقدار را وارد کنید',
    'Date & Time': 'تاریخ و زمان',
    'Serial number': 'شماره سریال',
    Continue: 'ادامه',
    Hot: 'داغ',
    'Choose Payment method': 'روش پرداخت را انتخاب کنید',
    Previous: 'قبلی',
    Confirm: 'تأیید',
    'History of your orders': 'تاریخچه سفارش‌های شما',
    Fiat: 'فیات',
    'P2P History': 'تاریخچه P2P',
    'History of your P2P orders': 'تاریخچه سفارش‌های P2P شما',
    TAKE: 'گرفتن',
    MAKE: 'ساختن',
    COMPLETED: 'تکمیل شده',
    FAILED: 'ناموفق',
    PARTIALLY_COMPLETED: 'تا حدودی تکمیل شده',
    PENDING: 'در انتظار',
    'Please input your oldMobile!': 'لطفاً موبایل قدیمی خود را وارد کنید!',
    'Please input your new mobile!': 'لطفاً موبایل جدید خود را وارد کنید!',
    'Change Mobile': 'تغییر موبایل',
    'New Mobile': 'موبایل جدید',
    'Please input your oldEmailAddress!': 'لطفاً آدرس ایمیل قدیمی خود را وارد کنید!',
    'Paysafe total value': 'مجموع ارزش پی‌سیف',
    'Perfect Money total value': 'مجموع ارزش پرفکت‌مانی',
    'Tether total value': 'مجموع ارزش تتر',
    'Automatic Deposit History': 'تاریخچه واریز اتوماتیک',
    'Sheba number': 'شماره شبا',
    Unblock: 'آزاد کردن',
    undefined: 'تعریف نشده',
    Approved: 'تأیید شده',
    Rejectd: 'رد شده',
    'You can see the list of commissions received by this user through introduction.':
      'شما می‌توانید لیست کمیسیون‌های دریافتی توسط این کاربر از طریق معرفی را ببینید.',
    INTERMEDIATE: 'میانی',
    ADVANCED: 'پیشرفته',
    Family: 'خانواده',
    'Please input first name!': 'لطفاً نام خود را وارد کنید!',
    'Please input email address!': 'لطفاً آدرس ایمیل خود را وارد کنید!',
    'Please input last name!': 'لطفاً نام خانوادگی خود را وارد کنید!',
    "If you don't decide to change the password, leave this field blank":
      'اگر تصمیم به تغییر رمز عبور ندارید، این فیلد را خالی بگذارید',
    'Access Denied': 'دسترسی ممنوع',
    'Fixed panel page header': 'سربرگ صفحه پنل ثابت',
    'By notification': 'توسط اطلاعیه',
    'Header panel pages only once': 'سربرگ صفحه‌های پنل تنها یک بار',
    'by Email': 'توسط ایمیل',
    'Via SMS': 'توسط پیامک',
    Desktap: 'رایانه',
    'Deactive user': 'غیرفعال کردن کاربر',
    'Buy Crypto': 'خرید رمزارز',
    'Contact Us': 'تماس با ما',
    'Privacy policy': 'سیاست حفظ حریم خصوصی',
    'E-money': 'پول الکترونیک',
    Verify: 'تأیید',
    Announcement: 'اعلان',
    API: 'واسط برنامه‌نویسی (API)',
    'OTC Terms': 'شرایط OTC',
    'Convert Terms': 'شرایط تبدیل',
    'Please enter {{min}}-{{max}}': 'لطفاً {{min}}-{{max}} را وارد کنید',
    Favorites: 'علاقه‌مندی‌ها',
    'All Cryptos': 'تمام رمزارزها',
    'Spot Markets': 'بازارهای نقدی',
    'Futures Markets': 'بازارهای آتی',
    'New Markets': 'بازارهای جدید',
    Metaverse: 'متاورس',
    Gaming: 'گیمینگ',
    Defi: 'دیفای',
    Innovation: 'نوآوری',
    'Layer1/layer2': 'لایه 1/لایه 2',
    'Fan Toket': 'توکن هوادار',
    NFT: 'توکن‌های غیرقابل تعویض',
    Storage: 'ذخیره‌سازی',
    Palkadot: 'پلکادات',
    POS: 'ثابت‌وزن (POS)',
    POW: 'اختیار محاسبه‌گر (POW)',
    '1 Month': '1 ماه',
    Symbols: 'نمادها',
    '1 Year': '1 سال',
    Open: 'باز کردن',
    Remove: 'حذف',
    FINANCE: 'مالی',
    TECHNICAL: 'تکنیکال',
    'Close ticket': 'بستن تیکت',
    'Updated Date': 'آخرین فعالیت',
    'Type a message': 'پیامی بنویسید',
    'How satisfying it was ?': 'چقدر راضی بودید؟',
    'Ticket closed': 'تیکت بسته شد',
    'Your current Limit': 'محدودیت فعلی شما',
    'Switch to enterprise Account': 'تغییر به حساب شرکتی',
    'Video Guide': 'راهنمای ویدیویی',
    'Personal Information': 'اطلاعات شخصی',
    'Crypto Deposit': 'واریز ارز دیجیتال',
    Unlimited: 'نامحدود',
    'Most Popular': 'محبوب‌ترین',
    'All basic Requirements': 'تمامی نیازهای اساسی',
    'Government ID': 'شناسنامه دولتی',
    'Facial Verification': 'تایید چهره',
    'Review time': 'زمان بررسی',
    days: 'روزها',
    'Fiat Deposit & Withdrawal Limit': 'محدودیت واریز و برداشت پول Fiat',
    Daily: 'روزانه',
    'P2P Transaction Limits': 'محدودیت معاملات P2P',
    'Crypto Withdraw': 'برداشت ارز دیجیتال',
    'Highest Fiat Limit': 'بالاترین محدودیت Fiat',
    'Proof of Address': 'اثبات آدرس',
    'Quick & Easy': 'سریع و آسان',
    WAITING: 'در انتظار',
    'Advanced requested': 'درخواست پیشرفته',
    'Intermediate requested': 'درخواست میانی',
    ACTIVE: 'فعال',
    'Intermediate by admin': 'میانی توسط مدیر',
    'Advanced by admin': 'پیشرفته توسط مدیر',
    Registered: 'ثبت‌نام شده',
    'Select Approve or Reject status': 'وضعیت تایید یا رد را انتخاب کنید',
    Blocked: 'مسدود شده',
    'USDT Value': 'ارزش USDT',
    'USD Value': 'ارزش USD',
    'Please fill the blank': 'لطفاً جای خالی را پر کنید',
    'Please select one': 'لطفاً یکی را انتخاب کنید',
    'ID Card': 'کارت شناسایی',
    'User Image': 'تصویر کاربر',
    'Country / region': 'کشور / منطقه',
    'The user reject successfully': 'کاربر با موفقیت رد شد',
    'Advanced Verification': 'تایید پیشرفته',
    'Confirm Residential Address': 'تایید آدرس مسکونی',
    'Please choose and upload one of the following documents': 'لطفاً یکی از اسناد زیر را انتخاب کرده و آپلود کنید',
    'Utility bill (electricity, gaz. water/sewage, fibre/broadband service)':
      'صورتحساب خدمات اساسی (برق، گاز، آب/فاضلاب، خدمات فیبر/بازپهنایی)',
    'Select Photo': 'انتخاب عکس',
    'Upload .JPG/.JPEG/.PNG file and no more than 5M': 'آپلود فایل .JPG/.JPEG/.PNG و حداکثر 5 مگابایت',
    'Bank statement': 'گزارش بانکی',
    'Basic Verification': 'تایید اساسی',
    'Identity Information': 'اطلاعات هویتی',
    Nationality: 'تابعیت',
    'Select your nationality': 'تابعیت خود را انتخاب کنید',
    'date of birth': 'تاریخ تولد',
    'Please input your street address!': 'لطفاً آدرس خود را وارد کنید!',
    'Please input your city!': 'لطفاً شهر خود را وارد کنید!',
    'Please input your phone number!': 'لطفاً شماره تلفن خود را وارد کنید!',
    'Please input your postal code!': 'لطفاً کد پستی خود را وارد کنید!',
    'Use a valid government-issued document': 'از یک مدرک دولتی معتبر استفاده کنید',
    'Only the following documents listed below will be accepted': 'تنها اسناد زیر پذیرفته می‌شود',
    'Id Card': 'کارت شناسایی',
    'Government-issued': 'صادره از دولت',
    'place documents against a single-colored background': 'مدارک را در برابر پس‌زمینه یک رنگ قرار دهید',
    'Readable, well-lit, colored images': 'تصاویر خوانا، خوش روشن و رنگی',
    'Original Full-size, unedited documents': 'مدارک اصلی در اندازه کامل و بدون ویرایش',
    'No black and white images': 'بدون تصاویر سیاه و سفید',
    'No edited or expired documents': 'بدون مدارک ویرایش شده یا منقضی',
    'file size must be between 10kb and 512kb in jpg/jpeg/png format':
      'سایز فایل باید بین 10 کیلوبایت و 512 کیلوبایت باشد و در فرمت jpg/jpeg/png باشد',
    'Basic information': 'اطلاعات اصلی',
    'Change Password': 'تغییر رمز عبور',
    'Two-step verification': 'تأیید دو مرحله‌ای',
    'Change email': 'تغییر ایمیل',
    'Receive SMS after transactions': 'دریافت پیامک پس از معاملات',
    'Receive notifications after transactions (Guide)': 'دریافت اعلان‌ها پس از معاملات (راهنما)',
    Activities: 'فعالیت‌ها',
    'Receive SMS after each login': 'دریافت پیامک پس از هر ورود',
    'Receive emails after transactions': 'دریافت ایمیل‌ها پس از معاملات',
    'Receive emails after each login': 'دریافت ایمیل‌ها پس از هر ورود',
    'User ID': 'شناسه کاربری',
    'Registration via : Website': 'ثبت‌نام از طریق: وب‌سایت',
    'Registration Date': 'تاریخ ثبت‌نام',
    'Save Changes': 'ذخیره تغییرات',
    Default: 'پیشفرض',
    'Please input a valid oldEmailAddress': 'لطفاً یک آدرس ایمیل قدیمی معتبر وارد کنید',
    'Please input a valid email': 'لطفاً یک آدرس ایمیل معتبر وارد کنید',
    'GO BACK': 'بازگشت',
    'Total Buy': 'مجموع خرید',
    'Total Referrals': 'مجموع معرفی‌ها',
    'Total Tickets': 'مجموع تیکت‌ها',
    'Total Transactions': 'مجموع معاملات',
    'Highest Buy': 'بالاترین خرید',
    'Highest Sell': 'بالاترین فروش',
    'Total Tade': 'مجموع تراکنش',
    'Total Order': 'مجموع سفارش',
    'Total Fiat Income': 'مجموع درآمد Fiat',
    'Block user': 'مسدود کردن کاربر',
    'Intermediate user': 'کاربر میانی',
    'Advanced user': 'کاربر پیشرفته',
    items: 'موارد',
    'Close By Admin': 'بسته شده توسط مدیر',
    'All tickets': 'تمام تیکت‌ها',
    'Ticket Title': 'عنوان تیکت‌',
    'Pending tickets': 'تیکت‌های در انتظار',
    'Total messages': 'مجموع پیام‌ها',
    'Open tickets ': 'تیکت‌های باز',
    'Please input dollar limit amount!': 'لطفاً مقدار محدودیت دلاری را وارد کنید!',
    'Please input rial limit amount!': 'لطفاً مقدار محدودیت ریالی را وارد کنید!',
    'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.':
      'برای فعال‌سازی، ابتدا روی دکمه ارسال پیامک کلیک کرده و یک پیامک حاوی کد فعال‌سازی به شما ارسال می‌شود، سپس کد را وارد کرده و دکمه فعال‌سازی را بزنید.',
    'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.':
      'برای فعال‌سازی، ابتدا روی دکمه ارسال ایمیل کلیک کرده و یک کد به ایمیل شما ارسال می‌شود، سپس کد را وارد کرده و دکمه فعال‌سازی را بزنید. ممکن است ایمیل در پوشه (هرزنامه / اسپم) شما دریافت شده باشد.',
    'To activate this feature, follow these steps:': 'برای فعال‌سازی این ویژگی، مراحل زیر را دنبال کنید:',
    '2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program.':
      '2. پس از نصب و اجرای برنامه Google Authenticator به یکی از روش‌های زیر، کلید را به برنامه اضافه کنید.',
    '- Enter a provided key: Select this option and enter the following code carefully.':
      '- کد ارائه شده را وارد کنید: این گزینه را انتخاب کرده و کد زیر را با دقت وارد کنید.',
    '3. Enter the received code (6-digit number) in the box below and click the activate button.':
      '3. کد دریافتی (6 رقمی) را در جعبه زیر وارد کرده و دکمه فعال‌سازی را بزنید.',
    '1. Get the latest version of Google Authenticator from Google Play or Apple Store':
      '1. آخرین نسخه Google Authenticator را از Google Play یا Apple Store دریافت کنید',
    '- Scan a barcode: Select this option and scan the following barcode.':
      '- اسکن کد توسط دوربین: این گزینه را انتخاب کرده و بارکد زیر را اسکن کنید.',
    WalletID: 'شناسه کیف پول',
    'All Networks Status': 'وضعیت تمام شبکه‌ها',
    'Networks Status': 'وضعیت شبکه‌ها',
    UnVerified: 'تایید نشده',
    'User level settings can be changed from this section, and please change the settings of this section carefully.':
      'تنظیمات سطح کاربر از این بخش قابل تغییر است، لطفاً تنظیمات این بخش را با دقت تغییر دهید.',
    'Please input withdrawal of cryptocurrency!': 'لطفاً مقدار برداشت ارز دیجیتال را وارد کنید!',
    'Please input withdrawal of fiat!': 'لطفاً مقدار برداشت Fiat را وارد کنید!',
    'Please input buying digital money (equivalent to dollar)!':
      'لطفاً مقدار خرید ارز دیجیتال (معادل دلار) را وارد کنید!',
    'Please input buying digital money (equivalent to rial)!':
      'لطفاً مقدار خرید ارز دیجیتال (معادل ریال) را وارد کنید!',
    'Please input fees for buy cryptocurrency!': 'لطفاً مقدار هزینه برای خرید ارز دیجیتال را وارد کنید!',
    'Please input fees for sell cryptocurrency!': 'لطفاً مقدار هزینه برای فروش ارز دیجیتال را وارد کنید!',
    'Please input trade maker fee!': 'لطفاً مقدار هزینه تولید کننده تراکنش را وارد کنید!',
    'Please input futures maker fee!': 'لطفاً مقدار هزینه تولید کننده معاملات آینده را وارد کنید!',
    'Please input trade taker fee!': 'لطفاً مقدار هزینه تاکر تراکنش را وارد کنید!',
    'Please input futures taker fee!': 'لطفاً مقدار هزینه تاکر معاملات آینده را وارد کنید!',
    'Please input internal transaction fee!': 'لطفاً مقدار هزینه تراکنش داخلی را وارد کنید!',
    'Please input OTC minimum buy!': 'لطفاً حداقل مقدار خرید OTC را وارد کنید!',
    'Cryptocurrency & Fiat Withdrawal': 'برداشت ارز دیجیتال و Fiat',
    'Tether Fee': 'هزینه Tether',
    'Calculation of percentage in the price for buy': 'محاسبه درصد در قیمت برای خرید',
    'Calculation of the percentage in the price for sale': 'محاسبه درصد در قیمت برای فروش',
    'Futures Fee': 'هزینه معاملات آینده',
    'Futures Fee (Coin-M Futures)': 'هزینه معاملات آینده (Coin-M Futures)',
    'Futures Fee (USDⓈ-M Futures)': 'هزینه معاملات آینده (USDⓈ-M Futures)',
    'Trade Fee': 'هزینه تراکنش',
    'Internal Transaction Fee': 'هزینه تراکنش داخلی',
    'OTC Minimum Buy': 'حداقل خرید OTC',
    DISAPPROVAL: 'تأیید نشده',
    'Advanced rejected': 'پیشرفته رد شد',
    'Are you sure to remove this user from users?': 'آیا مطمئن هستید که می‌خواهید این کاربر را از کاربران حذف کنید؟',
    'Are you sure to remove this ticket?': 'آیا مطمئن هستید که می‌خواهید این تیکت را حذف کنید؟',
    'Please input your mobile number!': 'لطفاً شماره موبایل خود را وارد کنید!',
    'Please select your birth date': 'لطفاً تاریخ تولد خود را انتخاب کنید!',
    'Please select one!': 'لطفاً یک مورد را انتخاب کنید!',
    'Please input your email adress!': 'لطفاً آدرس ایمیل خود را وارد کنید!',
    'Please input your photo!': 'لطفاً تصویر خود را وارد کنید!',
    'Fiat type': 'نوع Fiat',
    'Fiat Status': 'وضعیت Fiat',
    'Withdraw Status': 'وضعیت برداشت',
    'Awaiting Approval': 'در انتظار تأیید',
    Cancelled: 'لغو شده',
    'Email Sent': 'ایمیل ارسال شده',
    Processing: 'در حال پردازش',
    Rejected: 'رد شده',
    'Transaction ID': 'شناسه تراکنش',
    'Deposit Status': 'وضعیت واریز',
    'Deposit Id': 'شناسه واریز',
    'Create Date': 'تاریخ ایجاد',
    'Transaction Type': 'نوع تراکنش',
    'Transaction Id': 'شناسه تراکنش',
    Sheba: 'شبا',
    'Deposit type': 'نوع واریز',
    'Created Date': 'تاریخ ایجاد',
    Expired: 'منقضی شده',
    'Partially Filled': 'جزئیاً پر شده',
    'Pending Cancel': 'در انتظار لغو',
    Limit: 'محدودیت',
    'Stop Limit': 'محدودیت توقف',
    Filled: 'پر شده',
    'Transaction Date': 'تاریخ تراکنش',
    'Stop Price': 'قیمت توقف',
    'Stop limit': 'محدودیت توقف',
    'Minimum limit': 'حداقل محدودیت',
    'Stop price': 'قیمت توقف',
    'Are you sure to remove this admin account?': 'آیا مطمئن هستید که می‌خواهید این حساب مدیریتی را حذف کنید؟',
    'Dynamic profit percentage': 'درصد سود پویا',
    'User Share': 'سهم کاربر',
    Iran: 'ایران',
    Armenia: 'ارمنستان',

    /**
     * **************************************************************************************
     * Please translate the below statements
     */
    'can see the list of commissions received by this user through introduction.':
      'شما می‌توانید لیست کمیسیون‌های دریافتی توسط این کاربر از طریق معرفی را ببینید.',
    'Crypto Desposit': 'واریز رمزارز',
    Monthly: 'ماهانه',
    'Contry / region': 'کشور / منطقه',
    'Use a valid goverment-issued document': 'از سند دولتی معتبر استفاده کنید',
    'place documents against a single-coloured background': 'سند‌ها را در برابر پس زمینه یک رنگ قرار دهید',
    'Readable, well-lit, colured images': 'تصاویر خوانا، روشن و رنگی',
    'Total Trade': 'مجموع معامله',
    // Fiat
    Account: 'حساب',
    'Tracking code': 'کد پیگیری',
    'This transaction is in progress, and after review, you can either confirm or reject it.':
      'این تراکنش در حال انجام است و پس از بررسی، می‌توانید آن را تایید یا رد کنید.',
    'Are you sure to deny this transaction?': 'آیا مطمئن هستید که می‌خواهید این تراکنش را رد کنید؟',
    'Are you sure to accept this transaction?': 'آیا مطمئن هستید که می‌خواهید این تراکنش را تایید کنید؟',
    // Missing Translations
    'List of withdrawals': 'لیست برداشت‌ها',
    'There is no record for this table': 'برای این جدول هیچ رکوردی وجود ندارد',
    'Recurring Buy': 'خرید دوره‌ای',
    'In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.':
      'برای افزایش سطح امنیت حساب کاربری خود، توصیه می‌کنیم که ورود دو مرحله‌ای خود را فعال کنید. می‌توانید ورود دو مرحله‌ای زیر را فعال کنید که شامل ورود دو مرحله‌ای از طریق Google، ایمیل و پیام کوتاه (SMS) است، و ایمن‌ترین روش ورود دو مرحله‌ای از طریق Google است، اما برای شما آسان‌تر کردن فعال‌سازی هر کدام از این سه روش را در نظر داریم.',
    'Are you sure to remove this order?': 'آیا مطمئن هستید که می‌خواهید این سفارش را حذف کنید؟',
    'I want to pay': 'می‌خواهم پرداخت کنم',
    'I want to sell': 'می‌خواهم بفروشم',
    'I will receive': 'من دریافت می‌کنم',
    'Deposit account number': 'شماره حساب واریز',
    'The total number of users': 'تعداد کل کاربران',
    'Total balance(Fiat)': 'مجموع موجودی (رمزارز)',
    'Total number of purchases': 'تعداد کل خریدها',
    'Total sales': 'مجموع فروش‌ها',
    'Total sales amount': 'مقدار مجموع فروش',
    'number of orders': 'تعداد سفارشات',
    'otal order amount': 'مقدار کل سفارش',
    Transaction: 'تراکنش',
    'Referral Users': 'کاربران معرفی‌شده',
    'Existing users': 'کاربران موجود',
    'Active users': 'کاربران فعال',
    'All tickets': 'تمامی تیکت‌ها',
    'Opened Tickets': 'تیکت‌های باز',
    Change: 'تغییر',
    Price: 'قیمت',
    Pair: 'جفت',
    'Market Trades': 'معاملات بازار',
    'Time Amount(MATIC) price(USDT)': 'زمان مقدار(MATIC) قیمت(USDT)',
    'Market Activities': '',
    XMRBUSD: 'XMRBUSD',
    MANAUSDT: 'MANAUSDT',
    SLAPETH: 'SLAPETH',
    ONGBTS: 'ONGBTS',
    'MATIC/USDT': 'MATIC/USDT',
    Binance: 'بایننس',
    'Volume USDT': 'حجم USDT',
    'Volume MATIC': 'حجم MATIC',
    low: 'پایین',
    Hight: 'بالا',
    Change: 'تغییر',
    Polygon: 'پلیگون',
    STOP_LIMIT: 'محدودیت توقف',
    MARKET: 'بازار',
    LIMIT: 'محدود',
    Total: 'مجموع',
    'Amout(MATIC)': 'مقدار(MATIC)',
    'Price(USDT)': 'قیمت(USDT)',
    'Recurring Buy': 'خرید دوره‌ای',
    'Rol 0.00% got .years for USDT Recurring Buy': 'درصد 0.00% به مدت .سال برای خرید دوره‌ای USDT',
    'Oder Book': 'دفتر سفارشات',
    'Sum(BTC)': 'جمع(BTC)',
    'Size(BTC)': 'اندازه(BTC)',
    Trades: 'معاملات',
    'BTC/USDT': 'BTC/USDT',
    Sep: 'سپتامبر',
    Oct: 'اکتبر',
    Nov: 'نوامبر',
    '3y': '3 سال',
    '50y': '50 سال',
    'Trade History': 'تاریخچه معامله',
    'Open Trades': 'سفارشات باز',
    Positione: 'موقعیت',
    'Margin Ratio': 'نسبت مارجین',
    'Liq.Price': 'قیمت تصفیه',
    'Entry Price': 'قیمت ورود',
    Size: 'اندازه',
    BTCUSDT: 'BTCUSDT',
    Layer1: 'لایه 1',
    Layer2: 'لایه 2',
    Fav: 'مورد علاقه',
    'Number of referrals': 'تعداد معرفی‌ها',
    'Number of transactions': 'تعداد معاملات',
    'your total income is based on USD': 'درآمد کلی شما بر اساس دلار می‌باشد',
    'Updated At': 'تاریخ به‌روزرسانی',
    'Creat At': 'تاریخ ایجاد',
    Deleted: 'حذف شده',
    'Close by admin': 'بسته شده توسط مدیر',
    'Oder ID': 'شناسه سفارش',
    'Paysafe History': 'تاریخچه Paysafe',
    'Perfact Money History': 'تاریخچه Perfect Money',
    'Oder ID': 'شناسه سفارش',
    'Tx Code': 'کد تراکنش',
    sum: 'جمع',
    BUY: 'خرید',
    'Unit price': 'قیمت واحد',
    "in order to increase the security leveled of yor account. we recommend that you enable your two-step login. you can enable the following two-step login. which includes two-step login via Google. email and SMS,and the safest method is two-step login via Google. but for you to consider three types we've got to make it easier for you to activate with each one.":
      'برای افزایش سطح امنیت حساب کاربری خود، توصیه می‌کنیم که ورود دو مرحله‌ای خود را فعال کنید. می‌توانید ورود دو مرحله‌ای زیر را فعال کنید که شامل ورود دو مرحله‌ای از طریق Google، ایمیل و پیام کوتاه (SMS) است، و ایمن‌ترین روش ورود دو مرحله‌ای از طریق Google است، اما برای شما آسان‌تر کردن فعال‌سازی هر کدام از این سه روش را در نظر داریم.',
    'Two-step login via Google': 'ورود دو مرحله‌ای از طریق Google',
    'Two-step login via Sms': 'ورود دو مرحله‌ای از طریق پیام کوتاه (SMS)',
    'Two-step login via Email': 'ورود دو مرحله‌ای از طریق ایمیل',
    'Withdrawal of cryptocurrency(equivalent to Dollar)': 'برداشت ارز دیجیتال (معادل دلار)',
    'Withdrawal of Fiat(equivalent to Dollar)': 'برداشت ارز معمولی (معادل دلار)',
    'Buying digital money(equivalent to Dollar)': 'خرید پول دیجیتال (معادل دلار)',
    'Buying digital money(equivalent to Rial)': 'خرید پول دیجیتال (معادل ریال)',
    'Fees for buy Tether': 'هزینه برای خرید تتر',
    'Fees for Sell Tether': 'هزینه برای فروش تتر',
    'Future Fee(Marker)': 'هزینه آینده (نشانگر)',
    'Future Fee(Taker)': 'هزینه آینده (تیکر)',
    'Trade Fee(Marker)': 'هزینه معامله (نشانگر)',
    'Trede Fee(Taker)': 'هزینه معامله (تیکر)',
    'Fees for internal transaction': 'هزینه برای معاملات داخلی',
    'Min internal transaction': 'حداقل معامله داخلی',
    'Max internal transaction': 'حداکثر معامله داخلی',
    'Max leverage': 'حداکثر اهرم',
    'Max Margin': 'حداکثر مارجین',
    'Card Number': 'شماره کارت',
    'Month Of date': 'ماه تاریخ',
    'Year of date': 'سال تاریخ',
    'Paysafe Price': 'قیمت پیسیف',
    'Deposit Type': 'نوع واریز',
    'Voucher Currency': 'واحد پرداختی',
    'First Name': 'نام',
    'Last Name': 'نام خانوادگی',
    'Old Password': 'رمزعبور قدیمی',
    'New Password': 'رمزعبور جدید',
    'Confirm Password': 'تأیید رمزعبور',
    'Add Mobile': 'افزودن شماره موبایل',
    'There is no record for this table': 'هیچ رکوردی برای این جدول وجود ندارد',
    'Paysafe Price': 'قیمت پیسیف',
    'Deposite Type': 'نوع واریز',
    'Voucher Currency': 'واحد پرداختی',
    'Fiat Type': 'نوع فیات',
    'Perfect Money Price': 'قیمت پرفکت مانی',
    TxID: 'شناسه تراکنش',
    Origin: 'منبع',
    'Entry Price': 'قیمت ورود',
    Leverage: 'اهرم',
    'Margin Ratio': 'نسبت مارجین',
    'Value currency': 'واحد ارزی',
    Address: 'آدرس',
    'Recurring Buy': 'خرید تکراری',
    Long: 'خرید',
    Short: 'فروش',
    Cost: 'هزینه',
    Max: 'حداکثر',
    'Introduction link': 'لینک معرفی',
    'Introduction Code': 'کد معرفی',
    'Number of referrals': 'تعداد معرفی‌ها',
    'Number of transactions': 'تعداد معاملات',
    'Your total income is based on USD': 'درآمد کل شما بر اساس دلار محاسبه می‌شود',
    'Verify Now': 'تأیید کنید',
    'After Basic': 'پس از اصولی',
    'After Intermediate': 'پس از متوسط',
    Fiat: 'فیات',
    "You didn't have any activity yet After you start trading in cryptizi,a live chart will be shown here.":
      'هنوز هیچ فعالیتی نداشته‌اید. پس از شروع معامله در کریپتیزی، یک نمودار زنده اینجا نمایش داده خواهد شد.',
    'Save Change': 'ذخیره تغییرات',
    'Setting Saved Successfully.': 'تنظیمات با موفقیت ذخیره شدند.',
    'Default setting restored successfully.': 'تنظیمات پیش‌فرض با موفقیت بازنشانی شدند.',
    'Please enter Spend amount!': 'لطفاً مقدار هزینه را وارد کنید!',
    'added to favorites successfully.': 'به موردعلاقه‌ها با موفقیت افزوده شد.',
    'Bad Request': 'درخواست نادرست',
    'Enter the amount!': 'مقدار را وارد کنید!',
    'Please enter total amount!': 'لطفاً مقدار کل را وارد کنید!',
    'Internal Server Error': 'خطای داخلی سرور',
    'Insufficient funds': 'موجودی کافی نیست',
    'Please select a coin!': 'لطفاً یک سکه را انتخاب کنید!',
    'Please fill in all fields': 'لطفاً تمام فیلدها را پر کنید',
    'Share percentage changed successfully.': 'درصد سهام با موفقیت تغییر یافت.',
    'deleted from your favorites': 'از موردعلاقه‌های شما حذف شد',
    'Invalid request': 'درخواست نامعتبر',
    'Invalid symbol USDTUSDT': 'نماد نامعتبر USDTUSDT',
    'Card ExpiryDate Month is invalid': 'ماه انقضای کارت نامعتبر است',
    'card is expired': 'کارت منقضی شده است',
    'Month of date': 'ماه تاریخ',
    'Account Number': 'شماره حساب',
    'Card ExpiryDate Month is invalid': 'ماه انقضای کارت نامعتبر است',
    'To activate this feature, follow these steps:\n 1. Get the latest version of Google Authenticator from Google Play or Apple Store.\n 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.':
      'برای فعال‌سازی این ویژگی، مراحل زیر را دنبال کنید:\n 1. آخرین نسخه Google Authenticator را از Google Play یا Apple Store دریافت کنید.\n 2. پس از نصب و اجرای برنامه Google Authenticator از یکی از روش‌های زیر، کلید را به برنامه اضافه کنید. - اسکن بارکد: این گزینه را انتخاب کرده و بارکد زیر را اسکن کنید.',
    '- Enter a provided key: Select this option and enter the following code carefully.':
      '- وارد کردن کد ارائه‌شده: این گزینه را انتخاب کرده و کد زیر را با دقت وارد کنید.',
    '3. Enter the received code (6-digit number) in the box below and click the activate button.':
      '3. کد دریافتی (عدد 6 رقمی) را در جعبه زیر وارد کرده و دکمه فعال‌سازی را بزنید.',
    'Google Code': 'کد گوگل',
    'To activate, first click on the send SMS button to send you a text message containing the activation code and insert the gad in the field and click the activate button.':
      'برای فعال‌سازی، ابتدا بر روی دکمه ارسال پیامک کلیک کنید تا یک پیامک حاوی کد فعال‌سازی به شما ارسال شود و gad را در فیلد وارد کرده و دکمه فعال‌سازی را بزنید.',
    'Send SMS': 'ارسال پیامک',
    'Email Code': 'کد ایمیل',
    'To activate, first click on the send email button to send a code to your email and insert the code in the field and click the activate button. You may have received an email in the (Spam / Junk) folder.':
      'برای فعال‌سازی، ابتدا بر روی دکمه ارسال ایمیل کلیک کنید تا یک کد به ایمیل شما ارسال شود و کد را در فیلد وارد کرده و دکمه فعال‌سازی را بزنید. ممکن است یک ایمیل در پوشه (هرزنامه / اسپم) دریافت کرده باشید.',
    'Send Email': 'ارسال ایمیل',
    "In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We've got to make it easier for you to activate with each one.":
      'برای افزایش سطح امنیت حساب خود، ما پیشنهاد می‌دهیم که ورود دو مرحله‌ای خود را فعال کنید. شما می‌توانید ورود دو مرحله‌ای زیر را فعال کنید که شامل ورود دو مرحله‌ای از طریق گوگل، ایمیل و پیامک است، و روش ایمن‌تر ورود دو مرحله‌ای از طریق گوگل است، اما برای شما سه نوع را در نظر گرفته‌ایم تا فعال‌سازی با هر کدام آسان‌تر شود.',
    'Selecting higher leverage such as [10x] increases your liquidation risk. Always manage your risk levels.':
      'انتخاب اهرم‌های بزرگتر مانند [10x] خطر لیکویدیشن شما را افزایش می‌دهد. همیشه مدیریت سطح ریسک خود را انجام دهید.',
    'Maximum position: 15,000,000 USDT': 'حداکثر موقعیت: 15,000,000 USDT',
    'Adjust Leverage': 'تنظیم اهرم',
    Leverage: 'اهرم',
    'Select Symbol': 'انتخاب نماد',
    'See what the potential risk and reward will be in monetary terms on any given trade. Use our Futures Calculator to establish your potential profit/loss on a future trade. Read tips on how to use.':
      'ببینید که چه خطر و پاداش بالقوه‌ای در اصطلاحات پولی برای هر معامله دلاری خواهد بود. از ماشین حساب آینده ما استفاده کنید تا سود و زیان بالقوه‌تان را در یک معامله آینده مشخص کنید. نکاتی در مورد چگونگی استفاده را بخوانید.',
    'Liquidation Price': 'قیمت لیکویدیشن',
    'Target Price': 'قیمت هدف',
    'Initial Margin': 'مارجین ابتدایی',
    Shot: 'فروش',
    Long: 'خرید',
    'Entry Price': 'قیمت ورود',
    Quantity: 'مقدار',
    Calculate: 'محاسبه',
    Result: 'نتیجه',
    'Maximum position': 'حداکثر موقعیت',
    'Introduction code': 'کد معرفی',
    'Introduction link': 'لینک معرفی',
    'No Data': 'بدون داده',
    Total: 'مجموع',
    'Number of referrals': 'تعداد معرفی‌ها',
    'Number of transactions': 'تعداد معاملات',
    'Your total income is based on USD': 'درآمد کل شما بر اساس دلار محاسبه می‌شود',
    'introduction code': 'کد معرفی',
    'introduction link': 'لینک معرفی',
    'The total number of users': 'تعداد کل کاربران',
    'Total number of orders': 'تعداد کل سفارش‌ها',
    'Total sales amount': 'مجموع مبلغ فروش',
    'Total number of purchases': 'تعداد کل خریدها',
    'Total balance ( Fiat )': 'مجموع موجودی (فیات)',
    'Total order amount': 'مجموع مبلغ سفارش‌ها',
    'Total sales': 'مجموع فروش‌ها',
    'Total amount of purchases': 'مجموع مبلغ خریدها',
    'Opened Tickets': 'تیکت‌های باز',
    'Existing users': 'کاربران موجود',
    'All tickets': 'همه تیکت‌ها',
    'Referral Users': 'کاربران معرفی‌شده',
    'Active users': 'کاربران فعال',
    Transaction: 'تراکنش',
    'Purchases amount': 'مقدار خریدها',
    'Sales amount': 'مقدار فروش‌ها',
    'Creat At': 'تاریخ ایجاد',
    'Updated At': 'تاریخ بروزرسانی',
    Order: 'سفارش',
    'Enter Tx-Code': 'وارد کردن شماره سفارش',
    'Tx-Code': 'شماره سفارش',
    'First Name': 'نام',
    'Last Name': 'نام خانوادگی',
    'Open Trades': 'سفارش‌های باز',
    'Tx-Code': 'کد تراکنش',
    'Enter Tx-code': 'وارد کردن کد تراکنش',
    'Tx Code': 'کد تراکنش',
    'Paysafe Price': 'قیمت پیسیف',
    'Deposit Type': 'نوع واریز',
    'Voucher Currency': 'واحد پوند واقعی',
    'Paysafe History': 'تاریخچه پیسیف',
    'Perfect Money History': 'تاریخچه پرفکت مانی',
    'Fiat Amount': 'مقدار فیات',
    'Perfect Money Price': 'قیمت پرفکت مانی',
    'There is no record for this table': 'هیچ رکوردی برای این جدول وجود ندارد',
    Origin: 'منبع',
    TxID: 'شناسه تراکنش',
    'Entry Price': 'قیمت ورود',
    Leverage: 'وسعت اهرم',
    'Margin Ratio': 'نسبت مارجین',
    'User id': 'شناسه کاربر',
    'Fiat withdraw transaction waiting': 'تراکنش برداشت فیات در انتظار',
    'You can see the list of fiat transactions whose information must be confirmed.':
      'شما می‌توانید لیست تراکنش‌های فیات که اطلاعات آنها باید تایید شود را مشاهده کنید.',
    'Fiat Type': 'نوع فیات',
    Account: 'حساب',
    Actions: 'عملیات',
    'Fiat deposit transaction waiting': 'تراکنش واریز فیات در انتظار',
    'You can see the list of fiat transactions whose information must be confirmed.':
      'شما می‌توانید لیست تراکنش‌های فیات که اطلاعات آنها باید تایید شود را مشاهده کنید.',
    Receipt: 'رسید',
    Token: 'توکن',
    Getway: 'درگاه',
    'Percentage in price for sale': 'درصد در قیمت برای فروش',
    'Purchase price in dollars': 'قیمت خرید به دلار',
    'Percentage on the price to buy': 'درصد بر روی قیمت برای خرید',
    'Percentage in the price for ( marketing )': 'درصد در قیمت برای (بازاریابی)',
    'Selling price in dollars': 'قیمت فروش به دلار',
    'Percentage in price for sale': 'درصد در قیمت برای فروش',
    'Percentage in the price for ( marketing )': 'درصد در قیمت برای (بازاریابی)',
    'Percentage in the price for purchase': 'درصد در قیمت برای خرید',
    'Percentage in the price for sale': 'درصد در قیمت برای فروش',
    Inventory: 'موجودی',
    Active: 'حداقل خرید مجاز',
    'Minimum allowed purchase': 'حداقل خرید مجاز',
    'Only Iranian IPs are allowed': 'تنها آی‌پی‌های ایرانی مجاز هستند',
    'Deactivating purchase from site': 'غیرفعال کردن خرید از سایت',
    'IP Block': 'مسدودیت آی‌پی',
    Num: 'شماره',
    Delete: 'حذف',
    'Withdrawal of cryptocurrency (equivalent to Dollar)': 'برداشت از رمزارز (معادل دلار)',
    'Buying digital money (equivalent to Dollar)': 'خرید پول دیجیتال (معادل دلار)',
    'Fees for buy Tether': 'هزینه خرید تتر',
    Marker: 'نشانگر',
    Taker: 'تیکر',
    'Fees for internal transaction': 'هزینه تراکنش داخلی',
    'Min internal transaction': 'حداقل تراکنش داخلی',
    'Internal Transaction': 'تراکنش داخلی',
    'Internal Transaction': 'تراکنش داخلی',
    'Max Leverage': 'حداکثر اهرم',
    'Withdrawal of Fiat (equivalent to Dollar)': 'برداشت فیات (معادل دلار)',
    'Buying digital money (equivalent to Rial)': 'خرید پول دیجیتال (معادل ریال)',
    'Fees for Sell Tether': 'هزینه فروش تتر',
    'OTC Minimum Buy': 'حداقل خرید OTC',
    'Max internal transaction': 'حداکثر تراکنش داخلی',
    'Max Margin': 'حداکثر مارجین',
    'Futures settings': 'تنظیمات آتی',
    Disabled: 'غیرفعال',
    Enabled: 'فعال',
    'Max Borrow': 'حداکثر وام گیری',
    'Add More Coins': 'افزودن سکه‌های دیگر',
    'List of bank accounts': 'لیست حساب‌های بانکی',
    'Bank name': 'نام بانک',
    'Card number': 'شماره کارت',
    'Account number': 'شماره حساب',
    'Shaba number': 'شماره شبا',
    'Add New': 'افزودن جدید',
    'Transfer methods': 'روش‌های انتقال',
    Name: 'نام',
    'Card to Card': 'کارت به کارت',
    'Bank deposit': 'واریز به بانک',
    'Remember me': 'مرا به خاطر بسپار',
    'Web Advertisement Setting': 'تنظیمات تبلیغات وب',
    'Show how many days': 'نمایش تعداد روزها',
    'Advanced editor': 'ویرایشگر پیشرفته',
    'Mobile Advertisement Setting': 'تنظیمات تبلیغات موبایل',
    'Write Something awesome...': 'چیزی عالی بنویسید...',
    Zibal: 'زیبال',
    Vander: 'وندر',
    Default: 'پیش‌فرض',
    'Total users': 'تعداد کل کاربران',
    'Verified users': 'کاربران تایید‌شده',
    'Users are waiting': 'کاربران در انتظار',
    'Rejected users': 'کاربران رد‌شده',
    'User Id': 'شناسه کاربری',
    Mobile: 'موبایل',
    Tell: 'تلفن',
    'Select or Drage photo': 'انتخاب یا کشیدن تصویر',
    'Introduced user': 'کاربر معرفی‌شده',
    'Total orders': 'تعداد کل سفارش‌ها',
    'Total commission orders': 'تعداد کل سفارش‌های کمیسیونی',
    'Average commission per order': 'میانگین کمیسیون برای هر سفارش',
    'There is no record for this table': 'هیچ رکوردی برای این جدول وجود ندارد',
    'Fiat Wallet Transaction': 'تراکنش کیف پول فیات',
    'Notification Tite': 'عنوان اعلان',
    'Color background': 'رنگ پس‌زمینه',
    'Normal(black)': 'معمولی (سیاه)',
    Users: 'کاربران',
    'Active days': 'روزهای فعال',
    Headers: 'سربرگ‌ها',
    'Notification /Email /SMS': 'اعلان / ایمیل / پیام کوتاه',
    Types: 'انواع',
    Recipient: 'گیرنده',
    Color: 'رنگ',
    'Active Days': 'روزهای فعال',
    'Total Trade': 'تعداد کل معاملات',
    Russia: 'روسیه',
    'United Arab Emirates': 'امارات متحده عربی',
    'Saudi Arabia': 'عربستان سعودی',
    'User Statistics': 'آمار کاربران',
    'Old Password': 'گذرواژه قدیمی',
    'New Password': 'گذرواژه جدید',
    'Confirm Password': 'تأیید گذرواژه',
    'In order to increase the security level of your account, we recommend that you enable your two-step login. You can enable the following two-step login, which includes two-step login via Google, email and SMS, and the safest method is two-step login via Google, but for you to consider three types We`ve got to make it easier for you to activate with each one.':
      'برای افزایش سطح امنیت حساب کاربری خود، توصیه می‌شود که ورود دو مرحله‌ای خود را فعال کنید. شما می‌توانید ورود دو مرحله‌ای زیر را فعال کنید که شامل ورود دو مرحله‌ای از طریق Google، ایمیل و پیام کوتاه است و ایمن‌ترین روش ورود دو مرحله‌ای از طریق Google است، اما برای شما این سه نوع را در نظر گرفته‌ایم تا فعال‌سازی با هرکدام آسان‌تر باشد.',
    'To activate this feature, follow these steps: 1. Get the latest version of Google Authenticator from Google Play or Apple Store. 2. After installing and running the Google Authenticator program through one of the following methods, add the key to the program. - Scan a barcode: Select this option and scan the following barcode.':
      'برای فعال‌سازی این ویژگی، مراحل زیر را دنبال کنید: 1. آخرین نسخه Google Authenticator را از Google Play یا Apple Store بگیرید. 2. پس از نصب و اجرای برنامه Google Authenticator از طریق یکی از روش‌های زیر، کلید را به برنامه اضافه کنید. - اسکن یک بارکد: این گزینه را انتخاب کرده و بارکد زیر را اسکن کنید.',
    '- Enter a provided key: Select this option and enter the following code carefully.':
      '- وارد کردن کد ارائه‌شده: این گزینه را انتخاب کرده و کد زیر را با دقت وارد کنید.',
    '3. Enter the received code (6-digit number) in the box below and click the activate button.':
      '3. کد دریافتی (شش رقمی) را در کادر زیر وارد کرده و دکمه فعال‌سازی را بزنید.',
    'Google Code': 'کد Google',
    'New Email': 'ایمیل جدید',
    'Add Mobile': 'افزودن موبایل',
    'Internal Server Error': 'خطای داخلی سرور',
    'Only normal user allowed': 'تنها کاربر عادی مجاز است',
    'Please input your old password!': 'لطفاً گذرواژه قدیمی خود را وارد کنید!',
    'Passwords must have at least 8 characters and contain at least one uppercase letter, lowercase letter, number, and symbol.':
      'گذرواژه‌ها باید حداقل شامل ۸ کاراکتر باشند و حاوی حداقل یک حرف بزرگ، یک حرف کوچک، یک عدد و یک نماد باشند.',
    'Please input your confirm password!': 'لطفاً گذرواژه تأییدی خود را وارد کنید!',
    'Invalid soft otp': 'کد نرم‌افزاری نامعتبر است',
    'Session not found': 'نشست یافت نشد',
    'Send SMS': 'ارسال پیام کوتاه',
    'send SMS otp need phone number!': 'برای ارسال کد پیام کوتاه نیاز به شماره تلفن دارید!',
    'User Id': 'شماره ای دی کاربر',
    'Final amount': 'مقدار نهایی',
    'Fiat Type': 'نوع فیات',
    'Waller ID': 'شماره ای دی کیف پول',
    'Pay Safe Voucher': 'ووچر پی سیف',
    'Pay Safe': 'پی سیف',
    // --------------
    'Popup link': 'Popup link',
    'Please choose an image': 'لطفا یک تصویر انتخاب کنید',
    Tokens: 'توکن ها',
    'Add token': 'ااضافه کردن توکن',
    'Main wallet setting': 'تنظیمات کیف پول اصلی',
    'Network setting': 'تنظیمات شبکه',
    "Listing":'لیستینگ',
    "Cryptizi offers a powerful platform to take advantage of a broad user base and solid infrastructure, improving your token's market visibility and accessibility." : "کریپتیزی یک پلتفرم قدرتمند برای استفاده از پایگاه کاربر گسترده و زیرساخت محکم ارائه می‌دهد و دید و دسترسی به بازار توکن شما را بهبود می‌بخشد.",
    "Application Process" :'فرآیند لیستینگ',
    "Listing Application" : 'لیستینگ',
    "To apply for listing, simply click on 'Listing Application'. Our business manager will promptly get in touch with you.": " برای درخواست لیست، به سادگی بر روی 'لیستینگ' کلیک کنید. مدیر کسب و کار ما فوراً با شما تماس خواهد گرفت. مدیر کسب و کار ما به سرعت با شما تماس خواهد گرفت. " ,
    "For any queries, reach out to us at listing@cryptizi.com. Thank you!":"برای هر گونه سؤال، با ما در listing@cryptizi.com تماس بگیرید. متشکرم!" ,
    "Ticket Number" : 'شماره تیکت',
    "Created At" : 'ایجاد شده در',
    "Dedicated Wallet Settings" : 'تنظیمات کیف پول اختصاصی'
  },
};

export default fa;
